import { parseInput } from 'components/DateRangePickerNew';
import { AggregateFunctions } from 'constants/aggregations';

export const BADGE_COLOR = {
  Alerting: 'error',
  Firing: 'error',
  Pending: 'warning',
  Normal: 'success',
  NoData: 'default',
  Error: 'error',
};

export const ALARM_ENABLED_SERVICES = [];

export const getNextAlphabet = (alphabet) => {
  if (!alphabet) return 'A';
  const nextAlphabet = alphabet.charCodeAt(0) + 1;
  return String.fromCharCode(nextAlphabet);
};

export const trimTrailingZeroesAfterDecimal = (value) => {
  return value
    .toString()
    .replace(/(?<=\d+\.\d*)(0+)(?!\.)(?!\d)/g, '')
    .replace(/(?<=\d+)(\.)(?=$|\D)/g, '');
};

export const parseInputSeconds = (val) => {
  const parsed = parseInput(val);
  if (!parsed) return 0;
  const { value, unit } = parsed;
  switch (unit) {
    case 's':
      return value;
    case 'm':
      return value * 60;
    case 'h':
      return value * 60 * 60;
    case 'd':
      return value * 60 * 60 * 24;
    case 'w':
      return value * 60 * 60 * 24 * 7;
    default:
      return 60;
  }
};

export const defaultAgg = {
  stat: AggregateFunctions[0],
  field: '',
  type: '',
};

export const secondsToString = (seconds) => {
  if (!seconds) return '';
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }
  return `${Math.floor(hours / 24)}d`;
};

export const getDimensionOptions = (dimensions) => {
  return dimensions
    ? Object.keys(dimensions).map((key) => {
        return {
          name: { label: key, value: key },
          values: dimensions[key].map((v) => ({
            label: v === '*' ? 'All' : v,
            value: v,
          })),
        };
      })
    : [];
};

export const getDimensionFilters = (dimensions) => {
  return (dimensions || []).reduce((acc, cur) => {
    if (cur.name && cur.values) {
      return {
        ...acc,
        [cur.name.value]: (cur.values || []).map((val) => val.value),
      };
    }
    return acc;
  }, {});
};

export const parsePanel = (panel) => {
  if (!panel) {
    return null;
  }
  return {
    ...panel,
    node_configs: (panel.node_configs || []).map((config) => ({
      ...config,
      dimensions: Array.isArray(config.dimensions)
        ? getDimensionFilters(config.dimensions)
        : config.dimensions,
    })),
  };
};