import React from 'react';
import Sidebar from '../Sidebar/index';
import AppHeader from '../AppHeader';
import Box from '@mui/material/Box';
import { useStyles } from '../Sidebar/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import TrialBanner from '../TrialBanner';
import OrganizationPausedBanner from '../OrganizationSuspendedBanner';
import { useSelector } from 'store';

const Layout = ({ children, history }) => {
  const [open, setOpen] = React.useState(false);
  const { org } = useSelector((state) => state.workspace);
  const { pathname } = history.location;
  const splittedValue = pathname.split('/', 2);
  const showMenu = splittedValue[1] !== 'settings';

  return (
    <>
      {showMenu ? <Sidebar show={open} setOpen={setOpen} /> : null}
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          transition: 'margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          marginLeft: !showMenu ? 0 : open ? '250px' : '68px',
        }}
      >
        <AppHeader />
        <TrialBanner />
        {org && <OrganizationPausedBanner />}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            padding: showMenu ? undefined : '0px !important',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Layout);
