import prettyMs from 'pretty-ms';
import moment from 'moment';

export function formatBytesWithType(value, unit, decimals = 2) {
  if (value === 0) return `0 ${unit}`;
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const index = sizes.findIndex((item) => item === unit);
  const i = Math.floor(Math.log(value) / Math.log(k));
  return (
    parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i + index]
  );
}

export function formatCount(
  count,
  decimals = 2,
  verbose = false,
  noUnits = false,
) {
  const dm = decimals < 0 ? 0 : decimals;
  if (count === Infinity || count === -Infinity) return null;
  if (count === 0) return 0;
  if (count < 1000 || noUnits) return parseFloat(Number(count).toFixed(dm));
  const k = 1000;
  const sizes = verbose
    ? ['', 'K', 'million', 'billion', 'trillion']
    : ['', 'k', 'mil', 'bil', 'T'];
  const i = Math.floor(Math.log(count) / Math.log(k));
  return (
    parseFloat((count / Math.pow(k, i)).toFixed(dm)) +
    (verbose ? ' ' : '') +
    sizes[i]
  );
}

export function formatDuration(ms, options) {
  if (!ms || ms === Infinity || ms === -Infinity) return '0';
  return prettyMs(ms, options);
}

export function formatNanoSeconds(nanoSeconds) {
  if (!nanoSeconds || nanoSeconds === Infinity || nanoSeconds === -Infinity)
    return '0ms';
  return prettyMs(nanoToMs(nanoSeconds), {
    millisecondsDecimalDigits: 2,
  });
}

export function formatSeconds(seconds) {
  // format seconds into human readable format upto minutes precision
  if (!seconds || seconds === Infinity || seconds === -Infinity) return '0s';

  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const parts = [];

  if (days > 0) {
    parts.push(`${days}d`);
  }

  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  return parts.length > 0 ? parts.join(' ') : '0m';
}

export function formatDateTime(timestamp) {
  const input = moment(timestamp);
  const isSameDay = moment().isSame(input, 'day');
  if (isSameDay) {
    return input.format('h:mm A');
  }
  return input.format('LLL');
}

export const nanoToMs = (nanoSeconds) => {
  return nanoSeconds / 1000000;
};
