import { makeStyles } from '@mui/styles';

export const headerStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.background.default,
    transition: 'all 0.3s ease',
  },
  headerScrolling: {
    background: theme.palette.background.light,
    boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
  },
}));

export const switcherStyles = makeStyles((theme) => {
  return {
    item: {
      'cursor': 'pointer',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
      '& svg': {
        color: theme.palette.text.secondary,
      },
      '&:hover': {
        '& .MuiTypography-root': {
          color: theme.palette.text.primary,
        },
        '& svg': {
          color: theme.palette.text.primary,
        },
      },
    },
    avatar: {
      'minWidth': '46px',
      '& .MuiAvatar-root': {
        fontSize: '1em',
        fontWeight: 600,
        color: 'secondary.contrastText',
        width: 36,
        height: 36,
        borderRadius: 4,
      },
    },
  };
});

export const accMenuStyles = makeStyles((theme) => {
  return {
    item: {
      cursor: 'pointer',
    },
    avatar: {
      'minWidth': '46px',
      '& .MuiAvatar-root': {
        fontSize: '1em',
        fontWeight: 600,
        color: 'secondary.contrastText',
        width: 36,
        height: 36,
        // borderRadius: 4,
      },
    },
  };
});
