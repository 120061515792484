import { gql } from '@apollo/client';

export const dashboardFields = gql`
  fragment Dashboard on dashboards {
    id
    name
    description
    workspace_id
    created_by
    updated_by
    data
    tags
    folder_id
    is_folder
    created_at
    updated_at
    is_starred {
      dashboard_id
    }
  }
`;
