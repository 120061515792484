import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CustomCheckBox = ({
  name,
  value,
  heading,
  label = '',
  hasError,
  helpText,
  checked,
  onChange,
  className,
  size,
  hint,
  type,
  color = 'primary',
  margin,
}) => {
  const Component = type === 'radio' ? Radio : Checkbox;
  return (
    <Box className={className}>
      {heading && <FormLabel>{heading}</FormLabel>}
      <FormControl error={hasError} variant="standard" margin={margin}>
        <FormGroup>
          <FormControlLabel
            control={
              <Component
                color={color}
                name={name}
                checked={checked || false}
                onChange={onChange}
                value={value}
              />
            }
            label={label}
          />
          {hint && (
            <Typography variant="caption" color="textSecondary">
              {hint}
            </Typography>
          )}
        </FormGroup>
        {hasError && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default CustomCheckBox;
