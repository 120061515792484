import { gql } from '@apollo/client';

export const alarmFields = gql`
  fragment AlarmRule on alarm_rules {
    id
    name
    description
    disabled
    workspace_id
    evaluation_periods
    interval_seconds
    alarm_condition
    tags
    alarm_params
    no_data_state
    eval_error_state
    created_at
    updated_at
  }
`;

export const alarmStateFields = gql`
  fragment AlarmState on alarm_states {
    id
    alarm_id
    current_state
    current_state_since
    current_state_end_at
    label_hash
    labels
    state_reason
    state_updated_at
    last_evaluated_at
  }
`;

export const alarmStateHistoryFields = gql`
  fragment AlamStateHistory on alarm_states_history {
    new_state
    prev_state
    id
    created_at
    state_reason
  }
`;
