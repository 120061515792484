import { gql } from '@apollo/client';
import { userFields } from '../users/fragments';
import { orgBillingFields, orgFields } from './fragments';

export const GET_ORG = gql`
  query GetOrganization($id: String!) {
    row: organizations_by_pk(id: $id) {
      ...Organization
    }
  }
  ${orgFields}
`;

export const GET_ORG_USERS = gql`
  query getOrgUsers($orgId: String!) {
    rows: organization_users(where: { organization_id: { _eq: $orgId } }) {
      user_id
      role
      user {
        ...User
        permissions(where: { workspace: { org_id: { _eq: $orgId } } }) {
          user_id
          workspace_id
          role
          workspace {
            id
            name
          }
        }
      }
    }
    workspaces(where: { org_id: { _eq: $orgId } }) {
      id
      name
    }
  }
  ${userFields}
`;

export const GET_ORG_USER = gql`
  query getOrgUsers($orgId: String!, $userId: String!) {
    user: organization_users(
      where: { organization_id: { _eq: $orgId }, user_id: { _eq: $userId } }
      limit: 1
    ) {
      user_id
      role
      user {
        ...User
        permissions(where: { workspace: { org_id: { _eq: $orgId } } }) {
          user_id
          workspace_id
          role
          workspace {
            id
            name
          }
        }
      }
    }
  }
  ${userFields}
`;

export const DELETE_ORG_USER = gql`
  mutation deleteOrgUser($orgId: String!, $userId: String!) {
    delete_organization_users_by_pk(organization_id: $orgId, user_id: $userId) {
      organization_id
      user_id
    }
    delete_workspace_users(
      where: {
        user_id: { _eq: $userId }
        workspace: { org_id: { _eq: $orgId } }
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_ORG_BILLING_DETAILS = gql`
  query getOrgBillingDetails($orgId: String!) {
    row: organizations_by_pk(id: $orgId) {
      ...OrganizationBillingDetails
    }
  }
  ${orgBillingFields}
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      name
      isoCode
    }
  }
`;

export const GET_STATES = gql`
  query getStates($countryCode: String!) {
    states(countryCode: $countryCode) {
      name
      isoCode
    }
  }
`;

export const UPDATE_ORG_USER = gql`
  mutation updateOrgUser($orgId: String!, $userId: String!, $role: String!) {
    update_organization_users(
      where: { user_id: { _eq: $userId }, organization_id: { _eq: $orgId } }
      _set: { role: $role }
    ) {
      returning {
        role
        organization_id
        user_id
      }
    }
  }
`;
