import { gql } from '@apollo/client';
import { userFields } from './fragments';

export const GET_LOGGED_IN_USER_DETAILS = gql`
  query getMe($id: String!) {
    users(where: { id: { _eq: $id } }) {
      ...User
      mfa_enabled
      acl {
        workspace_id
        org_id
        ws_role
        org_role
        workspace {
          id
          name
        }
        organization {
          id
          name
          created_at
          product_key
          plan {
            plan_tier
          }
          default_workspace_id
        }
      }
    }
  }
  ${userFields}
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $data: users_set_input!) {
    user: update_users_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...User
    }
  }
  ${userFields}
`;
