import React from 'react';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import { Formik, Form, Field, FieldArray } from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from 'components/Button';
import TextField from 'components/Form/Formik/FormikInput';
import Select from 'components/Form/Formik/FormikReactSelect';
import Stack from '@mui/material/Stack';
import { useQuery } from '@apollo/client';
import { useSelector } from 'store';
import REGIONS from 'constants/aws-regions';
import { LIST_DASHBOARDS } from 'graphql/dashboards/queries';
import Chip from '@mui/material/Chip';
import { DEFAULT_REGION } from 'constants/aws-regions';

const validationRules = yup.object({
  name: yup.string().required('Name is required'),
});

const General = ({ dashboard, onClose, onSave, onDelete }) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);
  const { data, loading } = useQuery(LIST_DASHBOARDS, {
    variables: {
      workspaceId: workspace.id,
      isFolder: true,
    },
  });

  const onSubmit = async (values) => {
    delete values.tag;
    await onSave('settings', {
      payload: values,
      postSave: () => onClose(),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: dashboard.name || '',
        description: dashboard.description || '',
        tags: dashboard.tags || [],
        folder_id: dashboard.folder_id,
        data: {
          default_region: dashboard.data.default_region || DEFAULT_REGION,
        },
      }}
      validationSchema={validationRules}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form>
            <Box m={1}>
              <Grid container direction="column">
                <Grid item>
                  <Field
                    fullWidth
                    size="small"
                    name="name"
                    label="Name"
                    component={TextField}
                  />
                </Grid>
                <Grid item>
                  <Field
                    fullWidth
                    size="small"
                    name="description"
                    label="Description"
                    inputProps={{
                      multiline: true,
                      maxRows: 4,
                      minRows: 3,
                    }}
                    component={TextField}
                  />
                </Grid>
                <Grid item>
                  <Field
                    transformValue
                    isClearable
                    size="small"
                    label="Folder"
                    name="folder_id"
                    isLoading={loading}
                    component={Select}
                    options={(data?.rows || []).map((folder) => ({
                      value: folder.id,
                      label: folder.name,
                    }))}
                  />
                </Grid>
                <Grid item>
                  <Typography mb={0.5} mt={2} color="text.secondary">
                    Tags
                  </Typography>
                  <FieldArray name="tags">
                    {(helpers) => {
                      return (
                        <>
                          <Grid
                            mb={1}
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              <Field
                                fullWidth
                                size="small"
                                margin="none"
                                name={`tag`}
                                placeholder="Name"
                                component={TextField}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                disabled={!values.tag}
                                variant="contained"
                                color="neutral"
                                onClick={() => {
                                  if (values.tag) {
                                    !(values.tags || []).some(
                                      (tag) => tag === values.tag,
                                    ) && helpers.push(values.tag);
                                  }

                                  setFieldValue('tag', '');
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                          <Stack direction="row" spacing={1}>
                            {values.tags?.map((tag, i) => (
                              <Chip
                                label={tag}
                                onDelete={() => helpers.remove(i)}
                              />
                            ))}
                          </Stack>
                        </>
                      );
                    }}
                  </FieldArray>
                </Grid>
                <Grid item>
                  <Field
                    transformValue
                    size="small"
                    label="Default Region"
                    name="data.default_region"
                    component={Select}
                    options={REGIONS.map((i) => ({
                      label: i.region,
                      value: i.region,
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent={'space-between'} gap={1} mt={2}>
                <Button color="error" variant="text" onClick={onDelete}>
                  Delete Dashboard
                </Button>
                <Box>
                  <Button color="primary" variant="text" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={Object.keys(errors).length > 0}
                    onClick={() => onSubmit(values)}
                    color="primary"
                    loading={false}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default General;
