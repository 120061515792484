import React, { useState, useEffect, useMemo } from 'react';
import { useSubscription, useQuery } from '@apollo/client';
import { SUB_ACCOUNT_SYNC_SETTINGS_PROGRESS } from 'graphql/account_sync_settings/queries';
import { GET_ALL_ENABLED_SERVICE_DEFINITIONS } from 'graphql/service_definitions/queries';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import Spin from 'components/Spin';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';
import { analyticsTrack } from 'utils/segment';

const SKIP_TO_DASHBOARD_THRESHOLD = 20;
const AUTO_SKIP_TO_DASHBOARD_THRESHOLD = 80;

const Finish = ({ onFinish, account, mobileStepperProps }) => {
  const [progress, setProgress] = useState(0);
  const [isOnFinishExecuted, setIsOnFinishExecuted] = useState(false);
  const [syncedResourcesCount, setSyncedResourcesCount] = useState(0);
  const [enabledServicesCount, setEnabledServicesCount] = useState(1);

  usePageEvent('Account Completed', {
    section: 'Configuration',
    flow: 'Sign Up',
  });

  useMemo(() => {
    setProgress(
      Math.floor(
        ((syncedResourcesCount || 0) * 100) / (enabledServicesCount || 1),
      ),
    );
  }, [syncedResourcesCount, enabledServicesCount]);

  const { data: enabledServicesResult, error: enabledServicesError } = useQuery(
    GET_ALL_ENABLED_SERVICE_DEFINITIONS,
  );

  const { data: syncedResourcesResult, error: syncedResourcesError } =
    useSubscription(SUB_ACCOUNT_SYNC_SETTINGS_PROGRESS, {
      variables: {
        account_id: account.id,
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (enabledServicesResult?.service_definitions_aggregate?.aggregate) {
      setEnabledServicesCount(
        enabledServicesResult.service_definitions_aggregate.aggregate.count,
      );
    }
  }, [enabledServicesResult]);

  useEffect(() => {
    if (syncedResourcesResult?.account_sync_settings_aggregate?.aggregate) {
      setSyncedResourcesCount(
        syncedResourcesResult.account_sync_settings_aggregate.aggregate.count,
      );
    }
  }, [syncedResourcesResult]);

  useEffect(async () => {
    if (AUTO_SKIP_TO_DASHBOARD_THRESHOLD <= progress && !isOnFinishExecuted) {
      await onFinish();
      setIsOnFinishExecuted(true);
      analyticsTrack('Onboarding Completed', {
        integrationType: 'aws',
      });
    }
  }, [progress]);

  if (syncedResourcesError || enabledServicesError) {
    toast.error(syncedResourcesError?.message || enabledServicesError?.message);
    return;
  }

  const FinishBtn = React.memo(
    (props) =>
      progress >= 0 && (
        <Button
          size="large"
          onClick={onFinish}
          disabled={progress < SKIP_TO_DASHBOARD_THRESHOLD}
          {...props}
        >
          Finish
        </Button>
      ),
    [progress, SKIP_TO_DASHBOARD_THRESHOLD, onFinish],
  );

  return (
    <>
      <Box sx={{ textAlign: 'center', p: { xs: 4, md: 0 } }}>
        <Helmet title="Getting your account ready" />
        <Spin
          spinning
          withLabel
          isDeterminate={progress === 100}
          progress={progress}
          size={40}
        />
        <Typography variant="h5" mb="8px" fontWeight={600}>
          Your account setup is complete.
        </Typography>
        <Typography paragraph color="text.secondary" lineHeight="150%">
          Please wait while we fetch your AWS resources. It should take 10 to 15
          minutes.
        </Typography>
        <Typography paragraph>
          You can configure which services you want to sync, frequency, and
          apply Tag filtering rules from the <b>"Settings > Sync Settings"</b>{' '}
          page.
        </Typography>
        <FinishBtn sx={{ display: { xs: 'none', md: 'inline-block' } }} />
      </Box>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<FinishBtn />} />
      )}
    </>
  );
};

export default Finish;
