import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Button from 'components/Button';
import Paper from '@mui/material/Paper';
import { verifyMfa, hub } from 'api/auth';
import { Link } from 'react-router-dom';
import OTPInput from 'components/OTPInput';

const TOTPVerify = ({ user, onAuthStateChange }) => {
  const [error, setError] = useState();
  const [value, setValue] = useState('');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (value.length === 6) {
      onConfirm();
    }
  }, [value]);

  const onConfirm = async () => {
    try {
      setSaving(true);
      setError();
      const resp = await verifyMfa(user.email, user.challengeToken, value);
      hub.emit('auth', {
        event: 'signIn',
        data: resp,
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Alert severity="info" sx={{ mb: 2 }}>
        Please enter MFA code from your authenticator app.
      </Alert>
      <Box
        sx={{
          '.otpInput': {
            textAlign: 'center',
            borderRadius: 3,
          },
        }}
      >
        <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <OTPInput
            isNumberInput
            autoFocus
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={setValue}
            length={6}
          />
        </Paper>
      </Box>
      <Box textAlign="right" mt={1}>
        <MuiLink underline="always" component={Link} to="/recover-mfa">
          Lost your device?
        </MuiLink>
      </Box>
      <Box mt={3}>
        <Button
          onClick={onConfirm}
          loading={saving}
          disabled={!value || saving}
          size="large"
          type="submit"
          fullWidth
          variant="contained"
        >
          Sign in
        </Button>
      </Box>
    </div>
  );
};

export default TOTPVerify;
