import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeysField from './KeysField';

const GroupBy = ({
  name,
  queryOptions,
  updateQueryOptions,
  variableOptions,
  from,
  to,
  loading,
}) => {
  return (
    <Box>
      <Typography gutterBottom variant="body2">
        Group By
      </Typography>
      <KeysField
        isMulti
        isClearable
        fullWidth
        limitTags={2}
        dataset={queryOptions.dataset}
        placeholder="Select field"
        name={`${name}.groupBy`}
        onChange={(name, values) => {
          updateQueryOptions(
            name,
            values.map((v) => {
              return v.type ? { field: v.field, type: v.type } : v.value;
            }),
          );
        }}
        value={queryOptions.groupBy?.map((gb) => {
          return gb.field ? gb : { label: gb, value: gb };
        })}
        from={from}
        to={to}
        variableOptions={variableOptions}
      />
    </Box>
  );
};

export default GroupBy;
