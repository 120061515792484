import React from 'react';
import zipWith from 'lodash/zipWith';
import moment from 'moment';
import DataTable from 'components/DataTable';

const TimeseriesTable = ({ data, valueFormatter = (value) => value }) => {
  const columns = [
    {
      text: 'Timestamp',
      dataField: 'timestamp',
      formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      text: 'value',
      dataField: 'value',
      formatter: valueFormatter,
    },
  ];

  const tableData = zipWith(
    data.timestamps,
    data.values,
    (timestamp, value) => ({
      timestamp,
      value,
    }),
  );

  return (
    <DataTable
      tableContainerStyle={{ maxHeight: 100 }}
      columns={columns}
      data={tableData}
      pagination={false}
    />
  );
};

export default TimeseriesTable;
