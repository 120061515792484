import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import 'uplot/dist/uPlot.min.css';
import App from './App';
import StoreProvider from './store';
import reportWebVitals from './reportWebVitals';
// import './utils/otelInstrumentation';
import { env } from 'utils/env';

try {
  if (env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://9fae46be0491476b8e8d0c3d1cad0c75@o1082879.ingest.sentry.io/6091876',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      release: env.REACT_APP_SENTRY_RELEASE,
      environment: env.REACT_APP_SENTRY_ENVIRONMENT,
    });
  }
} catch (e) {
  console.log(e.message);
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
