import React, { useCallback, useMemo, useState } from 'react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import TextInput from 'components/Form/TextInput';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import debounce from 'lodash/debounce';

import { timeFormats } from './constants';
import { parseInput, parseOptions } from './helper';
import { relativeStyles } from './styles';

const Item = ({ data, onChange }) => {
  const { title, unit, period } = data;

  return (
    <ListItem
      button
      dense
      secondaryAction={
        <Typography variant="body2" color="textSecondary">
          {period}
          {unit}
        </Typography>
      }
      onClick={() => {
        onChange(`${period}${unit}`);
      }}
    >
      <ListItemText primary={`Last ${period} ${title}`} />
    </ListItem>
  );
};

function RelativeTime({ onChange }) {
  const [search, setSearch] = useState('');
  const classes = relativeStyles();

  const defaultOptions = useMemo(
    () => parseOptions(timeFormats),
    [timeFormats],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debounceSearch = useCallback(debounce(handleSearch, 100), []);

  const onKeyUp = (e) => {
    if (e.which === 13 && search) {
      const parsed = parseInput(search);
      if (!parsed) return;
      onChange(`${parsed.value}${parsed.unit}`);
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Box px={1} pt={1}>
        <TextInput
          size="small"
          margin="none"
          placeholder="Relative time (Ex: 15m, 1h, 1d)"
          fullWidth
          onChange={debounceSearch}
          onKeyUp={onKeyUp}
        />
      </Box>
      <List className={classes.list}>
        {defaultOptions.map((item, idx) => (
          <Item data={item} key={idx} onChange={onChange} />
        ))}
      </List>
    </Box>
  );
}

export default RelativeTime;
