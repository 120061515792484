import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useEffect, useMemo } from 'react';
import { Field } from 'formik';
import TextField from 'components/Form/Formik/FormikInput';
import Formatter from './Formatter';
import { getColumns } from 'modules/alarms/components/kloudmate/utils';

const TablePanelColumns = ({ values, setFieldValue }) => {
  const columns = useMemo(() => {
    return getColumns(values.node_configs);
  }, [values.node_configs]);

  useEffect(() => {
    setFieldValue(
      'table.columns',
      columns.map((col) => {
        const existingCol = (values?.table?.columns || []).find(
          (c) => c.key === col.label,
        );
        return {
          key: col.label,
          rename: existingCol?.rename || '',
          format: existingCol?.format,
        };
      }),
    );
  }, [columns]);

  return (
    <Stack>
      <Stack spacing={2}>
        {columns.map((column, idx) => {
          const { label, type } = column;
          return (
            <Stack spacing={1}>
              <>
                <Typography variant="subtitle1" color="text.secondary">
                  Column {label}
                </Typography>
                <Field
                  fullWidth
                  margin="none"
                  name={`table.columns.${idx}.rename`}
                  label="Rename to"
                  component={TextField}
                  size="small"
                  onChange={(e) => {
                    setFieldValue(`table.columns.${idx}.key`, label);
                    setFieldValue(
                      `table.columns.${idx}.rename`,
                      e.target.value,
                    );
                  }}
                />
              </>
              {type === 'number' && (
                <Formatter
                  name={`table.columns.${idx}.format`}
                  values={values}
                />
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default TablePanelColumns;
