import React, { useState, useMemo, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import CustomSelect from 'components/Form/Select';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from 'components/Form/Switch';
import IconButton from 'components/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import { DEFAULT_PANEL_CONFIG } from '../dashboards/utils';
import { useSelector } from 'store';
import TimeseriesGraph, {
  onChangeUnitGraph,
} from '../alarms/components/TimeseriesGraph';
import TimeseriesTable from '../alarms/components/TimeseriesTable';
import {
  getLabelsString,
  ValueTable,
} from '../alarms/components/ExpressionResult';
import { resolveTemplateVariables } from '../dashboards/utils';
import DateRangePicker from 'components/DateRangePickerNew';
import PanelSettings from '../dashboards/components/PanelSettings';
import Spin from 'components/Spin';
import usePanelData from '../dashboards/hooks/usePanelData';
import Button from 'components/Button';
import { toast } from 'react-hot-toast';
import ExploreQuery from './components/ExploreQuery';
import NodeActionBar from '../alarms/components/NodeActionBar';
import { shortId } from 'utils';
import { ROLES } from 'constants/roles';
import FormListener from './components/FormListener';
import StatPanel, { onChangeUniStat } from './components/StatPanel';
import Typography from '@mui/material/Typography';
import TablePanel, {
  onChangeUnitTable,
} from 'modules/explore/components/TablePanel';
import TablePanelConfig from 'modules/alarms/components/kloudmate/TablePanelConfig';

const DEFAULT_TIME_OPTIONS = {
  override: false,
  time_shift: '',
};

const Explore = ({
  formRef,
  isExplore,
  resolvedVariablesValues,
  panel,
  defaultRegion,
  isSavedToDashboard,
  onSaveToDashboard,
  onUpdateDashboard,
  onDuplicatePanel,
  onChange,
}) => {
  const [tableView, setTableView] = useState(false);
  const [tableViewOption, setTableViewOption] = useState(0);

  const modifiedPanel = useMemo(() => {
    if (panel) {
      return panel?.panelType && panel?.statConfig
        ? panel
        : {
            ...panel,
            panelType: 'TimeSeries',
            statConfig: {
              statView: 'grid',
              calculation: 'last',
              baseColor: '#4CBB17',
            },
          };
    }
  }, [panel]);

  const resolvedConfig = useMemo(() => {
    if (modifiedPanel?.node_configs) {
      return resolveTemplateVariables(
        modifiedPanel,
        resolvedVariablesValues,
        defaultRegion,
      );
    }
  }, [resolvedVariablesValues]);

  const {
    result: queryResult,
    loading,
    error,
    runQuery,
  } = usePanelData({ panelConfig: resolvedConfig, panelInViewPort: true });

  useEffect(() => {
    if (error) {
      toast.error(
        error || `There was an error displaying ${modifiedPanel.title}`,
      );
    }
  }, [error]);

  const {
    workspace: { workspace },
  } = useSelector((s) => s);

  // TODO this does not hide node until submitted. Need to fix
  const filteredResult = queryResult?.filter((result) => {
    const nodeConfig = formRef?.current?.values?.node_configs?.find(
      (config) => config.nodeId === result.nodeId,
    );
    return !(nodeConfig && nodeConfig.hidden);
  });

  const timeseriesFrames = useMemo(() => {
    return filteredResult?.filter((f) => f.type === 'series');
  }, [filteredResult]);

  const selectedTableView = useMemo(() => {
    return queryResult && (queryResult[tableViewOption] || queryResult[0]);
  }, [tableView, tableViewOption, timeseriesFrames]);

  const variableOptions = useMemo(() => {
    return (resolvedVariablesValues || []).map((variable) => ({
      label: `$${variable.name}`,
      value: `$${variable.name}`,
      groupBy: 'Template Variables',
      isMulti: variable.is_multi,
      queryType: variable.config?.queryType,
    }));
  }, [resolvedVariablesValues]);

  const valueFormatter = (val) => {
    return val >= 100 ? Number(val?.toFixed(0)) : Number(val?.toFixed(1));
  };

  const Nodes = ({ values, setFieldValue }) => {
    const onChangeUnit = ({ format, nodeId }) => {
      const payload = {
        nodeId,
        format,
        values,
        setFieldValue,
      };
      switch (values.panelType) {
        case 'STATS': {
          onChangeUniStat({ ...payload });
          break;
        }
        case 'table': {
          onChangeUnitTable({ ...payload });
          break;
        }
        case 'TimeSeries':
        default: {
          onChangeUnitGraph({
            ...payload,
            data: (timeseriesFrames || []).map(({ nodeId, name }) => ({
              name,
              nodeId,
            })),
          });
          break;
        }
      }
    };

    return (
      <FieldArray name="node_configs">
        {({ push, remove }) => (
          <>
            {values.node_configs?.map((node, index) => {
              return (
                <ExploreQuery
                  values={values}
                  resolvedVariablesValues={resolvedVariablesValues}
                  defaultRegion={defaultRegion}
                  setFieldValue={setFieldValue}
                  workspace={workspace}
                  runQuery={runQuery}
                  variableOptions={variableOptions}
                  remove={remove}
                  index={index}
                  node={node}
                  onChangeUnit={onChangeUnit}
                />
              );
            })}
            {values.panelType === 'table' && (
              <TablePanelConfig
                name="node_configs"
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
            <NodeActionBar
              nodeConfig={'node_configs'}
              values={resolveTemplateVariables(
                values,
                resolvedVariablesValues,
                defaultRegion,
              )}
              push={push}
              onRunQuery={runQuery}
            />
          </>
        )}
      </FieldArray>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={
        modifiedPanel?.id
          ? {
              ...modifiedPanel,
              time_options: modifiedPanel.time_options || DEFAULT_TIME_OPTIONS,
            }
          : {
              id: shortId(),
              title: 'Untitled Panel',
              timeRange: '1h',
              tz: 'local',
              timeseries: {
                legend: {
                  view: 'list',
                  values: [],
                },
              },
              panelType: 'TimeSeries',
              statConfig: {
                statView: 'grid',
                calculation: 'last',
                baseColor: '#4CBB17',
              },
              axis: {
                x: {
                  date_style: 'auto',
                },
              },
              time_options: DEFAULT_TIME_OPTIONS,
              node_configs: [DEFAULT_PANEL_CONFIG],
            }
      }
    >
      {({ values, setFieldValue }) => {
        return (
          <Grid container spacing={2}>
            {onChange && <FormListener onChange={onChange} />}
            <Grid item sm={12} md={isExplore ? 12 : 8}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingLeft={3}
              >
                <Typography
                  variant="subtitle1"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {values.title}
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Switch
                    margin="none"
                    labelPlacement="start"
                    label="Table View"
                    value={tableView}
                    onChange={() => {
                      setTableView(!tableView);
                    }}
                  />
                  <DateRangePicker
                    size="small"
                    color="neutral"
                    value={{
                      value: values.timeRange,
                      tz: values.tz,
                    }}
                    onChange={async (val) => {
                      setFieldValue(`timeRange`, val.value);
                      setFieldValue(`tz`, val.tz);

                      const config = resolveTemplateVariables(
                        { ...values, timeRange: val.value, tz: val.tz },
                        resolvedVariablesValues,
                        defaultRegion,
                      );
                      await runQuery(config);
                    }}
                  />
                  <IconButton
                    size="small"
                    title="Refresh data"
                    icon={<RefreshIcon />}
                    onClick={() => {
                      const config = resolveTemplateVariables(
                        values,
                        resolvedVariablesValues,
                        defaultRegion,
                      );
                      return runQuery(config);
                    }}
                  />
                  {onDuplicatePanel && (
                    <Button
                      size="small"
                      onClick={onDuplicatePanel}
                      variant="outlined"
                    >
                      Duplicate Panel
                    </Button>
                  )}
                  {onSaveToDashboard &&
                    !isSavedToDashboard &&
                    workspace.role === ROLES.ADMIN && (
                      <Button size="small" onClick={onSaveToDashboard}>
                        Save To Dashboard
                      </Button>
                    )}
                  {onUpdateDashboard && isSavedToDashboard && (
                    <Button onClick={onUpdateDashboard}>Save Changes</Button>
                  )}
                </Stack>
              </Stack>

              <Spin spinning={loading}>
                <Box sx={{ mb: 2, pt: 1 }}>
                  {tableView ? (
                    <>
                      <CustomSelect
                        transformValue
                        fullWidth
                        options={
                          queryResult?.map((frame, index) => ({
                            label:
                              frame.type === 'series'
                                ? frame.name
                                : Object.keys(frame.labels).length
                                ? getLabelsString(frame.labels)
                                : frame.name,
                            value: index,
                          })) || []
                        }
                        value={tableViewOption}
                        onChange={(value) => {
                          setTableViewOption(value);
                        }}
                      />
                      <Box height={100}>
                        {selectedTableView &&
                          (selectedTableView.type === 'series' ? (
                            <TimeseriesTable
                              data={selectedTableView}
                              valueFormatter={valueFormatter}
                            />
                          ) : (
                            <ValueTable
                              frames={[selectedTableView]}
                              valueFormatter={valueFormatter}
                            />
                          ))}
                      </Box>
                    </>
                  ) : (
                    <Box px={!isExplore && 2}>
                      {values.panelType === 'STATS' && (
                        <StatPanel
                          queryResult={filteredResult}
                          values={values}
                          height={200}
                        />
                      )}
                      {values.panelType === 'table' && (
                        <TablePanel
                          data={filteredResult}
                          config={values}
                          height={200}
                        />
                      )}
                      {(!values.panelType ||
                        values.panelType === 'TimeSeries') && (
                        <TimeseriesGraph
                          timeRange={values.timeRange}
                          data={timeseriesFrames}
                          height={200}
                          tz={values.tz}
                          config={values}
                          showAnnotations
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Spin>
              {!isExplore && (
                <Grid item sm={12} md={12} p={2}>
                  <Nodes values={values} setFieldValue={setFieldValue} />
                </Grid>
              )}
            </Grid>
            {isExplore && (
              <Grid item sm={12} md={8}>
                <Nodes values={values} setFieldValue={setFieldValue} />
              </Grid>
            )}
            <Grid item sm={12} md={4}>
              <Box>
                <PanelSettings
                  setFieldValue={setFieldValue}
                  values={values}
                  frames={
                    values?.panelType === 'TimeSeries'
                      ? timeseriesFrames
                      : filteredResult
                  }
                />
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
export default Explore;
