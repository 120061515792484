import { useMemo, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import DataTable from 'components/DataTable';
import { getLabelsString } from 'modules/alarms/components/ExpressionResult';
import orderBy from 'lodash/orderBy';
import {
  format,
  formatResultToString,
} from 'modules/alarms/components/TimeseriesGraph';
import isNil from 'lodash/isNil';

export const onChangeUnitTable = ({
  nodeId,
  format,
  values,
  setFieldValue,
}) => {
  (values.table?.columns || []).forEach((col, idx) => {
    const id = col.key.split(':')[0];
    if (id === nodeId) setFieldValue(`table.columns.${idx}.format`, format);
  });
};

const TablePanel = ({ data, config, height }) => {
  const [sort, setSort] = useState();
  const formik = useFormikContext();
  const columnConfigs = config.table?.columns || [];

  const { columns, rows } = useMemo(() => {
    const rows = {};
    const columns = {};

    const isNumber = (data || []).every((row) => row.type === 'number');
    if (!data || !isNumber) {
      return { columns: [], rows: [] };
    }

    const { limit, nodeId } =
      config.node_configs.find((nc) => (nc.orderBy || []).length > 0) || {};

    orderBy(data, (o) => o.nodeId !== nodeId).forEach((row) => {
      const { labels, name, nodeId, values } = row;

      if (isNil(values[0])) {
        return;
      }

      const unit = (config.node_configs || []).find(
        (nc) => nc.nodeId === nodeId,
      )?.unit;

      const valColumn = nodeId === name ? name : `${nodeId}:${name}`;
      columns[valColumn] = {
        text: valColumn,
        dataField: valColumn,
        sortable: true,
        isValueCol: true,
        unit,
      };

      const rowId = getLabelsString(labels);
      rows[rowId] = {
        ...rows[rowId],
        id: rowId,
        ...labels,
        [valColumn]: values[0],
      };

      Object.keys(labels).forEach((label) => {
        columns[label] = {
          text: label,
          dataField: label,
          sortable: true,
        };
      });
    });

    const modifiedCols = Object.values(columns).map((column) => {
      const colConfig = columnConfigs.find((col) => col.key === column.text);

      const formatting = colConfig?.format;
      return {
        ...column,
        text: colConfig?.rename || column.text,
        formatter: (val) =>
          formatting
            ? formatResultToString(format({ ...formatting, value: val }))
            : val,
      };
    });

    return {
      rows: Object.values(rows).slice(0, limit || undefined),
      columns: orderBy(modifiedCols, 'isValueCol', 'desc'), //place value columns to the right
    };
  }, [data, columnConfigs]);

  const orderedRows = useMemo(() => {
    if (!sort) {
      return rows;
    }
    return orderBy(rows, sort.sortField, sort.sortOrder);
  }, [rows, sort]);

  const onTableChange = (type, props) => {
    if (type === 'sort') {
      setSort(props);
    }
  };

  // useMemo for smoother drag and drop
  const table = useMemo(
    () => (
      <DataTable
        columns={columns}
        data={orderedRows}
        onTableChange={onTableChange}
        tableContainerStyle={{ maxHeight: height }}
        stickyHeader
      />
    ),
    [orderedRows, columns, height],
  );

  return table;
};

export default TablePanel;
