import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const refreshOptions = [
  { label: 'Off', value: null },
  { label: '5s', value: 5 },
  { label: '10s', value: 10 },
  { label: '30s', value: 30 },
  { label: '1m', value: 60 },
  { label: '5m', value: 300 },
  { label: '15m', value: 900 },
  { label: '30m', value: 1800 },
  { label: '1h', value: 3600 },
  { label: '2h', value: 7200 },
];

const RefreshButtonGroup = ({
  onRefresh,
  refreshInterval,
  setRefreshInterval,
}) => {
  const anchorRef = useRef();

  const onChange = (value, popupState) => {
    setRefreshInterval(value);
    popupState.close();
  };

  return (
    <Box ref={anchorRef}>
      <PopupState variant="popover" popupId="refreshIntervalPicker">
        {(popupState) => {
          return (
            <Box display="flex" flex={1}>
              <ToggleButtonGroup>
                <ToggleButton size="small" onClick={onRefresh}>
                  <Tooltip title={'Refresh'} placement="top" arrow>
                    <RefreshIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton size="small" {...bindTrigger(popupState)}>
                  {refreshInterval && (
                    <Typography>
                      {refreshOptions.find(
                        (opt) => opt.value === refreshInterval,
                      )?.label || ''}
                    </Typography>
                  )}
                  {popupState.isOpen ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </ToggleButton>
              </ToggleButtonGroup>
              <Popover
                elevation={2}
                {...bindPopover(popupState)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ToggleButtonGroup
                  orientation="vertical"
                  color="primary"
                  value={refreshInterval}
                  exclusive
                  onChange={(e, v) => onChange(v, popupState)}
                >
                  {refreshOptions.map((option) => {
                    return (
                      <ToggleButton
                        sx={{ py: 0.5 }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Popover>
            </Box>
          );
        }}
      </PopupState>
    </Box>
  );
};

export default RefreshButtonGroup;
