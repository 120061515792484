import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from 'components/Button';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import ResetPassword from '../components/ResetPassword';
import ConfirmSignup from '../pages/ConfirmCode';
import Input from 'components/Form/TextInput';
import { requestPasswordReset, resendCode, login } from 'api/auth';
import usePageEvent from 'hooks/usePageEvent';

function ForgotPassword({ history }) {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [unverified, setUnverified] = useState(false);
  const [showConfirmSignup, setShowConfirmSignup] = useState(false);

  usePageEvent('Forgot Password', {
    section: 'Forgot Password',
    flow: 'Sign In',
  });

  const onSubmit = async () => {
    try {
      setError('');
      await requestPasswordReset(email);
      setSent(true);
    } catch (e) {
      switch (e.code) {
        case 'UserNotFoundException': {
          setError('User not found');
          break;
        }
        // TODO implement following cases in the API
        case 'NotAuthorizedException': {
          setError('Your account has been disabled. Please contact support.');
          break;
        }
        case 'InvalidParameterException': {
          if (e.message.includes('verified')) {
            // this happens when user is unconfirmed and tries to reset
            setUnverified(true);
            setError(
              'Your email is not verified. You need to verify your email address before you can reset your password.',
            );
          } else if (e.message.includes('already confirmed')) {
            setUnverified(false);
            setError(
              'Your email is already verified. Please login or reset your password.',
            );
          } else {
            setError(e.message);
          }
          break;
        }
        default: {
          setError(e.message);
        }
      }
    }
  };

  const onResendCode = async () => {
    try {
      await resendCode(email);
      setShowConfirmSignup(true);
      toast.success('Verification code is sent!');
    } catch (e) {
      setError(e.message);
    }
  };

  const onAuthStateChange = async (state, values) => {
    switch (state) {
      case 'confirmedSignup': {
        setShowConfirmSignup(false);
        setUnverified(false);
        setError('');
        break;
      }
      default: {
        history.push('/login');
      }
    }
  };

  if (showConfirmSignup) {
    return (
      <ConfirmSignup
        mode="reset"
        username={email}
        onAuthStateChange={onAuthStateChange}
      />
    );
  }

  return (
    <Box>
      <Helmet title="Reset password" />
      <Typography gutterBottom variant="h5" fontWeight={700}>
        Reset Password
      </Typography>
      <Typography color="textSecondary">
        Let's get your account back.
      </Typography>
      {sent ? (
        <Box mt={2}>
          {error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <Alert severity="info">
              Password reset code sent to your email address
            </Alert>
          )}
          <ResetPassword
            username={email}
            onAuthStateChange={onAuthStateChange}
          />
        </Box>
      ) : (
        <Box mt={2}>
          {error && <Alert severity="error">{error}</Alert>}
          {unverified ? (
            <>
              <Typography paragraph>
                Please check your inbox for verification code.
              </Typography>
              <Button
                color="secondary"
                sx={{ mr: 1 }}
                onClick={() => setShowConfirmSignup(true)}
              >
                I already have a code
              </Button>
              <Button onClick={onResendCode}>Resend the code</Button>
            </>
          ) : (
            <>
              <Input
                fullWidth
                type="email"
                margin="normal"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                fullWidth
                sx={{ mt: 1 }}
                size="large"
                variant="contained"
                onClick={onSubmit}
              >
                Send Code
              </Button>
            </>
          )}
        </Box>
      )}
      <Box mt={3}>
        <MuiLink underline="always" component={Link} to="/login">
          ← Back to sign In
        </MuiLink>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
