import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

const Usage = ({ panels, variables }) => {
  return (
    <Box>
      {panels.length ? (
        <List>
          <ListSubheader>Panels</ListSubheader>
          {panels.map((panel) => (
            <ListItem key={panel.id}>
              <ListItemText primary={panel.name} />
            </ListItem>
          ))}
        </List>
      ) : null}
      {variables.length ? (
        <List>
          <ListSubheader>Template Variables</ListSubheader>
          {variables.map((variable) => (
            <ListItem key={variable.id}>
              <ListItemText primary={variable.name} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
};

export default Usage;
