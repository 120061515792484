import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import ListItemIcon from '@mui/material/ListItemIcon';
import DocIcon from '@mui/icons-material/MenuBookOutlined';
import NewIcon from '@mui/icons-material/NewReleasesOutlined';
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import MessageIcon from '@mui/icons-material/SendOutlined';
import EmailIcon from '@mui/icons-material/AlternateEmailOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { Twitter } from 'constants/Icons';
import IconButton from 'components/IconButton';

const HelpMenu = ({ headerRef }) => {
  const intercom = useIntercom();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'helpMenu',
  });

  const onTalk = () => {
    popupState.close();
    try {
      intercom.show();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box mr={1}>
      <IconButton
        color="primary"
        icon={
          <HelpIcon
            sx={{
              fontSize: 28,
              backgroundColor: 'background.default',
              color: 'text.primary',
            }}
          />
        }
        {...bindTrigger(popupState)}
      />
      <Menu
        {...bindMenu(popupState)}
        anchorEl={headerRef?.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        sx={{
          ' .MuiMenu-paper': {
            width: 300,
          },
          ' .MuiListItem-root': {
            py: 1,
          },
          ' .MuiListItemIcon-root': {
            'minWidth': 30,
            ' .MuiSvgIcon-root': {
              fontSize: 16,
            },
          },
        }}
      >
        <ListItem
          button
          component={MuiLink}
          href="https://docs.kloudmate.com/"
          target="_blank"
        >
          <ListItemIcon>
            <DocIcon />
          </ListItemIcon>
          <ListItemText primary="Documentation & guide" />
        </ListItem>
        <ListItem
          button
          component={MuiLink}
          target="_blank"
          href="https://feedback.kloudmate.com/changelog"
        >
          <ListItemIcon>
            <NewIcon />
          </ListItemIcon>
          <ListItemText primary="What's new?" />
        </ListItem>
        <ListItem
          button
          component={MuiLink}
          href="https://feedback.kloudmate.com"
          target="_blank"
        >
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback and Request Feature" />
        </ListItem>
        <ListItem button onClick={onTalk}>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText
            primary="Talk to us"
            secondary="Report a bug, send a feature request, ask for help or just say hi"
          />
        </ListItem>
        <ListItem
          button
          component={MuiLink}
          target="_blank"
          href="https://twitter.com/kloudmate"
        >
          <ListItemIcon>
            <Twitter width={18} height={18} />
          </ListItemIcon>
          <ListItemText primary="Twitter - @KloudMate" />
        </ListItem>
        <ListItem
          button
          component={MuiLink}
          href="mailto:support@kloudmate.com"
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Support - support@kloudmate.com" />
        </ListItem>
      </Menu>
    </Box>
  );
};

export default HelpMenu;
