import { gql } from '@apollo/client';

export const issueUserFragment = gql`
  fragment IssueUser on issue_users {
    issue_id
    user_id
    is_assigned
    muted
    user {
      id
      name
    }
  }
`;
