import { gql } from '@apollo/client';
import { orgFields, orgUsersField } from '../organizations/fragments';

export const userFields = gql`
  fragment User on users {
    id
    email
    name
    sub
    created_at
    crm_hash
  }
`;
