import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import MFASecretLogin from './pages/MFASecretLogin';
import { IS_LDAP_ENABLED } from 'constants';

const Auth = () => (
  <Switch>
    {!IS_LDAP_ENABLED && <Route path="/signup" component={Signup} />}
    {!IS_LDAP_ENABLED && (
      <Route path="/forgot-password" component={ForgotPassword} />
    )}
    {!IS_LDAP_ENABLED && (
      <Route path="/recover-mfa" component={MFASecretLogin} />
    )}
    <Route component={Login} />
  </Switch>
);

export default Auth;
