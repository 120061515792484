import React from 'react';
import { Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const legendValues = ({ title, name }) => {
  const TIMESERIES_LEGENED_VALUES = [
    { label: 'Min', value: 'min' },
    { label: 'Max', value: 'max' },
    { label: 'Mean', value: 'mean' },
    { label: 'Total', value: 'total' },
    { label: 'First', value: 'first' },
    { label: 'Last', value: 'last' },
    { label: 'Count', value: 'count' },
  ];
  return (
    <Field
      fullwidth
      isMulti={title === 'Values'}
      size="small"
      label={title}
      name={name}
      component={Select}
      options={TIMESERIES_LEGENED_VALUES}
    />
  );
};

const TIMESERIES_LEGENED_VIEW = [
  { label: 'Table', value: 'table' },
  { label: 'List', value: 'list' },
];

const Legend = ({ setFieldValue }) => {
  return (
    <Box mt={1}>
      <Field name={'timeseries.legend.view'}>
        {({ field }) => {
          return (
            <Stack direction="row" alignItems={'center'} spacing={1}>
              <Typography gutterBottom variant="body1" color="text.secondary">
                Mode
              </Typography>
              <ToggleButtonGroup
                value={field.value || 'list'}
                onChange={(e, val) => {
                  setFieldValue(field.name, val);
                }}
                size="small"
                exclusive
              >
                {TIMESERIES_LEGENED_VIEW.map((view) => (
                  <ToggleButton value={view.value}>{view.label}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          );
        }}
      </Field>
      {legendValues({ title: 'Values', name: 'timeseries.legend.values' })}
    </Box>
  );
};

export default Legend;
