import { useMemo, useState } from 'react';
import moment from 'moment';
import { ALARM_STATES } from 'constants/index';

const initialState = {
  alarms: [],
  firingCount: 0,
  pendingCount: 0,
};

const useAlarmData = (alarmRules) => {
  const [alarms, setAlarms] = useState(initialState);

  useMemo(() => {
    if (alarmRules) {
      let firingCount = 0;
      let pendingCount = 0;

      const rulesArr = Array.isArray(alarmRules) ? alarmRules : [alarmRules];

      const newAlarms = rulesArr.map((alarm) => {
        const { states, ...rest } = alarm;
        let health = ALARM_STATES.Normal;
        let state = null;
        let lastError = null;
        let errorStateSince = null;

        states.forEach(
          ({ current_state, current_state_since, state_reason }) => {
            switch (current_state) {
              case ALARM_STATES.Pending: {
                if (!state) {
                  state = ALARM_STATES.Pending;
                }
                break;
              }
              case ALARM_STATES.Alerting:
                state = ALARM_STATES.Alerting;
                if (!errorStateSince) {
                  errorStateSince = current_state_since;
                } else if (
                  moment(errorStateSince).isAfter(current_state_since)
                ) {
                  errorStateSince = current_state_since;
                }
                break;
              case ALARM_STATES.Error:
                state = ALARM_STATES.Error;
                break;
              case ALARM_STATES.NoData:
                if (!state) {
                  state = ALARM_STATES.NoData;
                }
                break;
            }
            if (state_reason) {
              lastError = state_reason;
              health = ALARM_STATES.Error;
            }
          },
        );

        if (!state) {
          state = ALARM_STATES.Normal;
        }

        if (state === ALARM_STATES.Alerting) {
          firingCount++;
        } else if (state === ALARM_STATES.Pending) {
          pendingCount++;
        }

        return {
          ...rest,
          states,
          state,
          health,
          errorStateSince,
          lastError,
        };
      });
      setAlarms({
        alarms: newAlarms,
        firingCount,
        pendingCount,
      });
    } else {
      setAlarms(initialState);
    }
  }, [alarmRules]);

  return alarms;
};

export default useAlarmData;
