import React from 'react';
import PropTypes from 'prop-types';
import usePromise from 'hooks/usePromise';
import LoadingButton from '@mui/lab/LoadingButton';

const CustomButton = React.forwardRef(
  ({ loading, onClick, style, className, ...rest }, ref) => {
    const [isLoading, handler] = usePromise(onClick, loading);
    return (
      <LoadingButton
        ref={ref}
        loading={isLoading}
        disabled={isLoading}
        onClick={handler}
        variant="contained"
        {...rest}
      />
    );
  },
);

CustomButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomButton;
