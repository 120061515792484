import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tooltipContainer: ({ bbox }) => {
    return {
      position: 'fixed',
      left: bbox.left,
      top: bbox.top,
      pointerEvents: 'none',
      zIndex: theme.zIndex.tooltip,
    };
  },
  tooltip: ({ x, y, maxWidth }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    transform: `translate(${x}px, ${y}px)`,
    transition: 'transform ease-out 0.1s',
    left: 0,
    top: 0,
    width: 'max-content',
    maxWidth,
    wordBreak: 'break-word',
    padding: '0.5rem',
    boxShadow: `${theme.palette.grey[900]} 0rem 0.75rem 2rem 0rem`,
    borderRadius: '0.5rem',
  }),
}));
