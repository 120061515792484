import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import {
  GET_DIMENSION_VALUES,
  GET_METRIC_DIMENSION_KEYS,
} from 'graphql/metric/queries';
import { useFormikContext } from 'formik';
import Select from 'components/Form/Select';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { resolveTemplateVariables } from 'modules/dashboards/utils';

const Dimension = ({
  name,
  dimensionValues,
  namespace,
  accountId,
  region,
  metricName,
  dimensionKey,
  dimensionFilters,
  source,
  onRemove,
  variableOptions,
  resolvedVariablesValues,
}) => {
  const { setFieldValue } = useFormikContext();
  const keysInput = resolveTemplateVariables(
    {
      namespace,
      accountId,
      region,
      metricName,
      source,
    },
    resolvedVariablesValues,
  );

  const valuesInput = resolveTemplateVariables(
    {
      namespace,
      accountId,
      region,
      metricName,
      dimensionKey,
      source,
    },
    resolvedVariablesValues,
  );

  const {
    data: keysResult,
    loading: keysLoading,
    refetch: keysQuery,
  } = useQuery(GET_METRIC_DIMENSION_KEYS, {
    variables: keysInput,
    fetchPolicy: 'cache-first',
  });
  const {
    data: valuesResult,
    loading: valuesLoading,
    refetch: valuesQuery,
  } = useQuery(GET_DIMENSION_VALUES, {
    variables: valuesInput,
    fetchPolicy: 'cache-first',
  });

  const dimensionValueOptions = valuesResult?.rows?.length
    ? [{ label: 'All', value: '*' }, ...valuesResult?.rows]
    : [];

  return (
    <Box display="flex" alignItems="center" my={0.5}>
      <Select
        size="small"
        isLoading={keysLoading}
        margin="none"
        onChange={(value) => {
          if (value.value !== dimensionKey) {
            const newDims = { ...dimensionFilters };
            delete newDims[dimensionKey];
            setFieldValue(`${name}.dimensions`, {
              ...newDims,
              [value.value]: [],
            });
          }
        }}
        value={dimensionKey}
        options={
          (variableOptions || []).length
            ? variableOptions
                .filter((o) => !o.isMulti)
                .concat(
                  (keysResult?.rows || []).map((dimKey) => ({
                    ...dimKey,
                    groupBy: 'Dimension Keys',
                  })),
                )
            : keysResult?.rows || []
        }
        onOpen={() =>
          keysQuery({
            variables: keysInput,
          })
        }
      />
      <Typography sx={{ px: 1 }}>=</Typography>
      <Select
        isMulti
        size="small"
        isLoading={valuesLoading}
        margin="none"
        transformValue
        value={dimensionValues}
        onChange={(value) => {
          setFieldValue(`${name}.dimensions.${dimensionKey}`, value);
        }}
        options={
          (variableOptions || []).length
            ? variableOptions.concat(
                dimensionValueOptions.map((dimValue) => ({
                  ...dimValue,
                  groupBy: 'Dimension Values',
                })),
              )
            : dimensionValueOptions
        }
        onOpen={() =>
          valuesQuery({
            variables: valuesInput,
          })
        }
      />
      <Box ml={2}>
        <Button
          size="small"
          color="error"
          variant="outlined"
          onClick={onRemove}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};

export default Dimension;
