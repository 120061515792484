import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Input from 'components/Form/TextInput';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';
import { env } from 'utils/env';

const POLICY_URL = `https://${env.REACT_APP_CF_BUCKET}.s3.amazonaws.com/cloudformation.yml`;

const useStyles = makeStyles((theme) => ({
  list: {
    '& li': {
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  },
  strongText: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}));

const ConnectAccount = ({ account, onAddRoleArn, mobileStepperProps }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    role_arn: '',
  });

  usePageEvent('AWS Connect', {
    section: 'Configuration',
    flow: 'Sign Up',
  });

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value.trim() });
  };

  const onCreate = () => onAddRoleArn(values.role_arn);
  const NextBtn = React.memo(
    (props) => (
      <Button
        disabled={!values.role_arn}
        size="large"
        type="primary"
        onClick={onCreate}
        {...props}
      >
        Next
      </Button>
    ),
    [values.role_arn, onCreate],
  );
  return (
    <>
      <Box>
        <Helmet title="Connect your account" />
        <Typography gutterBottom variant="h5" fontWeight={600}>
          Connect AWS Account
        </Typography>
        <Typography color="text.secondary" lineHeight="150%" mb={4}>
          No code change required! Instead, establish a connection between
          KloudMate and your AWS account by utilizing a pre-configured
          CloudFormation stack.
        </Typography>
        <ol className={classes.list}>
          <li>
            <Typography color="text.secondary" lineHeight="150%">
              <Link
                target="_blank"
                href={`https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?stackName=kloudmate-delegation&param_ExternalId=${account.external_id}&templateURL=${POLICY_URL}`}
                rel="noreferrer"
                color="primary.main"
              >
                Click here to add KloudMate CloudFormation stack to AWS
              </Link>
            </Typography>
          </li>
          <li>
            <Typography color="text.secondary" lineHeight="150%">
              Scroll down and check following at the bottom{' '}
              <strong className={classes.strongText}>
                I Acknowledge that AWS CloudFormation might create IAM resources
              </strong>
            </Typography>
          </li>
          <li>
            <Typography color="text.secondary" lineHeight="150%">
              Click <strong className={classes.strongText}>Create</strong>
            </Typography>
          </li>
          <li>
            <Typography color="text.secondary" lineHeight="150%">
              Please wait for approximately one minute until the stack creation
              process is finished. Then go to the{' '}
              <strong className={classes.strongText}>Outputs</strong> tab. Copy
              the <strong className={classes.strongText}>ARN</strong> value of{' '}
              <strong className={classes.strongText}>
                KloudMateAppDelegationRoleArn
              </strong>{' '}
              to the box below:
            </Typography>
          </li>
        </ol>
        <Box>
          <Input
            fullWidth
            label="Role ARN"
            name="role_arn"
            placeholder="arn:aws:iam::XXXXXXXXXXXX:role/KloudMateAppDelegationRoleArn-XXXXXXXXXXXXX"
            value={values.role_arn}
            onChange={onValueChange}
          />
          <NextBtn sx={{ mt: 1, display: { xs: 'none', md: 'block' } }} />
        </Box>
      </Box>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<NextBtn />} />
      )}
    </>
  );
};

export default ConnectAccount;
