import React, { Fragment, useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from 'components/Button';
import { getInvitation, updateInvitation } from 'api/user';
import toast from 'react-hot-toast';
import Spin from 'components/Spin';

const InviteItem = ({ id, refetch }) => {
  const [data, setData] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInvitation(id).then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [id]);

  const onAccept = () => {
    return updateInvitation(id)
      .then(() => {
        refetch?.();
        setAccepted(true);
        toast.success('Invite accepted');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const setCurrentAccount = () => {
    window.location.replace('/');
  };

  return (
    <Spin spinning={loading}>
      <ListItem divider key={id}>
        <ListItemText primary={data?.invite.organization.name} />
        <ListItemSecondaryAction>
          {accepted ? (
            <Button
              variant="text"
              onClick={setCurrentAccount}
              endIcon={<ArrowForwardIcon />}
            >
              Continue
            </Button>
          ) : (
            <Button size="small" startIcon={<CheckIcon />} onClick={onAccept}>
              Accept
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </Spin>
  );
};

const PendingInvites = ({ invites, refetch }) => {
  return (
    <List>
      {invites.map((invite) => (
        <InviteItem id={invite.id} key={invite.id} refetch={refetch} />
      ))}
    </List>
  );
};

export default PendingInvites;
