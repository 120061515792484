import React, { memo, useMemo, useCallback } from 'react';
import { useStyles } from './styles';
import { geoData } from './geoUrl.js';
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import IconButton from 'components/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import { geoPatterson } from 'd3-geo-projection';
import { markers, regionNames } from './awsRegions';
import Typography from '@mui/material/Typography';
import { serviceTypes } from 'constants/servicesData';
import { GET_RESOURCE_IN_REGION } from 'graphql/resources/queries';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import { useStyles as usePanelStyles } from 'modules/dashboards/styles';
import Dropdown from 'components/Form/Dropdown';
import Stack from '@mui/material/Stack';

function scale(min, max) {
  const delta = max - min;
  const factor = min < 5 ? 0.3 : 0;
  return (val) =>
    ((val < min ? min : val > max ? max : val) - min) / delta + factor;
}

const MapChart = ({
  accountsResp,
  accountId,
  setAccountId,
  isEditable,
  onDelete,
  height,
  width,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const panelClasses = usePanelStyles();
  const markerRef = React.useRef();
  const [content, setContent] = React.useState('');
  const [popupData, setPopupData] = React.useState(null);

  const projection = geoPatterson()
    .center([40, 30])
    .scale(135)
    .clipExtent([
      [0, 0],
      [1000, 500],
    ]);

  const { data, loading, error } = useQuery(GET_RESOURCE_IN_REGION, {
    variables: { accountId: accountId },
  });

  function onClickMap(event, region) {
    let dataFilter = data.v_region_groupings_aggregate.nodes.filter(
      (item) => item.region === region,
    );
    setPopupData(dataFilter);
  }

  const markersWithResourceCount = useMemo(
    () =>
      data &&
      markers.map((marker) => {
        const filterRegionData = data.v_region_groupings_aggregate.nodes.filter(
          (data) => data.region === marker.region,
        );
        return {
          ...marker,
          resourceCount: sumBy(filterRegionData, 'total_type'),
        };
      }),
    [data],
  );

  const [minCount, maxCount] = useMemo(() => {
    if (markersWithResourceCount) {
      return [
        minBy(markersWithResourceCount, 'resourceCount').resourceCount,
        maxBy(markersWithResourceCount, 'resourceCount').resourceCount,
      ];
    }
    return [0, 0];
  }, [markersWithResourceCount]);

  const normalizer = useCallback(scale(minCount, maxCount), [
    maxCount,
    minCount,
  ]);

  return (
    <Paper sx={{ width: '100%', height: '100%' }}>
      <Box
        px={2}
        py={1}
        className={isEditable && panelClasses.gridItemTitle}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="subtitle1">Resource Distribution</Typography>
        <Stack
          spacing={1}
          direction="row"
          justifyContent={'flex-end'}
          alignItems="center"
        >
          <Dropdown
            placeholder="Select account"
            value={accountId}
            onChange={setAccountId}
            options={
              accountsResp?.data?.accounts.map((i) => ({
                label: i.name,
                value: i.id,
              })) ?? []
            }
          />
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      </Box>
      {data && (
        <ComposableMap
          projection={projection}
          style={{
            width: '100%',
            height: height - 60,
          }}
        >
          <Geographies geography={geoData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    height: height,
                    width: width,
                    default: {
                      fill: theme.palette.grey['600'],
                      outline: 'none',
                    },
                    hover: {
                      fill: 'none',
                      outline: 'none',
                    },
                    pressed: {
                      fill: 'none',
                      outline: 'none',
                    },
                  }}
                />
              ))
            }
          </Geographies>

          <PopupState variant="popover" popupId="mapPopupMarker">
            {(popupState) => {
              return (
                <>
                  {markersWithResourceCount &&
                    markersWithResourceCount.map(
                      ({ coordinates, niceName, region, resourceCount }) => {
                        if (resourceCount) {
                          return (
                            <>
                              <Marker
                                key={niceName}
                                coordinates={coordinates}
                                onClick={(event) => onClickMap(event, region)}
                              >
                                <Tooltip key={niceName} title={content} arrow>
                                  <circle
                                    key={niceName}
                                    ref={markerRef}
                                    {...bindTrigger(popupState)}
                                    r={normalizer(resourceCount) * 10}
                                    fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.contrastText}
                                    strokeWidth={1}
                                    strokeOpacity={1}
                                    className={classes.circleStyles}
                                    onMouseEnter={() => {
                                      setContent(`${niceName}`);
                                    }}
                                    onMouseLeave={() => {
                                      setContent('');
                                    }}
                                  />
                                </Tooltip>
                              </Marker>
                            </>
                          );
                        } else {
                          return '';
                        }
                      },
                    )}
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      overflow="hidden"
                      p={2}
                    >
                      <Typography variant="h6" mb={1}>
                        {popupData
                          ? `${regionNames[popupData[0].region]} (${
                              popupData[0].region
                            })`.toUpperCase()
                          : null}
                      </Typography>
                      {popupData
                        ? popupData
                            .sort((a, b) => b.total_type - a.total_type)
                            .map((item, index) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Typography variant="body2">{`${
                                    serviceTypes[item.type]?.title
                                  }`}</Typography>
                                  <Typography variant="body2" ml={3}>
                                    {item.total_type}
                                  </Typography>
                                </Box>
                              );
                            })
                        : ''}
                    </Box>
                  </Popover>
                </>
              );
            }}
          </PopupState>
        </ComposableMap>
      )}
    </Paper>
  );
};

export default memo(MapChart);
