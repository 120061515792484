import { makeStyles } from '@mui/styles';

export const relativeStyles = makeStyles((theme) => ({
  wrapper: {},
  list: {
    maxHeight: '200px',
    overflow: 'auto',
  },
}));

export const pickerStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: '260px',
    maxWidth: '640px',
    display: 'flex',
  },
  left: {
    minWidth: '260px',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.lighter,
  },
  right: {
    'minWidth': '320px',
    'flex': 1,
    'padding': '12px',
    'background': theme.palette.background.paper,
    '& form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));
