import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import AccountSwitcher from './AccountSwitcher';
import AccountMenu from './AccountMenu';
import HelpMenu from './HelpMenu';

import { headerStyles } from './styles';

function AppHeader() {
  const classes = headerStyles();
  const [isScrolling, setIsScrolling] = useState(false);
  const ref = useRef(null);

  const listener = (e) => {
    if (window.scrollY > 10) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return (
    <AppBar
      elevation={0}
      className={cx(classes.header, {
        [classes.headerScrolling]: isScrolling,
      })}
      position="sticky"
      ref={ref}
    >
      <Toolbar variant="dense">
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <AccountSwitcher />
          <Box flex={1} />
          <HelpMenu headerRef={ref} />
          <AccountMenu headerRef={ref} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
