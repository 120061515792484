import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';

function EnhancedTableHead(props) {
  const {
    columns,
    onSelectAllClick,
    selectionEnabled,
    childrenEnabled,
    numSelected,
    rows,
    onRequestSort,
    sortColumn,
    sortOrder,
    filters,
    onFilterChange,
  } = props;

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  const onSelectAll = (e) => {
    onSelectAllClick(e.target.checked, rows);
  };

  const rowCount = rows.length;

  return (
    <TableHead>
      <TableRow>
        {childrenEnabled && <TableCell width={65} />}
        {(onSelectAllClick || selectionEnabled) && (
          <TableCell padding="checkbox" sx={{ verticalAlign: 'middle' }}>
            {onSelectAllClick && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAll}
              />
            )}
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={`${headCell.dataField}${headCell.keyField || ''}`}
            align={headCell.numeric ? 'right' : headCell.align}
            sx={{
              verticalAlign: 'bottom',
              whiteSpace: 'nowrap',
              textTransform: 'uppercase',
              color: 'text.secondary',
              // fontWeight: 600,
              // fontSize: 12,
            }}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              sortColumn === headCell.dataField ? sortOrder : false
            }
          >
            {headCell.sortable ? (
              <TableSortLabel
                disabled={!onRequestSort}
                active={sortColumn === headCell.dataField}
                direction={
                  sortColumn === headCell.dataField ? sortOrder : 'asc'
                }
                onClick={createSortHandler(headCell.dataField)}
              >
                {headCell.text}
              </TableSortLabel>
            ) : (
              <div>{headCell.text}</div>
            )}
            {headCell.filter
              ? React.createElement(headCell.filter.Component, {
                  ...headCell.filter.props,
                  dataField: headCell.dataField,
                  onFilterChange,
                  value: filters[headCell.dataField]?.filterVal,
                  onChange: (e) =>
                    onFilterChange(headCell.dataField, e.target.value),
                })
              : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export default EnhancedTableHead;
