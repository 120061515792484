const fontSizeCalc = (expectedBodyFontSize) => {
  return (14 / 16) * expectedBodyFontSize;
};
const fontSize = 14; // px

const htmlFontSize = 16;
const coef = fontSize / 14;

export const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`;

export function themeTypography(theme) {
  return {
    htmlFontSize,
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
    fontSize: fontSizeCalc(fontSize),
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: pxToRem(12),
      fontWeight: 700,
    },
    caption: {
      lineHeight: 1.375,
    },
    h4: {
      fontWeight: 700,
      fontSize: pxToRem(20),
    },
    h5: {
      fontWeight: 700,
      fontSize: pxToRem(18),
    },
    h6: {
      fontWeight: 700,
      fontSize: pxToRem(16),
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  };
}
