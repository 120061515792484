import React from 'react';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Input = React.forwardRef(
  (
    {
      id,
      name,
      label,
      hint,
      placeholder,
      hasError,
      helpText,
      onChange,
      value,
      onClick,
      onBlur,
      endAdornment,
      startAdornment,
      type,
      size,
      inputProps = {},
      ...other
    },
    ref,
  ) => (
    <FormControl
      size={size}
      variant="standard"
      margin="normal"
      error={hasError}
      {...other}
    >
      {label && <FormLabel sx={{ mb: 0.5 }}>{label}</FormLabel>}
      <InputBase
        size={size}
        type={type}
        id={id}
        name={name}
        inputRef={ref}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={hasError}
        startAdornment={
          startAdornment && (
            <InputAdornment
              position="start"
              sx={{ position: 'absolute', left: 8, zIndex: 1 }}
            >
              {startAdornment}
            </InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment
              position="end"
              sx={{ position: 'absolute', right: 8 }}
            >
              {endAdornment}
            </InputAdornment>
          )
        }
        {...inputProps}
      />
      {hint && (
        <Typography variant="caption" color="textSecondary">
          {hint}
        </Typography>
      )}
      {hasError && helpText && (
        <FormHelperText sx={{ ml: 1 }}>{helpText}</FormHelperText>
      )}
    </FormControl>
  ),
);

export default Input;
