import max from 'lodash/max';
import min from 'lodash/min';
import mean from 'lodash/mean';
import first from 'lodash/first';
import last from 'lodash/last';
import sum from 'lodash/sum';

export const calculateLegendValues = ({
  legendValues,
  data,
  seriesScale,
  yValFormatter,
  yRightValFormatter,
}) => {
  if (!legendValues || !data) {
    return [];
  }

  const getValue = (val) => {
    if (Number.isInteger(val)) {
      return val;
    } else {
      if (val === undefined || val === null) {
        return '-';
      }
      return val;
    }
  };

  const calculations = [];
  data.forEach((values, index) => {
    if (index === 0) {
      calculations.push([]);
      return;
    }
    const noNullValues = values.filter((v) => v !== undefined && v !== null);
    const calc = legendValues
      .map((value) => {
        if (value === 'max') {
          return { value: max(noNullValues), name: 'Max' };
        }
        if (value === 'min') {
          return { value: min(noNullValues), name: 'Min' };
        }
        if (value === 'mean') {
          return { value: mean(noNullValues), name: 'Mean' };
        }
        if (value === 'first') {
          return { value: first(noNullValues), name: 'First' };
        }
        if (value === 'last') {
          return { value: last(noNullValues), name: 'Last' };
        }
        if (value === 'count') {
          return { value: noNullValues.length, name: 'Count' };
        }
        if (value === 'total') {
          return { value: sum(noNullValues), name: 'Total' };
        }
        return { value: undefined, name: '' };
      })
      .map((item) => ({
        ...item,
        value:
          item.name === 'Count'
            ? getValue(item.value)
            : seriesScale[index] === 'right'
            ? yRightValFormatter(getValue(item.value))
            : yValFormatter(getValue(item.value)),
      }));

    calculations.push(calc);
  });

  return calculations;
};
