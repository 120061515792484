import React, { useEffect, useMemo, useRef } from 'react';
import useMeasure from 'react-use-measure';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHistory } from 'react-router-dom';
import Spin from 'components/Spin';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TimeseriesGraph from '../../alarms/components/TimeseriesGraph';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from 'components/Dropdown';
import { calculateTimeRange, resolveTemplateVariables } from '../utils';
import { useStyles } from '../styles';
import usePanelData from '../hooks/usePanelData';
import { useInViewport } from 'react-in-viewport';
import StatPanel from 'modules/explore/components/StatPanel';
import TablePanel from 'modules/explore/components/TablePanel';

const Panel = ({
  refreshHash,
  dshTimeRange,
  dshTZ,
  panel,
  setSelectedPanel,
  onDuplicatePanel,
  showPanelEdit,
  onDeletePanel,
  resolvedVariablesValues,
  defaultRegion,
  isIsolatedView,
  isEditable = true,
  isViewMode,
  workspaceId,
}) => {
  const { title } = panel;
  const classes = useStyles();
  const history = useHistory();
  const [containerRef, containerBounds] = useMeasure();
  const panelRef = useRef();
  const { enterCount } = useInViewport(
    panelRef,
    {},
    { disconnectOnLeave: true },
  );

  const resolvedConfig = useMemo(() => {
    if (panel.node_configs) {
      const { override } = panel.time_options || {};
      const panelConfig = {
        ...panel,
        timeRange: override ? panel.timeRange : dshTimeRange || panel.timeRange,
        tz: override ? panel.tz : dshTZ || panel.tz,
      };
      return resolveTemplateVariables(
        panelConfig,
        resolvedVariablesValues,
        defaultRegion,
      );
    }
  }, [
    resolvedVariablesValues,
    panel.node_configs,
    panel.timeRange,
    panel.tz,
    dshTimeRange,
    dshTZ,
    panel.time_options,
  ]);

  const {
    result: queryResult,
    loading,
    error,
  } = usePanelData({
    panelConfig: resolvedConfig,
    refreshHash,
    panelInViewPort: enterCount > 0,
    workspaceId,
  });

  const filteredResult = queryResult?.filter((result) => {
    const nodeConfig = panel?.node_configs?.find(
      (config) => config.nodeId === result.nodeId,
    );
    return !(nodeConfig && nodeConfig.hidden);
  });

  const timeseriesFrames = useMemo(() => {
    return filteredResult?.filter((f) => f.type === 'series');
  }, [queryResult]);

  const options = useMemo(() => {
    let options = [];

    if (isViewMode) {
      options = isIsolatedView
        ? options.concat([{ key: 'close', children: 'Close' }])
        : options.concat([{ key: 'view', children: 'View' }]);
    }

    if (isEditable) {
      options = options.concat([{ key: 'edit', children: 'Edit' }]);
    }

    if (!isViewMode) {
      options = options.concat([
        { key: 'duplicate', children: 'Duplicate' },
        { key: 'delete', children: 'Delete' },
      ]);
    }

    return options;
  }, [isIsolatedView, isViewMode]);

  const onActionSelect = async (key) => {
    switch (key) {
      case 'view': {
        history.push({
          search: `?${new URLSearchParams({ viewPanel: panel.id })}`,
        });
        break;
      }
      case 'edit': {
        await setSelectedPanel();
        break;
      }
      case 'delete': {
        await onDeletePanel();
        break;
      }
      case 'duplicate': {
        await onDuplicatePanel(panel);
        break;
      }
      case 'close': {
        history.goBack();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Paper
      ref={containerRef}
      sx={{
        height: '100%',
      }}
    >
      <Spin spinning={loading}>
        <Stack direction="row">
          <Box
            className={
              !isIsolatedView &&
              isEditable &&
              !isViewMode &&
              classes.gridItemTitle
            }
            width={'100%'}
            py={0.5}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'center'}
              spacing={0.5}
            >
              {panel.description && (
                <Tooltip title={panel.description} placement="top" arrow>
                  <InfoOutlinedIcon
                    fontSize="1rem"
                    sx={{ color: 'text.secondary' }}
                  />
                </Tooltip>
              )}
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                variant="subtitle1"
              >
                {title}
              </Typography>
              {showPanelEdit && (
                <Dropdown
                  options={options}
                  onSelect={(action) => onActionSelect(action)}
                  color="primary"
                >
                  <IconButton size="small">
                    <ArrowDropDownIcon />
                  </IconButton>
                </Dropdown>
              )}
            </Stack>
          </Box>
        </Stack>

        <Box ref={panelRef} height={'100%'}>
          {error && (
            <Alert severity="error">
              <Typography>{error}</Typography>
            </Alert>
          )}
          {timeseriesFrames && containerBounds.height ? (
            <>
              {panel?.panelType === 'STATS' && (
                <StatPanel
                  queryResult={filteredResult}
                  values={panel}
                  height={containerBounds.height - 35}
                />
              )}
              {panel?.panelType === 'table' && (
                <TablePanel
                  data={queryResult}
                  config={resolvedConfig}
                  height={containerBounds.height - 45}
                />
              )}
              {(!panel?.panelType || panel?.panelType === 'TimeSeries') && (
                <TimeseriesGraph
                  timeRange={resolvedConfig.timeRange}
                  data={timeseriesFrames}
                  height={containerBounds.height - 47}
                  tz={resolvedConfig.tz}
                  config={resolvedConfig}
                  showAnnotations
                />
              )}
            </>
          ) : (
            <Box height={containerBounds.height - 42}></Box>
          )}
        </Box>
      </Spin>
    </Paper>
  );
};

export default Panel;
