import * as React from 'react';
import { getIn } from 'formik';
import Switch from '../Switch';

const FormikSwitch = ({
  field,
  form: { touched, errors, setFieldValue },
  ...other
}) => {
  return (
    <Switch
      {...other}
      checked={field.value}
      onChange={(e) => setFieldValue(field.name, e.target.checked)}
    />
  );
};

export default FormikSwitch;
