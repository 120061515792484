import uPlot from 'uplot';

const { linear, stepped, bars, spline, spline2 } = uPlot.paths;

const lineInterpolations = {
  linear: 0,
  stepAfter: 1,
  stepBefore: 2,
  spline: 3,
  //	spline2:    4,
};

const drawStyles = {
  line: 0,
  bars: 1,
  points: 2,
  barsLeft: 3,
  barsRight: 4,
};

// generate bar builder with 60% bar (40% gap) & 100px max bar width
const _bars60_100 = bars({ size: [0.2, 20], align: 0 });
const _bars100Left = bars({ size: [1] });
const _bars100Right = bars({ size: [1], align: -1 });
const _stepBefore = stepped({ align: -1 }); //, ascDesc: true
const _stepAfter = stepped({ align: 1 }); //, ascDesc: true
const _linear = linear();
const _spline = spline({ alignGaps: -1 });
//	const _spline2      = spline2();

export function paths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
  let s = u.series[seriesIdx];
  let style = s.drawStyle;
  let interp = s.lineInterpolation;

  let renderer =
    style === drawStyles.line
      ? interp === lineInterpolations.linear
        ? _linear
        : interp === lineInterpolations.stepAfter
        ? _stepAfter
        : interp === lineInterpolations.stepBefore
        ? _stepBefore
        : interp === lineInterpolations.spline
        ? _spline
        : //	interp === lineInterpolations.spline2    ? _spline2 :
          null
      : style === drawStyles.bars
      ? _bars60_100
      : style === drawStyles.barsLeft
      ? _bars100Left
      : style === drawStyles.barsRight
      ? _bars100Right
      : style === drawStyles.points
      ? () => null
      : () => null;

  return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip);
}
