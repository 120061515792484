export default [
  {
    region: 'af-south-1',
    name: 'Cape Town',
    geo: 'europe',
    coordinates: [18.4214, -33.9253],
  },
  {
    region: 'ap-east-1',
    name: 'Hong Kong',
    geo: 'asia',
    coordinates: [114.1586, 22.2855],
  },
  {
    region: 'ap-northeast-1',
    name: 'Tokyo',
    geo: 'asia',
    coordinates: [139.6917, 35.6895],
  },
  {
    region: 'ap-northeast-2',
    name: 'Seoul',
    geo: 'asia',
    coordinates: [126.978, 37.5665],
  },
  {
    region: 'ap-northeast-3',
    name: 'Osaka',
    geo: 'asia',
    coordinates: [135.5023, 34.6938],
  },
  {
    region: 'ap-south-1',
    name: 'Mumbai',
    geo: 'asia',
    coordinates: [72.8479, 19.017],
  },
  {
    region: 'ap-south-2',
    name: 'Hyderabad',
    geo: 'asia',
    coordinates: [78.4866, 17.385],
  },
  {
    region: 'ap-southeast-1',
    name: 'Singapore',
    geo: 'asia',
    coordinates: [103.8198, 1.3521],
  },
  {
    region: 'ap-southeast-2',
    name: 'Sydney',
    geo: 'asia',
    coordinates: [151.2093, -33.8688],
  },
  {
    region: 'ap-southeast-3',
    name: 'Jakarta',
    geo: 'asia',
    coordinates: [106.8451, -6.2146],
  },
  {
    region: 'ap-southeast-4',
    name: 'Melbourne',
    geo: 'asia',
    coordinates: [144.9631, -37.8142],
  },
  {
    region: 'ca-central-1',
    name: 'Canada',
    geo: 'america',
    coordinates: [-111.3158, 53.4761],
  },
  {
    region: 'eu-central-1',
    name: 'Frankfurt',
    geo: 'europe',
    coordinates: [8.6821, 50.1109],
  },
  {
    region: 'eu-central-2',
    name: 'Zurich',
    geo: 'europe',
    coordinates: [8.5417, 47.3769],
  },
  {
    region: 'eu-north-1',
    name: 'Stockholm',
    geo: 'europe',
    coordinates: [18.0686, 59.3294],
  },
  {
    region: 'eu-south-1',
    name: 'Milan',
    geo: 'europe',
    coordinates: [9.1903, 45.465],
  },
  {
    region: 'eu-south-2',
    name: 'Spain',
    geo: 'europe',
    coordinates: [-3.7026, 40.4165],
  },
  {
    region: 'eu-west-1',
    name: 'Ireland',
    geo: 'europe',
    coordinates: [-7.6921, 53.1424],
  },
  {
    region: 'eu-west-2',
    name: 'London',
    geo: 'europe',
    coordinates: [-3.436, 55.3781],
  },
  {
    region: 'eu-west-3',
    name: 'Paris',
    geo: 'europe',
    coordinates: [2.2137, 46.2276],
  },
  {
    region: 'il-central-1',
    name: 'Israel (Tel Aviv)',
    geo: 'europe',
    coordinates: [34.7818, 32.0853],
  },
  {
    region: 'me-central-1',
    name: 'UAE',
    geo: 'europe',
    coordinates: [54.3705, 24.4764],
  },
  {
    region: 'me-south-1',
    name: 'Bahrain',
    geo: 'europe',
    coordinates: [50.5577, 26.0667],
  },
  {
    region: 'sa-east-1',
    name: 'São Paulo',
    geo: 'america',
    coordinates: [-46.6333, -23.5505],
  },
  {
    region: 'us-east-1',
    name: 'N. Virginia',
    geo: 'america',
    coordinates: [-76.2859, 36.8508],
  },
  {
    region: 'us-east-2',
    name: 'Ohio',
    geo: 'america',
    coordinates: [-90.77, 38.67],
  },
  {
    region: 'us-west-1',
    name: 'N. California',
    geo: 'america',
    coordinates: [-120.8958, 38.8375],
  },
  {
    region: 'us-west-2',
    name: 'Oregon',
    geo: 'america',
    coordinates: [-120.5542, 43.8041],
  },
];

export const DEFAULT_REGION = 'us-east-1';
