import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'store';
import { apiClient } from 'api';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_PLAN_BY_SKU } from 'graphql/plans/queries';
import { getBaseSubscriptionItem, parseInvoiceData } from '../utils';
import { env } from 'utils/env';

const transformPeriod = (start, end) => ({
  start: moment.unix(start).utc().valueOf(),
  end: moment.unix(end).utc().subtract(1, 'day').endOf('day').valueOf(),
});

const useSubscription = () => {
  const { org } = useSelector((state) => state.workspace);
  const [baseSubscriptionItem, setBaseSubscriptionItem] = useState();
  const [latestInvoice, setLatestInvoice] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [planQuery] = useLazyQuery(GET_PLAN_BY_SKU);
  const [subscriptions, setSubscriptions] = useState();
  const [monthlySubPeriod, setMonthlySubPeriod] = useState();

  useEffect(async () => {
    if (!env.REACT_APP_STRIPE_PUB_KEY) {
      setLoading(false);
      return;
    }
    try {
      const subscriptions = await apiClient.get(
        `billing/${org.id}/subscriptions`,
      );
      setSubscriptions(subscriptions);
    } catch (error) {
      setError(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (!subscriptions?.data?.length) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await planQuery({
        variables: {
          sku: subscriptions.data[0].metadata.sku,
        },
        fetchPolicy: 'network-only',
      });

      setLoading(false);

      if (data && subscriptions.data.length === 1) {
        const sub = getBaseSubscriptionItem(
          subscriptions.data[0],
          data.plans[0],
        );
        if (sub) {
          if (sub.latest_invoice) {
            setLatestInvoice(parseInvoiceData(sub.latest_invoice));
          }
          setBaseSubscriptionItem(sub);
        }

        if (subscriptions.data[0].plan.interval === 'month') {
          const { current_period_start, current_period_end } =
            subscriptions.data[0];
          setMonthlySubPeriod(
            transformPeriod(current_period_start, current_period_end),
          );
        }
      } else if (data && subscriptions.data.length === 2) {
        const invoices = subscriptions.data.map((i) =>
          parseInvoiceData(i.latest_invoice),
        );

        const unpaidInvoice = invoices.find((i) => i.requireAction);

        setLatestInvoice(unpaidInvoice);
        const sub = subscriptions.data
          .map((sub) => getBaseSubscriptionItem(sub, data.plans[0]))
          .find((item) => !!item);
        if (sub) {
          setBaseSubscriptionItem(sub);
        }

        const monthlySub = subscriptions.data.find(
          (sub) => sub.plan.interval === 'month',
        );
        if (monthlySub) {
          const { current_period_start, current_period_end } = monthlySub;
          setMonthlySubPeriod(
            transformPeriod(current_period_start, current_period_end),
          );
        }
      } else {
        setBaseSubscriptionItem(null);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [subscriptions]);

  const isTrial = baseSubscriptionItem?.status === 'trialing';

  return {
    baseSubscriptionItem,
    latestInvoice,
    isTrial,
    monthlySubPeriod,
    error,
    loading,
  };
};

export default useSubscription;
