export const WORKSPACE_ROLES = [
  { label: 'Developer', value: 'admin' },
  { label: 'Viewer', value: 'user' },
];

export const ORG_ROLES = [
  { label: 'Owner', value: 'owner' },
  { label: 'User', value: 'user' },
];

export const ROLES = {
  OWNER: 'owner',
  USER: 'user',
  ADMIN: 'admin',
};

export const ROLES_LABEL = {
  user: 'Viewer',
  admin: 'Developer',
  owner: 'Owner',
};
