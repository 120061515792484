import React from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { widgets_config } from '../utils';

const w = Object.keys(widgets_config).map((k) => ({
  id: k,
  title: widgets_config[k].title,
  description: widgets_config[k].description,
}));

const WIDGETS = [
  ...w.sort((a, b) => a.title.localeCompare(b.title)),
  {
    id: 'custom',
    title: 'Custom Panel',
    description: 'Add a custom panel',
  },
];

const AddWidgetModal = ({ onClose, onSelect }) => {
  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Add Widget</DialogTitle>
      <DialogContent>
        <List disablePadding>
          {WIDGETS.map((w) => (
            <ListItemButton key={w.id} onClick={() => onSelect(w.id)}>
              <ListItemText primary={w.title} secondary={w.description} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWidgetModal;
