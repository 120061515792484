import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { GET_LOGGED_IN_USER_DETAILS } from 'graphql/users/queries';
import { useSelector, useDispatch } from 'store';
import { storeSession, setActiveWorkspace } from 'store/actions';
import Routes from './index';
import RightSideInfo from './components/RightSideInfo';
import LoaderWithLogo from 'components/LoaderWithLogo';
import {
  analyticsIdentify,
  analyticsReset,
  analyticsTrack,
} from 'utils/segment';
import { hub, getMe, login } from 'api/auth';
import { env } from 'utils/env';

export const useAuthWrapperStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
  },
  leftContainer: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(22),
    },
  },
}));

const AuthWrapper = ({ children }) => {
  const classes = useAuthWrapperStyles();
  const store = useSelector((state) => state);
  const { theme } = store;
  const [loading, setLoading] = useState(true);
  const user = useSelector((s) => s.user);
  const client = useApolloClient();
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const history = useHistory();
  const isDarkMode = theme === 'dark';
  const logoPath = isDarkMode
    ? `/assets/images/logo_full_white.svg`
    : `/assets/images/logo_full_black.svg`;

  useEffect(async () => {
    if (window.location.hostname === env.REACT_APP_DEMO_DOMAIN) {
      await login(env.REACT_APP_DEMO_EMAIL, 'demopassword');
    }
    checkUser();
    setAuthListener();
  }, []);

  async function setAuthListener() {
    hub.listen('auth', (data) => {
      console.log(data);
      switch (data.payload.event) {
        case 'signIn':
          checkUser(true).then((payload) => {
            analyticsTrack('Signed In', {
              userId: payload.user_id,
              properties: {
                username: payload.name,
              },
            });
            history.replace(history.location.pathname);
          });
          break;
        case 'signOut':
          localStorage.removeItem('token');
          dispatch(storeSession());
          intercom.shutdown();
          analyticsReset();
          history.push('/login');
          break;
        case 'forgotPasswordSubmit':
          history.push('/login');
          break;
        case 'signIn_failure': {
          const { message } = data.payload.data;
          console.log(message);
          // TODO handle User not found
          break;
        }
        default:
      }
    });
  }

  async function checkUser(fromLogin) {
    try {
      const { id, name, email, crm_hash } = await getMe();
      const { data } = await client.query({
        query: GET_LOGGED_IN_USER_DETAILS,
        variables: {
          id,
        },
      });
      const {
        users: [user],
      } = data;

      const acl = user.acl[0];
      if (fromLogin && acl && acl.organization && acl.account) {
        dispatch(
          setActiveWorkspace(
            {
              id: acl.org_id,
              name: acl.organization.name,
              role: acl.org_role,
              created_at: acl.organization.created_at,
              plan: acl.organization.product_key,
              plan_tier: acl.organization.plan?.plan_tier,
              default_workspace_id: acl.organization.default_workspace_id,
            },
            {
              id: acl.account.id,
              name: acl.account.name,
              role: acl.account_role,
            },
          ),
        );
      }
      dispatch(
        storeSession({
          ...user,
          crm_hash,
        }),
      );
      Sentry.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
      });
      const firstName = user.name.split(' ')[0];
      const lastName = user.name.split(' ').slice(1).join(' ');

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'user-loaded',
          user_id: user.id,
          user_email: user.email,
          user_name: user.name,
          user_first_name: firstName,
          user_last_name: lastName,
        });
      }
      const intercomProps = {
        id: user.id,
        userId: user.id,
        name: user.name,
        first_name: firstName,
        last_name: lastName,
        email: user.email,
        createdAt: moment(user.created_at).unix(),
        userHash: crm_hash,
        hideDefaultLauncher: true,
      };
      intercom.boot(intercomProps);
      analyticsIdentify(
        id,
        {
          email,
          name,
          createdAt: user.created_at,
        },
        crm_hash,
      );
      setLoading(false);
      return user;
    } catch (err) {
      console.log('session error: ', err);
      Sentry.configureScope((scope) => scope.setUser(null));
      dispatch(storeSession());
      setLoading(false);
    }
  }

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <LoaderWithLogo />
      </Box>
    );

  if (user) return children;

  const Header = () => (
    <Box
      p={3}
      className={classes.leftContainer}
      sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}
    >
      <img height={25} alt="KloudMate" src={logoPath} />
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Header />
          <Box
            mt={4}
            className={classes.leftContainer}
            sx={{
              display: { xs: 'flex', md: 'inherit' },
              justifyContent: 'center',
            }}
          >
            <Box sx={{ maxWidth: 460 }}>
              <Routes />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={{ xs: 'none', md: 'block' }}>
          <RightSideInfo />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthWrapper;
