import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { NumberIcon, StringIcon, BooleanIcon, RootIcon } from 'constants/Icons';

const icon = {
  root: RootIcon,
  number: NumberIcon,
  string: StringIcon,
  bool: BooleanIcon,
};

const colors = {
  root: '#d996fd',
  number: '#62ff87',
  string: '#6babff',
  bool: '#ff8780',
};

const DataTypeIcon = ({ type }) => {
  const Icon = icon[type];
  const color = colors[type];
  if (!Icon) {
    return null;
  }

  return (
    <SvgIcon
      sx={{
        color,
        fontSize: 16,
      }}
    >
      <Icon />
    </SvgIcon>
  );
};

export default DataTypeIcon;
