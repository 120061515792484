import { alpha } from '@mui/material/styles';
export const common = {
  white: '#FFFFFF',
  black: '#000000',
};

export const grey = {
  50: '#F9F9FB',
  100: '#F3F4F7',
  200: '#E7E9EE',
  300: '#DADDE6',
  400: '#929AB5',
  500: '#636F92',
  600: '#424A61',
  700: '#323849',
  800: '#212530',
  900: '#101218',
  A900: '#191C24',
  A800: '#2A2F3C',
  A700: '#535D79',
  5008: 'rgba(99,111,146,0.08)',
  50012: 'rgba(99,111,146, 0.12)',
  50016: 'rgba(99,111,146, 0.16)',
  50024: 'rgba(99,111,146, 0.24)',
  50032: 'rgba(99,111,146, 0.32)',
  50048: 'rgba(99,111,146, 0.48)',
  50056: 'rgba(99,111,146, 0.56)',
  50080: 'rgba(99,111,146, 0.8)',
};

export const LIGHT = {
  primary: {
    lighter: '#F8A563',
    light: '#F68E3C',
    main: '#F47715',
    dark: '#e36e0f',
    darker: '#7a3802',
    contrastText: common.white,
  },
  secondary: {
    lighter: '#53a4ff',
    light: '#4295ff',
    main: '#4285f4',
    dark: '#3f74e0',
    darker: '#3d62cd',
    contrastText: common.white,
  },
  neutral: {
    main: grey['50080'],
    contrastText: common.white,
  },
  background: {
    default: '#FAFBFD',
    paper: common.white,
    light: grey[50],
    lighter: grey[100],
    navbar: grey[800],
  },
  action: {
    disabledBackground: grey['A800'],
    disabled: grey[500],
    active: grey[400],
    hover: grey['300'],
    selected: grey['300'],
  },
  divider: grey['200'],
  text: {
    primary: '#212B36',
    main: '#212B36',
    secondary: '#52606D',
    light: grey[600],
    lighter: '#101218',
  },
  success: {
    main: '#08a628',
  },
  error: {
    main: '#FD3162',
  },
  warning: {
    main: '#F4C622',
  },
  info: {
    main: '#4A6CFD',
  },
  grey,
};

export const DARK = {
  primary: {
    lighter: '#F8A563',
    light: '#F68E3C',
    main: '#F47715',
    dark: '#e36e0f',
    darker: '#7a3802',
    contrastText: common.white,
  },
  secondary: {
    lighter: '#53a4ff',
    light: '#4295ff',
    main: '#4285f4',
    dark: '#3f74e0',
    darker: '#3d62cd',
    contrastText: common.white,
  },
  neutral: {
    main: grey[600],
    contrastText: common.white,
  },
  background: {
    paper: grey['A900'],
    default: grey[900],
    neutral: grey[50012],
    light: grey[800],
    lighter: grey[700],
    navbar: grey[800],
  },
  text: {
    primary: common.white,
    main: common.white,
    secondary: grey[400],
    light: grey[300],
    disabled: grey[500],
    icon: grey[300],
    lighter: common.white,
  },
  action: {
    disabledBackground: grey['A800'],
    disabled: grey[500],
    active: grey[300],
    hover: grey['A800'],
    selected: grey['A800'],
  },
  error: {
    main: '#FD3162',
  },
  warning: {
    main: '#f4c622',
    dark: '#E09E00',
  },
  success: {
    main: '#11bb69',
  },
  info: {
    main: '#2eaaff',
  },
  divider: grey['50024'],
  grey,
};
