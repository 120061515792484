// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        color: theme.palette.secondary.main,
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            textDecorationStyle: 'dotted',
          },
        },
      },
    },
  };
}
