import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from 'components/Form/TextInput';
import Button from 'components/Button';
import { Helmet } from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';

const CreateWorkspace = ({ onUpdateWorkspace, mobileStepperProps }) => {
  const [value, setValue] = useState('');

  usePageEvent('Workspace Info', {
    section: 'Info',
    flow: 'Sign Up',
  });

  const NextBtn = React.memo(
    (props) => (
      <Button
        disabled={!value}
        size="large"
        type="primary"
        onClick={() => onUpdateWorkspace(value)}
        {...props}
      >
        Next
      </Button>
    ),
    [value, onUpdateWorkspace],
  );

  return (
    <>
      <Box sx={{ p: 4 }}>
        <Helmet title="Setup your workspace" />
        <Typography variant="h5" mb={1} fontWeight={600}>
          Workspace
        </Typography>
        <Typography mb={3} color="text.secondary" lineHeight="150%">
          Workspace is a way to group multiple data sources. For example, you
          can create separate workspace for development, production etc. You'll
          be able to add multiple workspaces to your organizations from
          settings.
        </Typography>
        <Input
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Workspace name"
        />
        <NextBtn sx={{ display: { xs: 'none', md: 'block' } }} />
      </Box>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<NextBtn />} />
      )}
    </>
  );
};

export default CreateWorkspace;
