import { gql } from '@apollo/client';

export const accountFields = gql`
  fragment Account on accounts {
    id
    name
    provider
    role_arn
    updated_at
    created_at
    external_id
    organization_id
    provider_account_id
    status
    status_reason
    status_updated_at
    delegation_status
    delegation_status_reason
    delegation_status_updated_at
    sync_allowed_tags
  }
`;
