import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Swatch = styled('div')`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  cursor: pointer;
`;

export default ({ value, onChange, ...rest }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(value);
  const togglePicker = () => setShowPicker(!showPicker);
  const hidePicker = () => setShowPicker(false);

  const handleChange = ({ hex }) => {
    setColor(hex);
    onChange && onChange(hex);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <Box {...rest}>
      <Swatch color={color} onClick={togglePicker} />
      {showPicker ? (
        <div style={popover}>
          <div style={cover} onClick={hidePicker} />
          <SketchPicker onChange={handleChange} color={color} />
        </div>
      ) : null}
    </Box>
  );
};
