import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from 'components/Form/TextInput';
import Button from 'components/Button';
import { Helmet } from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';
import Stack from '@mui/material/Stack';

const CreateOrganization = ({
  onUpdateAccount,
  mobileStepperProps,
  onCancel,
}) => {
  const [value, setValue] = useState('');

  usePageEvent('Account Info', {
    section: 'Info',
    flow: 'Sign Up',
  });

  const NextBtn = React.memo(
    (props) => (
      <Button
        disabled={!value}
        size="large"
        type="primary"
        onClick={() => onUpdateAccount(value)}
        {...props}
      >
        Next
      </Button>
    ),
    [value, onUpdateAccount],
  );

  return (
    <>
      <Box>
        <Typography gutterBottom variant="h5" fontWeight={600}>
          AWS Account
        </Typography>
        <Typography paragraph color="text.secondary" lineHeight="150%">
          Name your AWS account. You'll be able to add multiple accounts to your
          organizations from settings.
        </Typography>
        <Input
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Account name"
        />
      </Box>
      <Stack direction="row" spacing={1}>
        {onCancel && (
          <Button variant="text" size="large" onClick={() => onCancel()}>
            Back
          </Button>
        )}
        <NextBtn sx={{ display: { xs: 'none', md: 'block' } }} />
      </Stack>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<NextBtn />} />
      )}
    </>
  );
};

export default CreateOrganization;
