import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Label from './Label';

const LegendList = ({ series, legendCalculations }) => {
  // const getAppendValues = (i) => {
  //   if (legendCalculations.length && legendCalculations[i].length) {
  //     return `: ${legendCalculations[i]
  //       .map((calc) => `${calc.name}: ${calc.value}`)
  //       .join(' ')
  //       .trim()}`;
  //   }
  //   return '';
  // };

  return (
    <Stack direction={'row'} flexWrap="wrap">
      {series.map(
        (s, i) => i !== 0 && <Label key={i} text={s.name} color={s.stroke} />,
      )}
    </Stack>
  );
};

export default LegendList;
