import TextField from 'components/Form/TextInput';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from 'components/IconButton';

const Limit = ({ name, queryOptions, updateQueryOptions, nodeIdx }) => {
  const limit = queryOptions.node_configs[nodeIdx[0]]?.limit;

  return (
    <TextField
      size="small"
      label={<Typography color="text.secondary">Limit</Typography>}
      margin="none"
      value={limit || ''}
      type="number"
      onChange={(event) => {
        for (const idx of nodeIdx) {
          updateQueryOptions(`${name}.${idx}.limit`, event.target.value);
        }
      }}
      endAdornment={
        limit && (
          <IconButton
            icon={<ClearIcon />}
            onClick={() => {
              for (const idx of nodeIdx) {
                updateQueryOptions(`${name}.${idx}.limit`, null);
              }
            }}
          />
        )
      }
    />
  );
};

export default Limit;
