import groupBy from 'lodash/groupBy';
import { useEffect } from 'react';

const parseThreshold = ({ thresholds, lineType, isPercentage, maxY, minY }) => {
  return thresholds
    .filter((_) => lineType !== 'off')
    .map(({ value, color }) => {
      return {
        color,
        value: isPercentage ? minY + (value * (maxY - minY)) / 100 : value,
      };
    })
    .filter(({ value }) =>
      !isPercentage ? value >= minY && value <= maxY : value,
    );
};

const Threshold = ({ addHooks, thresholds, lineType, mode }) => {
  useEffect(() => {
    addHooks(
      [
        {
          type: 'drawClear',
          func: (u) => {
            let ctx = u.ctx;
            ctx.save();

            const groups = groupBy(thresholds, 'yAxis');

            Object.keys(groups).forEach((yAxis) => {
              const maxY = u.scales[yAxis].max;
              const minY = u.scales[yAxis].min;

              const isPercentage = mode === 'percentage';

              parseThreshold({
                isPercentage,
                maxY,
                minY,
                thresholds: groups[yAxis],
                lineType,
              }).forEach(({ value, color }) => {
                ctx.beginPath();
                ctx.strokeStyle = color;
                ctx.lineWidth = 1;
                if (lineType === 'line-dashed') {
                  ctx.setLineDash([10, 10]);
                }

                const x0 = u.bbox.left;
                const y0 = u.valToPos(value, yAxis, true);
                const x1 = u.bbox.left + u.bbox.width;
                const y1 = u.valToPos(value, yAxis, true);
                ctx.moveTo(x0, y0);
                ctx.lineTo(x1, y1);
                ctx.stroke();
              });
            });

            ctx.restore();
          },
        },
      ],
      true,
    );
  }, [thresholds, lineType, mode]);

  return null;
};

export default Threshold;
