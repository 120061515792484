import { env } from 'utils/env';

export const SEVERITY_COLORS = {
  debug: 'success',
  undefined: 'info',
  warn: 'warning',
  fatal: 'error',
  runtime: 'error',
  early_exit: 'error',
  timeout: 'error',
  // insights
  critical: 'error',
  warning: 'warning',
  ERROR: 'error',
  WARN: 'warning',
  INFO: 'info',
};

export const SEVERITY_LABEL = {
  debug: 'OK',
  warn: 'WARN',
  fatal: 'FATAL',
  runtime: 'CODE ERROR',
  early_exit: 'EARLY EXIT',
  timeout: 'TIMEOUT',
  ERROR: 'ERROR',
};

export const ISSUE_SEVERITY_LABEL = {
  warn: 'Warning',
  fatal: 'Fatal',
};

export const INSIGHT_SEVERITY_LABEL = {
  info: 'Info',
  warning: 'Warning',
  critical: 'Critical',
};

export const ORG_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
};

export const ACCOUNT_STATUS_LABEL = {
  active: 'Active',
  pending: 'Pending Setup',
  paused: 'Paused',
  suspended: 'Suspended',
};

export const DELEGATION_STATUS_LABEL = {
  pending: 'Pending integration',
  active: 'Connected',
  permission_error: 'Permission error',
};

export const STATUS_REASON_LABELS = {
  payment_error: 'Payment Error',
  over_quota: 'Usage limit exceeded',
  abuse: 'Account suspended due to abuse',
  user_paused: 'Account paused by user',
  missing_subscription: 'Missing Subscription. Please contact support for help',
  other: 'Please contact support for help.',
};

export const ALARM_STATES = {
  Alerting: 'Alerting',
  Pending: 'Pending',
  Normal: 'Normal',
  NoData: 'NoData',
  Error: 'Error',
};

export const ALARM_STATE_LABELS = {
  Alerting: 'Firing',
  Pending: 'Pending',
  Normal: 'Normal',
  NoData: 'No Data',
  Error: 'Error',
};

export const IS_LDAP_ENABLED = !!env.REACT_APP_LDAP_ENABLED;
