import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

const Annotation = React.forwardRef(
  ({ addHooks, axis, axisValue, children, width, height, ...props }, ref) => {
    const [coordinates, setCoordinates] = useState({ left: null, top: null });
    const otherAxis = axis === 'x' ? 'left' : 'x';

    useEffect(() => {
      addHooks([
        {
          type: 'drawAxes',
          func: (u) => {
            const { offsetTop, offsetLeft } = u.under;

            if (axis === 'x') {
              const top =
                u.valToPos(u.scales[otherAxis].min, otherAxis) +
                offsetTop -
                height;
              const left = u.valToPos(axisValue, axis) + offsetLeft - width;

              if (left < u.bbox.left) {
                return;
              } else if (left > u.bbox.left + u.bbox.width) {
                return;
              }

              setCoordinates({
                left,
                top,
              });
            }
          },
        },
      ]);
    }, []);

    return (
      coordinates.left &&
      coordinates.top && (
        <Box
          ref={ref}
          left={coordinates.left}
          top={coordinates.top}
          position={'absolute'}
          {...props}
        >
          {children}
        </Box>
      )
    );
  },
);

export default Annotation;
