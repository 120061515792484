import React from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-hot-toast';
import Card from '@mui/material/Card';
import CostTable from './CostTable';
import { useSelector, useDispatch } from 'store';
import { setCostByRegion, setCostByService } from 'store/actions';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import IconButton from 'components/IconButton';
import Stack from '@mui/material/Stack';
import { useStyles as usePanelStyles } from 'modules/dashboards/styles';
import Dropdown from 'components/Form/Dropdown';
import { apiClient } from 'api';

function CostPerService({
  accountId,
  setAccountId,
  accountsResp,
  isEditable,
  onDelete,
  width,
  height,
}) {
  const dispatch = useDispatch();
  const dashboardData = useSelector((s) => s.dashboard)[accountId] || {};
  const { workspace } = useSelector((state) => state.workspace);
  const [type, setType] = React.useState('SERVICE');
  const [loading, setLoading] = React.useState(false);
  const [permissionError, setPermissionError] = React.useState();
  const panelClasses = usePanelStyles();
  const handleChange = (event, newAlignment) => {
    setType(newAlignment);
  };

  function formatData(data) {
    let formatDataList = [];
    let tableCost = data.reduce((accumulator, currentValue) => {
      if (currentValue.totals) {
        return (
          accumulator +
          +Number(currentValue.totals.blendedCost.amount).toFixed(2)
        );
      } else {
        return accumulator;
      }
    }, 0);
    data.forEach((item) => {
      const serviceCost = +Number(item.totals.blendedCost.amount).toFixed(2);
      const percentageCost = ((serviceCost / tableCost) * 100).toFixed(2);
      formatDataList.push({
        id: item.service,
        cost: +Number(item.totals.blendedCost.amount).toFixed(2),
        label: item.service,
        value: percentageCost,
      });
    });
    return formatDataList;
  }

  async function getData() {
    try {
      let sortedData = [];
      const dateFrom = moment()
        .startOf('month')
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      const dateTo = moment().startOf('month').format('YYYY-MM-DD');
      const res = await apiClient.get(`cost-management/report`, {
        params: {
          accountId,
          workspace_id: workspace.id,
          start: dateFrom,
          end: dateTo,
          granularity: 'MONTHLY',
          groupBy: type,
        },
      });
      if (res.message) {
        toast.error(res.message);
      } else {
        sortedData = orderBy(
          res[0]?.groups,
          (item) => item.totals.blendedCost.amount,
          ['desc'],
        );
        const formattedData = formatData(sortedData.slice(0, 10));
        if (type === 'SERVICE') {
          dispatch(setCostByService(accountId, formattedData));
        } else {
          dispatch(setCostByRegion(accountId, formattedData));
        }
      }
    } catch (e) {
      setPermissionError(e.message);
    }
  }

  React.useEffect(async () => {
    if (!accountId) return;
    setLoading(true);
    if (type === 'SERVICE') {
      await getData();
      setLoading(false);
      if (dashboardData.costByService) {
        setLoading(false);
      } else {
        await getData();
        setLoading(false);
      }
    } else {
      await getData();
      setLoading(false);
      if (dashboardData.costByRegion) {
        setLoading(false);
      } else {
        await getData();
        setLoading(false);
      }
    }
  }, [type, workspace, accountId]);

  return (
    <Card
      sx={{
        width: '100%',
        height: height,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        px={2}
        py={1}
        className={isEditable && panelClasses.gridItemTitle}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="subtitle1">{`Most Billed Services (${moment()
          .startOf('month')
          .subtract(1, 'months')
          .format('MMMM')})`}</Typography>
        <Stack
          direction="row"
          justifyContent={'flex-end'}
          alignItems="center"
          spacing={1}
        >
          <ToggleButtonGroup
            sx={{ backgroundColor: 'transparent' }}
            size="small"
            value={type}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="SERVICE">Service</ToggleButton>
            <ToggleButton value="REGION">Region</ToggleButton>
          </ToggleButtonGroup>
          <Dropdown
            placeholder="Select account"
            value={accountId}
            onChange={setAccountId}
            options={
              accountsResp?.data?.accounts.map((i) => ({
                label: i.name,
                value: i.id,
              })) ?? []
            }
          />
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      </Box>
      <Box p={1.5} flex={1} overflow="auto">
        {!accountId && permissionError && (
          <Alert severity="error">
            <AlertTitle>{permissionError}</AlertTitle>
            Make sure that you've enabled Cost Explorer API access. For more
            information click{' '}
            <Link
              target="_blank"
              href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html"
            >
              here
            </Link>
            .
          </Alert>
        )}
        <CostTable
          costByRegion={dashboardData.costByRegion}
          costByService={dashboardData.costByService}
          loading={loading}
          type={type}
        />
      </Box>
    </Card>
  );
}

export default CostPerService;
