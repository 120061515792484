import { useMemo } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { formatCount } from 'utils/formatter';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { useSelector } from 'store';
import AlarmIcon from '@mui/icons-material/NotificationImportantOutlined';
import IssueIcon from '@mui/icons-material/BugReportOutlined';

const SecondaryText = ({ message, timestamp }) => {
  return (
    <>
      <Typography
        gutterBottom
        color="text.primary"
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
      >
        {message}
      </Typography>
      <Box display="flex" justifyContent={'flex-end'}>
        <Typography color="text.secondary">{timestamp}</Typography>
      </Box>
    </>
  );
};

const PreviewAnnotations = ({ issues = [], alarms = [], timestamp, tz }) => {
  const store = useSelector((s) => s);
  const {
    workspace: { workspace },
  } = store;

  const getTimestamp = (timestamp, tz) => {
    const FORMAT = 'DD MMM hh:mm:ss A';
    if (tz === 'local') {
      return moment.utc(timestamp).local().format(FORMAT);
    }
    return moment.utc(timestamp).format(FORMAT);
  };

  const listItems = useMemo(() => {
    const items = [];
    issues.forEach((i) => {
      items.push({
        type: 'issue',
        primary: i.message,
        to: `/${workspace.id}/issues/${i.id}`,
      });
    });

    alarms.forEach((a) => {
      items.push({
        type: 'alarm',
        primary: a.name,
        to: `/${workspace.id}/alarms/${a.id}`,
      });
    });
    return items;
  }, [issues, alarms]);

  return (
    <Stack
      sx={{
        pointerEvents: 'auto',
      }}
      minWidth={300}
      maxWidth={400}
      maxHeight={200}
      spacing={1}
      overflowY="auto"
      p={2}
      bgcolor="background.paper"
    >
      <Typography paragraph fontWeight={500}>
        {getTimestamp(timestamp, tz)}
      </Typography>
      {listItems.map((i, idx) => (
        <ListItem
          dense
          button
          target="_blank"
          disableRipple
          component={Link}
          to={i.to}
          key={idx}
          sx={{
            bgcolor: 'grey.50012',
          }}
        >
          <ListItemIcon sx={{ width: 8 }}>
            <SvgIcon sx={{ width: 16, height: 16 }}>
              {i.type === 'issue' ? <IssueIcon /> : <AlarmIcon />}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={i.primary}
            primaryTypographyProps={{
              variant: 'body2',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
        </ListItem>
      ))}
    </Stack>
  );
};

export default PreviewAnnotations;
