import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { timeZones } from './constants';

const Timezones = ({ value, onChange }) => {
  return (
    <List>
      {timeZones.map((z) => (
        <ListItemButton
          selected={z.value === value}
          onClick={() => onChange(z.value)}
        >
          <ListItemText primary={z.label} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default Timezones;
