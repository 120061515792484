import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { calculateLegendValues } from './utils';
import LegendList from './LegendList';
import LegendTable from './LegendTable';

const Legend = ({
  legendRef,
  series,
  data,
  legendView,
  legendAppendValues,
  yValFormatter,
  yRightValFormatter,
}) => {
  const classes = useStyles();

  const legendCalculations = useMemo(() => {
    if (data) {
      return calculateLegendValues({
        legendValues: legendAppendValues,
        data: data || [],
        seriesScale: series?.map((srs) => srs.scale),
        yValFormatter,
        yRightValFormatter,
      });
    }
    return [];
  }, [data, legendAppendValues, yValFormatter, yRightValFormatter]);

  return (
    <Box ref={legendRef} className={classes.legendContainer}>
      {legendView === 'list' && series && (
        <LegendList series={series} legendCalculations={legendCalculations} />
      )}
      {legendView === 'table' && series && (
        <LegendTable series={series} legendCalculations={legendCalculations} />
      )}
    </Box>
  );
};

export default Legend;
