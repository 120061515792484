import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import UpIcon from '@mui/icons-material/ArrowDropUp';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useSelector, useDispatch } from 'store';
import { setActiveWorkspace } from 'store/actions';

import { switcherStyles } from './styles';

import { AccountSettingsIcon } from 'constants/Icons';

const AccountSwitcher = ({}) => {
  const classes = switcherStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((state) => state);
  const { user, workspace } = store;

  const onSwitch = (acl, orgUser) => {
    popupState.close();
    const { workspace, organization, org_role, ws_role } = acl;
    dispatch(
      setActiveWorkspace(
        {
          id: organization.id,
          name: organization.name,
          role: org_role,
          created_at: organization.created_at,
          plan: organization.product_key,
        },
        {
          id: workspace.id,
          name: workspace.name,
          role: ws_role,
        },
      ),
    );
    history.push('/');
  };

  const org = workspace && user.acl.find((i) => i.org_id === workspace.org?.id);

  const orgs = Object.values(groupBy(user.acl, 'org_id'));

  const iconStyle = {
    color: 'text.secondary',
    width: 24,
    height: 24,
    paddingTop: '2px',
  };

  return (
    <Box>
      <ListItem
        disableGutters
        disablePadding
        className={classes.item}
        {...bindTrigger(popupState)}
      >
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          secondaryTypographyProps={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          primary={workspace.workspace?.name}
        />
        {!popupState.isOpen ? (
          <DownIcon sx={iconStyle} />
        ) : (
          <UpIcon sx={iconStyle} />
        )}
      </ListItem>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Box mt={1} />
        {orgs
          .filter(
            (acls) =>
              acls.filter((acl) => acl.workspace && acl.ws_role).length > 0,
          )
          .map((acls, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ lineHeight: 1.5, fontSize: 14 }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <AccountSettingsIcon
                      style={{ width: '1.2em', height: '1.2em' }}
                    />
                    {acls[0].organization?.name}
                  </Stack>
                </ListSubheader>
              }
            >
              {acls
                .filter((acl) => acl.workspace && acl.ws_role)
                .map((acl) => (
                  <ListItem
                    button
                    key={acl.workspace_id}
                    onClick={() => onSwitch(acl)}
                    disabled={acl.workspace_id === workspace.workspace?.id}
                  >
                    <ListItemText primary={acl.workspace.name} />
                  </ListItem>
                ))}
            </List>
          ))}
      </Menu>
    </Box>
  );
};

export default AccountSwitcher;
