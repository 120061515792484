import React from 'react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import { useSubscription } from '@apollo/client';
import { GET_ALARMS } from 'graphql/alarms/queries';
import { useSelector } from 'store';
import Card from '../Card';
import { ReactComponent as AlarmIcon } from 'assets/icons/alarm.svg';
import useAlarmData from 'modules/alarms/useAlarmData';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'components/IconButton';

const ActiveAlarms = ({ title, icon, isEditable, onDelete, serviceName }) => {
  const { workspace } = useSelector((s) => s.workspace);
  const { data, loading, error } = useSubscription(GET_ALARMS(serviceName), {
    variables: {
      workspaceId: workspace.id,
      serviceName,
    },
  });
  const { alarms, firingCount } = useAlarmData(data?.alarm_rules);

  return (
    <Card
      keyField="id"
      loading={loading}
      error={error?.message}
      rows={alarms.filter((i) => i.state === 'Alerting').splice(0, 5)}
      title={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1">{title}</Typography>
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      }
      getPrimaryText={(item) => item.name}
      getSecondaryText={(item) => item.description}
      getLink={(item) => `/${item.workspace_id}/alarms/${item.id}`}
      Icon={AlarmIcon}
      iconColor="error"
      count={firingCount}
      renderActions={(item) => (
        <Typography color="text.secondary" variant="body2">
          {moment(item.errorStateSince).fromNow(true)}
        </Typography>
      )}
      isEditable={isEditable}
    />
  );
};

export default ActiveAlarms;
