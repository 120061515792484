import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RadioGroup from '@mui/material/RadioGroup';
import Button from 'components/Button';
import Radio from 'components/Form/Radio';
import Typography from '@mui/material/Typography';
import {
  LIST_DASHBOARDS,
  GET_DASHBOARD_BY_ID,
} from 'graphql/dashboards/queries';
import { GET_ACCOUNTS_BY_WORKSPACE_ID } from 'graphql/accounts/queries';
import useDashboardTemplates from '../hooks/useDashboardTemplates';
import CustomSelect from 'components/Form/Select';
import { useQuery } from '@apollo/client';
import { useSelector } from 'store';
import { getVariableUsage, resolveTemplateVariables } from '../utils';
import VariablesBar from './VariablesBar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { DEFAULT_REGION } from 'constants/aws-regions';

const mapOption = (i) => ({
  label: i.name,
  value: i.id,
});

const panelMapOption = (p) => ({
  label: p.title,
  value: p.id,
  panel: p,
});

const ImportPanelModal = ({
  onClose,
  onSubmit,
  variables: existingVariables,
  defaultRegion = DEFAULT_REGION,
  noVariablesInPanel,
  autoSelectTemplate,
}) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);
  const [type, setType] = useState('library');
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [showVariablesPopup, setShowVariablesPopup] = useState(false);
  const [variables, setVariables] = useState([]);
  const templates = useDashboardTemplates();
  const { loading, data } = useQuery(LIST_DASHBOARDS, {
    variables: {
      workspaceId: workspace.id,
      isFolder: false,
    },
    skip: type !== 'mine',
    fetchPolicy: 'cache-first',
  });
  const { data: dashboardData, loading: loadingDashboard } = useQuery(
    GET_DASHBOARD_BY_ID,
    {
      variables: {
        id: selectedDashboard,
      },
      skip: type !== 'mine' || !selectedDashboard,
      fetchPolicy: 'cache-first',
    },
  );

  const selectedDashboardVariables = useMemo(() => {
    if (type === 'mine') {
      return dashboardData?.dashboard.data.variables || [];
    } else if (type === 'library') {
      return (
        (templates || []).find((template) => template.id === selectedDashboard)
          ?.data.variables || []
      );
    }
  }, [dashboardData, selectedDashboard, templates]);

  const { data: accountsData } = useQuery(GET_ACCOUNTS_BY_WORKSPACE_ID, {
    variables: {
      workspaceId: workspace.id,
    },
    fetchPolicy: 'cache-first',
  });
  const accounts = accountsData?.accounts || [];
  const hasAWSAccounts = accounts.length > 0;

  useEffect(() => {
    setSelectedDashboard(null);
  }, [type]);

  useEffect(() => {
    setSelectedPanels([]);
    if (!autoSelectTemplate || !templates || type === 'mine') {
      return;
    }
    const autoSelTpl = templates.find(
      (tpl) => tpl.name === autoSelectTemplate.template,
    );

    const autoSelPanels = autoSelTpl.data.panels
      .filter((p) =>
        autoSelectTemplate.metric_panels.find(
          (autoSelPanel) => autoSelPanel === p.title,
        ),
      )
      .map(panelMapOption);

    setSelectedPanels(autoSelPanels);
  }, [selectedDashboard]);

  useEffect(() => {
    if (!accounts.length) {
      setType('mine');
    }
  }, [accounts]);

  useEffect(() => {
    if (!autoSelectTemplate || !templates) {
      return;
    }
    const autoSelTpl = templates.find(
      (tpl) => tpl.name === autoSelectTemplate.template,
    );
    setSelectedDashboard(autoSelTpl.id);
  }, [templates]);

  useEffect(() => {
    const panelsToImport = selectedPanels.map(({ panel }) => panel);

    if (!panelsToImport.length) {
      setVariables([]);
      return;
    }

    const additionalVariables = selectedDashboardVariables
      .map((variable) => ({
        ...variable,
        ...getVariableUsage(
          variable,
          panelsToImport,
          selectedDashboardVariables,
        ),
      }))
      .filter(
        (usage) => usage.usedInPanels.length || usage.usedInVariables.length,
      )
      .filter(
        (variable) =>
          !(existingVariables || []).find(
            (existingVar) => existingVar.name === variable.name,
          ),
      )
      .map((variable) => ({
        ...variable,
        hint: [
          variable.usedInPanels.length ? (
            <Typography variant="subtitle2">
              {`Referenced by panels: ${variable.usedInPanels
                .map((p) => p.name)
                .join(', ')}`}
            </Typography>
          ) : null,
          variable.usedInVariables.length ? (
            <Typography variant="subtitle2">
              {`Referenced by variables: ${variable.usedInVariables
                .map((v) => v.name)
                .join(', ')}`}
            </Typography>
          ) : null,
          variable.usesVariables.length ? (
            <Typography variant="subtitle2">
              {`References variables: ${variable.usesVariables
                .map((v) => v.name)
                .join(', ')}`}
            </Typography>
          ) : null,
        ],
      }))
      .map((variable) =>
        variable.config?.queryType === 'regions'
          ? {
              ...variable,
              value: variable.value.match(/^\$/gi)?.length
                ? defaultRegion
                : variable.value || defaultRegion,
            }
          : { ...variable, value: undefined },
      );

    setVariables(additionalVariables);
  }, [selectedPanels, selectedDashboardVariables]);

  const panels = useMemo(() => {
    if (!selectedDashboard) return [];
    if (type === 'mine' && dashboardData) {
      return dashboardData.dashboard.data.panels || [];
    } else if (type === 'library') {
      const db = (templates || []).find((db) => db.id === selectedDashboard);
      if (db) {
        return db.data.panels;
      }
      return [];
    }
    return [];
  }, [selectedDashboard, dashboardData, templates]);

  const onImport = () => {
    const panelsToImport = selectedPanels.map(({ panel }) => panel);
    const panels = noVariablesInPanel
      ? panelsToImport.map((p) => resolveTemplateVariables(p, variables))
      : panelsToImport;
    return onSubmit(
      panels,
      variables.map(({ hint, ...v }) => v),
    );
  };

  const dashboards = useMemo(() => {
    if (type === 'mine' && data) {
      return data.rows.map(mapOption);
    } else if (type === 'library' && templates) {
      return templates
        .filter(({ tags }) =>
          tags[0]?.startsWith('AWS') ? hasAWSAccounts : true,
        )
        .map(mapOption);
    }
    return [];
  }, [data, templates]);

  const variablesNotSet = useMemo(
    () =>
      !variables.length ||
      variables.some((variable) => variable.hint && !variable.value),
    [variables],
  );

  const onVariableChange = (value, variable) => {
    setVariables(
      variables.map((v) => (v.id === variable.id ? { ...v, value } : v)),
    );
  };

  return (
    <>
      <Dialog open maxWidth="sm" fullWidth>
        <DialogTitle>Import Panel</DialogTitle>
        <DialogContent>
          {
            <RadioGroup
              row
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <Radio label="Import from templates" value="library" />
              <Radio label="Import from my dashboards" value="mine" />
            </RadioGroup>
          }
          <CustomSelect
            transformValue
            isLoading={loading}
            label={type === 'mine' ? 'Select dashboard' : 'Select template'}
            options={dashboards}
            onChange={(template) => {
              setSelectedDashboard(template);
            }}
            value={selectedDashboard}
          />
          {selectedDashboard && (
            <CustomSelect
              isMulti
              isLoading={loadingDashboard}
              label="Select panels to import"
              options={panels.map(panelMapOption)}
              onChange={setSelectedPanels}
              value={selectedPanels}
              hint={
                'Variables referenced by the selected panel(s) will be imported too.'
              }
            />
          )}
          {variables.length && noVariablesInPanel ? (
            <>
              <Alert
                severity={variablesNotSet ? 'warning' : 'info'}
                variant="outlined"
              >
                <Typography variant="caption">
                  Some of the selected panels reference variables whose values
                  need to be set.
                </Typography>
              </Alert>
              <VariablesBar
                onVariableChange={onVariableChange}
                variables={variables}
                defaultRegion={defaultRegion}
                direction="column"
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!selectedPanels.length} onClick={onImport}>
            Import
          </Button>
        </DialogActions>
      </Dialog>
      {/* {showVariablesPopup && (
        <Dialog open maxWidth="sm" fullWidth>
          <DialogTitle>Set Variables</DialogTitle>
          <DialogContent>
            <Typography variant="caption" color="text.secondary">
              Set the following variables that have been referenced by the
              selected panels
            </Typography>
            <VariablesBar
              onVariableChange={onVariableChange}
              variables={variables}
              defaultRegion={defaultRegion}
              compact
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setShowVariablesPopup(false)}>
              Cancel
            </Button>
            <Button
              disabled={!selectedPanels.length}
              onClick={() => setShowVariablesPopup(false)}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  );
};

export default ImportPanelModal;
