import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Button from 'components/Button';
import Input from 'components/Form/Formik/FormikInput';
import Typography from '@mui/material/Typography';
import { register, login, hub } from 'api/auth';
import Alert from '@mui/material/Alert';
import useQueryParams from 'hooks/useQueryParams';
import ConfirmSignup from './ConfirmCode';
import {
  DEFAULT_PLAN,
  PRODUCT_KEY,
  USE_TIMER_KEY,
  USE_TIMER_VALUE,
} from 'store/types';
import usePageEvent from 'hooks/usePageEvent';
import {
  analyticsIdentify,
  analyticsTrack,
  analyticsGroup,
} from 'utils/segment';
import { useSelector } from 'store';

const validationSchema = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

function FormSignUp({ hideHeader, onSignUpSuccess, email, disableEmail }) {
  const store = useSelector((s) => s);
  const [error, setError] = useState();
  const [authState, setAuthState] = useState({
    state: 'signup',
    username: '',
  });
  const { plan, use_timer } = useQueryParams();

  usePageEvent('Sign Up', {
    section: 'New Account',
    flow: 'Sign Up',
  });

  useEffect(() => {
    localStorage.setItem(PRODUCT_KEY, plan || DEFAULT_PLAN);
    if (use_timer === USE_TIMER_VALUE) {
      localStorage.setItem(USE_TIMER_KEY, USE_TIMER_VALUE);
    }
  }, [plan, use_timer]);

  const onSubmit = async (data) => {
    try {
      setError();
      const username = data.email || email;
      await register(username, data.password, data.name);
      setAuthState({
        state: 'confirmSignup',
        username: username,
        password: data.password,
      });
    } catch (e) {
      setError(e.message);
    }
  };

  const onAuthStateChange = async () => {
    try {
      const resp = await login(authState.username, authState.password);
      hub.emit('auth', {
        event: 'signIn',
        data: resp,
      });
      if (window.Reflio) {
        try {
          await window.Reflio.signup(email);
        } catch (e) {
          console.log(e);
        }
      }
      if (onSignUpSuccess) {
        await onSignUpSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  };

  let component;
  switch (authState.state) {
    case 'confirmSignup':
      component = (
        <ConfirmSignup
          mode="signup"
          username={authState.username}
          onAuthStateChange={onAuthStateChange}
        />
      );
      break;
    default:
      component = (
        <Box>
          {!hideHeader && (
            <>
              <Helmet title="Sign up" />
              <Typography gutterBottom variant="h5" fontWeight={700}>
                Create an Account
              </Typography>
              <Typography color="textSecondary">
                Signup for free! No credit card required.
              </Typography>
            </>
          )}
          <Box mt={3}>
            {error && <Alert severity="error">{error}</Alert>}
            <Formik
              initialValues={{
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
              }}
              onSubmit={onSubmit}
              validationSchema={
                !disableEmail
                  ? validationSchema.shape({
                      email: yup
                        .string('Enter your email')
                        .email('Enter a valid email')
                        .required('Email is required'),
                    })
                  : validationSchema
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    margin="dense"
                    name="name"
                    component={Input}
                    label="Your name"
                    placeholder="John Doe"
                  />
                  {!disableEmail && (
                    <Field
                      margin="dense"
                      name="email"
                      type="email"
                      component={Input}
                      label="Email"
                      placeholder="john@example.com"
                    />
                  )}
                  <Field
                    margin="dense"
                    name="password"
                    type="password"
                    component={Input}
                    label="Password"
                    helperText="Password should be of minimum 8 characters length"
                    placeholder="Enter password"
                  />
                  <Field
                    margin="dense"
                    name="confirmPassword"
                    type="password"
                    component={Input}
                    label="Confirm your password"
                    placeholder="Enter confirm password"
                  />
                  <Typography sx={{ my: 1 }}>
                    By creating an account you agree to our{' '}
                    <MuiLink
                      color="primary"
                      href="https://pages.kloudmate.com/terms-of-service"
                      target="_blank"
                    >
                      terms of service
                    </MuiLink>{' '}
                    and{' '}
                    <MuiLink
                      color="primary"
                      href="https://pages.kloudmate.com/privacy-policy"
                      target="_blank"
                    >
                      privacy policy
                    </MuiLink>
                  </Typography>

                  <Button
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                  >
                    Sign Up
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            mt={2}
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography color="textSecondary">
              Already have an account?
            </Typography>
            <MuiLink
              underline="always"
              component={Link}
              to="/login"
              ml={1}
              fontWeight={600}
              variant="subtitle1"
            >
              Login
            </MuiLink>
          </Box>
        </Box>
      );
  }

  return <Box>{component}</Box>;
}

export default FormSignUp;
