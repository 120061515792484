import { gql } from '@apollo/client';

export const GET_ALL_ENABLED_SERVICE_DEFINITIONS = gql`
  query getAllEnabledServiceDefinitions {
    service_definitions_aggregate(where: {enabled: {_eq: true}}) {
      aggregate {
        count
      }
    }
  }
`;