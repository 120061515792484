import { gql } from '@apollo/client';
import { planFields } from './fragments';

export const GET_PLANS = gql`
  query getPlans {
    plans(where: { allow_change_to: { _eq: true } }) {
      ...Plan
      prices(where: { product: { is_active: { _eq: true } } }) {
        id
        items_count
        price_cents
        stripe_price_id
        product {
          id
          type
          name
          description
        }
      }
    }
  }
  ${planFields}
`;

export const GET_PLAN_BY_SKU = gql`
  query getPlan($sku: String!) {
    plans(where: { sku: { _eq: $sku } }) {
      ...Plan
      prices(where: { product: { is_active: { _eq: true } } }) {
        id
        items_count
        price_cents
        stripe_price_id
        product {
          id
          type
          name
          description
        }
      }
    }
  }
  ${planFields}
`;
