import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
  split,
  defaultDataIdFromObject,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { RetryLink } from '@apollo/client/link/retry';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { env } from 'utils/env';

const API_URL = env.REACT_APP_GRAPHQL_URL;

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
        );
        if (err.message.includes('JWTExpired')) {
          window.location.reload();
        }
      }
    }
    if (networkError) {
      // TODO handle network error Socket closed with event 4400 {"server_error_msg":"4400: Connection initialization failed: Missing 'Authorization' or 'Cookie' header in JWT authenticati
      console.error(`[Network error]:`);
      console.log(networkError);
    }
  },
);

const wsLink = new GraphQLWsLink(
  createClient({
    url: API_URL.replace('https', 'wss').replace('http', 'ws'),
    shouldRetry: (error) => {
      return true;
    },
    keepAlive: 10000,
    retryAttempts: Infinity,
    connectionParams: async () => {
      try {
        const token = localStorage.getItem('token');
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } catch (e) {
        console.error('Connection params error: ', e.message);
      }
    },
  }),
);

const httpLink = new HttpLink({
  uri: API_URL,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (responseObject) => {
      const { __typename, id, ...rest } = responseObject;
      switch (__typename) {
        case 'account_sync_settings':
          return rest.account_id + rest.service_type;
        case 'smart_insights_rules_ignore':
          return rest.workspace_id + rest.rule_id;
        case 'issue_users':
          return rest.issue_id + rest.user_id;
        default: {
          return defaultDataIdFromObject(responseObject);
        }
      }
    },
  }),
  link: ApolloLink.from([errorLink, authLink, link]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
export default client;
