import React from 'react';
import Button from '../Button';
import Box from '@mui/material/Box';
import { useSelector } from 'store';
import { signOut } from 'api/auth';

const GenericHeader = () => {
  const theme = useSelector((state) => state.theme);
  const isDarkMode = theme === 'dark';
  const logoPath = isDarkMode
    ? `/assets/images/logo_full_white.svg`
    : `/assets/images/logo_full_dark.svg`;

  return (
    <Box
      p={3}
      display="flex"
      justifyContent="space-between"
      sx={{ borderBottom: '1px solid ', borderBottomColor: 'divider' }}
    >
      <img height={25} alt="KloudMate" src={logoPath} />
      <Button variant="text" onClick={signOut}>
        Log out
      </Button>
    </Box>
  );
};

export default GenericHeader;
