import React from 'react';
import { useStyles } from './styles';
import { PropTypes } from 'prop-types';
import Stack from '@mui/material/Stack';
import cx from 'classnames';

const LoaderWithLogo = ({ tip }) => {
  const classes = useStyles();
  const isSmall = !!tip;

  return (
    <Stack height="100%" width="100%" margin="auto" justifyContent="center">
      <div className={classes.logoWrapper}>
        <div className={classes.kmLogo}>
          <div
            className={cx(classes.kmGlass, { [classes.smallIcon]: isSmall })}
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1280 1024"
              xmlSpace="preserve"
            >
              <g>
                <path
                  className={classes.st0}
                  d="M695.8,293.6c-172.6-114-380.7,50.3-311.9,244.4c62.6,174,296.2,184,378.4,19.1c5.1,31.6,11,61,16.1,92.3
                            		c0.4,2.2-2,5.3-3.9,7.3c-13,13.7-26.7,26.5-42.2,37.4c-50.6,37.7-113.6,51.9-175.9,48.6c-49.3-2.2-97.1-21.2-136.7-50.4
                            		c-1.6-1.2-4.8-3.2-4.8-3.2s-2.3,2.5-3.8,4.1c-23.3,23.6-46.8,46.9-70.2,70.5c-5.6,6.5-13.5,7.8-20.9,10.4
                            		c-22.9,1.1-40.4-17.1-37-40c2.4-5.2,3.7-11.4,7.3-15.5c24-25.9,50.2-51.5,75.1-76.3c-78.6-94.1-81.1-238.4-11.5-338.2
                            		c53.2-80.9,152-121.6,246.9-116c0.1,0,0.2,0,0.2,0c37.4,4.7,72.1,18.1,106.5,32.8c1.6,0.6,9.4,4.2,9.4,4.2"
                />
              </g>
            </svg>
          </div>
          <div className={cx(classes.kmRate, { [classes.smallIcon]: isSmall })}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1280 1024"
              xmlSpace="preserve"
            >
              <g>
                <path
                  className={classes.st0}
                  d="M1025.9,490c-2,5.2-3.5,10.6-6,15.5c-2.9,5.9-8.9,8.2-14.8,9.3c-5.1,1-10.5,1.1-15.8,1.1c-20.8,0.1-41.7,0.2-62.5-0.1
                            		c-3.7,0-5.7,1.2-7.5,4.2c-8.9,15.4-17.9,30.7-27,46c-11,18.5-22,37-33.3,55.3c-6.5,10.6-17.2,13-28.4,11.4
                            		c-11.1-1.6-19.6-8.4-22.4-19.5c-3.2-12.8-5-25.9-7.4-38.9c-2.1-11.2-4.1-22.5-6.1-33.7c-2.6-14.3-5.2-28.5-7.9-42.8
                            		c-2.7-14.8-5.4-29.5-8.2-44.3c-2.6-14.1-5.2-28.2-7.8-42.3c-2-10.8-4.1-21.6-6.3-33.5c-12.5,46-24.7,90.9-37,136.2
                            		c-1.8,0-3.8,0-5.8,0c-62.9,0.3-125.8,0.7-188.8,1c-4.4,0-8.7,0.1-13.1,0c-10.7-0.3-21.6-10.6-21.9-23.7c-0.3-13,6.6-22.5,18.4-25.9
                            		c5.3-1.5,11.1-1.3,16.7-1.3c30.2-0.1,60.3-0.1,90.5-0.1c21.4,0,42.9,0,64.4,0c4.1-15.2,8-29.4,11.8-43.7
                            		c6.2-22.9,12.5-45.8,18.7-68.7c6.1-22.6,12.2-45.2,18.3-67.8c1.7-6.4,3.1-13,5.3-19.3c3.2-9,10.2-14.7,19.4-16.1
                            		c8.4-1.3,16.9-1.1,24.6,4.3c6,4.2,9.1,9.8,10.4,16.5c3.9,19.7,7.4,39.4,11.1,59.1c4.7,25.3,9.3,50.7,14,76
                            		c4.3,23.6,8.7,47.1,13,70.7c3.8,21.4,7.5,42.8,11.2,64.1c0.1,0.4,0.5,0.8,1.2,1.9c4-6.6,7.9-12.8,11.7-19.1
                            		c10.8-18.2,21.5-36.5,32.5-54.7c0.8-1.3,3.4-2.1,5.2-2.1c32.2-0.1,64.3,0.1,96.5-0.2c7.6-0.1,14.8-0.5,21.4,4.1
                            		c6.3,4.5,9.6,10.4,10.4,18c0.1,0.7,0.6,1.3,1,2C1025.9,489.3,1025.9,489.6,1025.9,490z"
                />
              </g>
            </svg>
            <div className={classes.fadeIn}></div>
            <div className={classes.fadeOut}></div>
          </div>
        </div>
      </div>
      {tip && <div className={classes.tip}>{tip}</div>}
    </Stack>
  );
};

LoaderWithLogo.propTypes = {
  tip: PropTypes.string,
};

export default LoaderWithLogo;
