import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Cell from '@mui/material/TableCell';
import Row from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import get from 'lodash/get';
import Checkbox from '@mui/material/Checkbox';

const TableRow = ({
  row,
  index,
  keyField,
  renderChildren,
  handleClick,
  columns = [],
  selectable,
  isItemSelected,
  handleCheckBox,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  let colSpan = renderChildren ? columns.length + 1 : columns.length;
  colSpan = selectable ? colSpan + 1 : colSpan;

  return (
    <>
      <Row
        {...props}
        onClick={handleClick}
        sx={{
          '& > *': { borderBottom: 'unset', cursor: handleClick && 'pointer' },
        }}
      >
        {renderChildren && (
          <Cell width={65}>
            <IconButton
              sx={{ p: 0 }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Cell>
        )}
        {selectable && (
          <Cell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(e) => e.stopPropagation()}
              onChange={handleCheckBox}
            />
          </Cell>
        )}
        {columns.map((col, idx) => (
          <Cell
            key={`${get(row, keyField)}${col.dataField}${col.keyField || ''}`}
            align={col.numeric ? 'right' : col.align}
            sx={col.sx}
          >
            {col.formatter
              ? col.formatter(
                  get(row, col.dataField),
                  row,
                  index,
                  col.formatExtraData,
                )
              : get(row, col.dataField)}
          </Cell>
        ))}
      </Row>
      {renderChildren && (
        <Row>
          <Cell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {renderChildren(row)}
            </Collapse>
          </Cell>
        </Row>
      )}
    </>
  );
};

export default TableRow;
