import gql from 'graphql-tag';

const workspaceFields = gql`
  fragment Workspace on workspaces {
    id
    name
  }
`;

export const ADD_WORKSPACE = gql`
  mutation AddWorkspace($orgId: String!, $name: String!) {
    row: insert_workspaces_one(object: { org_id: $orgId, name: $name }) {
      ...Workspace
    }
  }
  ${workspaceFields}
`;

export const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($workspaceId: String!) {
    delete_workspaces_by_pk(id: $workspaceId) {
      id
    }
  }
`;

export const GET_WORKSPACE = gql`
  query GetWorkspace($workspaceId: String!) {
    row: workspaces_by_pk(id: $workspaceId) {
      ...Workspace
      private_key
      public_key
      otel_allowed_hosts
    }
  }
  ${workspaceFields}
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace(
    $workspaceId: String!
    $values: workspaces_set_input!
  ) {
    row: update_workspaces_by_pk(
      pk_columns: { id: $workspaceId }
      _set: $values
    ) {
      ...Workspace
      private_key
      public_key
      otel_allowed_hosts
    }
  }
  ${workspaceFields}
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($workspaceId: String!) {
    rows: accounts(where: { workspace_id: { _eq: $workspaceId } }) {
      id
      name
      provider_account_id
      provider
    }
  }
`;

export const GET_ORG_WORKSPACES = gql`
  query getOrgWorkdspaces($orgId: String!) {
    workspaces(where: { org_id: { _eq: $orgId } }) {
      ...Workspace
    }
  }
  ${workspaceFields}
`;

export const GET_SAMPLING_POLICY = gql`
  query getSamplingPolicy($workspaceId: String!) {
    workspace: workspaces_by_pk(id: $workspaceId) {
      id
      sampling_policy
    }
  }
`;

export const UPDATE_SAMPLING_POLICY = gql`
  mutation updateSamplingPolicy(
    $workspaceId: String!
    $sampling_policy: jsonb
  ) {
    update_workspaces_by_pk(
      pk_columns: { id: $workspaceId }
      _set: { sampling_policy: $sampling_policy }
    ) {
      id
      sampling_policy
    }
  }
`;
