import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useSelector, useDispatch } from 'store';
import Avatar from '@mui/material/Avatar';
import { setActiveWorkspace, storeSession, toggleTheme } from 'store/actions';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import Logout from '@mui/icons-material/Logout';
import { analyticsTrack } from 'utils/segment';
import { stringToColor } from 'utils';
import ListSubheader from '@mui/material/ListSubheader';
import { signOut } from 'api/auth';

const AccountMenu = ({ headerRef }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { user, workspace, theme } = store;
  const isDarkMode = theme === 'dark';

  const onToggleTheme = () => {
    dispatch(toggleTheme());
  };

  async function onSignOut() {
    try {
      signOut();
      analyticsTrack('Signed Out', {
        userId: user.id,
        properties: {
          username: user.name,
        },
        context: {
          groupId: workspace.org.id,
        },
      });
      dispatch(storeSession(null));
      dispatch(setActiveWorkspace(null, null));
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }

  return (
    <Box>
      <Avatar
        {...bindTrigger(popupState)}
        sx={{
          backgroundColor: stringToColor(user.name, theme),
          cursor: 'pointer',
          width: 32,
          height: 32,
        }}
      >
        <AccountCircleIcon />
      </Avatar>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        anchorEl={headerRef?.current}
      >
        <List
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ lineHeight: 2 }}>
              {user.name}
            </ListSubheader>
          }
        >
          <ListItem button onClick={onToggleTheme}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              {isDarkMode ? (
                <LightModeOutlinedIcon />
              ) : (
                <DarkModeOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={isDarkMode ? 'Light Mode' : 'Dark Mode'} />
          </ListItem>
          <Divider />
          <ListItem button onClick={onSignOut} sx={{ color: 'error.main' }}>
            <ListItemIcon sx={{ minWidth: 36, color: 'inherit' }}>
              <Logout sx={{ fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Menu>
    </Box>
  );
};

export default AccountMenu;
