import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';

import { TYPES } from 'context/alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'visible',
    textAlign: 'center',
  },
}));

const SeverityIcon = ({ severity }) => {
  const colors = {
    error: '#F74A4E',
    warning: '#FFC107',
    info: '#6C63FF',
    success: '#1AAE6F',
  };

  const color = colors[severity] ?? colors.info;

  return (
    <svg
      width="148"
      height="123"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g>
        <title>Layer 1</title>
        <path
          id="svg_1"
          fill="none"
          d="m0,5.05479c0,-2.79168 2.10133,-5.05479 4.69345,-5.05479l138.6131,0c2.59203,0 4.69345,2.26311 4.69345,5.05479l0,112.89041c0,2.79159 -2.10141,5.05479 -4.69345,5.05479l-138.6131,0c-2.59212,0 -4.69345,-2.2632 -4.69345,-5.05479l0,-112.89041z"
        />
        <path
          id="svg_2"
          fill="#3F3D56"
          d="m105.392,108.267l-73.534,0.026c-3.695,0.001 -7.004,-1.908 -8.852,-5.106c-0.924,-1.6 -1.386,-3.355 -1.387,-5.11c0,-1.754 0.461,-3.51 1.384,-5.109l36.743,-66.8902c1.846,-3.2001 5.154,-5.1112 8.848,-5.1125c3.695,-0.0012 7.004,1.9075 8.852,5.1064l36.795,66.8723c0.922,1.596 1.383,3.348 1.383,5.102c0,1.754 -0.461,3.509 -1.384,5.109c-1.846,3.2 -5.154,5.111 -8.848,5.112l0,0l0,0zm-82.849,-10.191c0,1.598 8.115,-1.845 8.955,-0.39c1.682,2.91 -3.002,9.686 0.359,9.685c0,0 79.904,-1.766 81.584,-4.677c0.839,-1.456 1.259,-3.052 1.258,-4.649c0,-1.596 -0.421,-3.193 -1.262,-4.648l-36.794,-66.8722c-1.677,-2.9017 -4.687,-4.6383 -8.048,-4.6372c-3.36,0.0012 -46.051,74.5914 -46.052,76.1884l0,0z"
        />
        <path
          id="svg_3"
          fillOpacity="0.1"
          fill={color}
          d="m90.799,108.951c26.831,0 48.581,-21.927 48.581,-48.9754c0,-27.0485 -21.75,-48.9756 -48.581,-48.9756c-26.83,0 -48.58,21.9271 -48.58,48.9756c0,27.0484 21.75,48.9754 48.58,48.9754z"
        />
        <path
          id="svg_4"
          fill={color}
          d="m90.052,93.516c18.593,0 33.664,-15.0724 33.664,-33.6645c0,-18.5922 -15.071,-33.6642 -33.664,-33.6642c-18.592,0 -33.664,15.072 -33.664,33.6642c0,18.5921 15.072,33.6645 33.664,33.6645z"
        />
        <path
          id="svg_5"
          fill="#3F3D56"
          d="m44,79l-2,5.5c3.677,-2.6366 9.247,-5.944 13.817,-7.1613c-4.258,-2.0569 -9.204,-6.0553 -12.317,-9.3387l1.5,6.5c-20.509,-4.1773 -35.253,-18.9389 -35.259,-36.9619l-0.741,-0.2549c0.007,18.8255 13.663,37.4517 35,41.7168z"
        />
        <path
          id="svg_6"
          fill="white"
          d="m84.82,40.9582l10.868,23.3423l-5.608,2.8409l-12.437,-22.5478l7.177,-3.6354zm13.84,35.8126c-1.204,0.6099 -2.387,0.7511 -3.548,0.4235c-1.146,-0.3752 -1.999,-1.1174 -2.561,-2.2264c-0.562,-1.1091 -0.647,-2.2206 -0.256,-3.3346c0.407,-1.1617 1.213,-2.0476 2.417,-2.6575c1.172,-0.5938 2.331,-0.7031 3.477,-0.3279c1.13,0.3436 1.975,1.0699 2.537,2.179c0.562,1.109 0.655,2.2364 0.28,3.3821c-0.392,1.114 -1.173,1.9679 -2.346,2.5618z"
        />
      </g>
    </svg>
  );
};

const AlertComponent = ({ options, close }) => {
  const [val, setVal] = useState(options.inputValue);
  const classes = useStyles();

  const onOK = (customValue) => {
    if (options.onConfirm) {
      const result = options.onConfirm(
        options.type === TYPES.PROMPT ? val : customValue,
      );
      if (result && result.then) {
        return result.then(close);
      } else {
        close();
      }
    } else {
      close();
    }
  };

  const onCancel = () => {
    if (options.onCancel) {
      options.onCancel();
    }
    close();
  };

  let actions;
  if (options.actions) {
    if (options.actions.length) {
      actions = options.actions.map((action, index) => {
        const { label, ...rest } = action;
        return (
          <Button key={index} {...rest}>
            {action.label}
          </Button>
        );
      });
    }
  } else {
    actions = (
      <>
        {options.showCancelButton !== false && (
          <Button onClick={onCancel} variant="text" size="large">
            {options.cancellationText}
          </Button>
        )}
        <Button
          size="large"
          onClick={onOK}
          disabled={TYPES.PROMPT === options.type && !options.isValidInput(val)}
        >
          {options.confirmationText}
        </Button>
      </>
    );
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth={options.dialogSize || 'sm'}
      scroll="body"
      className={options.dialogClassName}
      classes={{ paper: classes.paper }}
    >
      <DialogContent className={classes.paper}>
        {options.severity && <SeverityIcon severity={options.severity} />}
        {options.icon}
        {options.title && (
          <Typography paragraph variant="h4" fontWeight={600}>
            {options.title}
          </Typography>
        )}
        {options.description && (
          <DialogContentText variant="subtitle1">
            {options.description}
          </DialogContentText>
        )}
        {options.render ? (
          options.render({ onConfirm: onOK, onCancel })
        ) : (
          <>
            {options.type === TYPES.PROMPT ? (
              options.renderInput ? (
                options.renderInput(val, setVal)
              ) : (
                <TextField
                  fullWidth
                  autoFocus
                  size="small"
                  multiline={options.multiline}
                  value={val}
                  placeholder={options.placeholder}
                  onChange={(e) => setVal(e.target.value)}
                />
              )
            ) : null}
          </>
        )}
      </DialogContent>
      {actions && (
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AlertComponent;
