import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomRadio = ({ label = '', value, checked, onChange, name }) => (
    <FormControlLabel
      name={name}
      onChange={onChange}
      value={value}
      checked={checked}
      control={<Radio />}
      label={label}
    />
  );

export default CustomRadio;
