import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { formatCount, formatBytesWithType } from 'utils/formatter';

export const constructPaymentMethod = (
  paymentMethod,
  defaultPaymentMethod,
) => ({
  id: paymentMethod.id,
  name: paymentMethod.billing_details.name,
  cardType: capitalize(paymentMethod.card.brand),
  cardNumber: `****${paymentMethod.card.last4}`,
  expiry: `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`,
  zip: paymentMethod.billing_details.address.postal_code,
  brand: paymentMethod.card.brand,
  isDefault: paymentMethod.id === defaultPaymentMethod,
});

export const getBaseSubscriptionItem = (subscription, plan) => {
  if (
    !['trialing', 'active', 'past_due', 'unpaid', 'incomplete'].includes(
      subscription.status,
    )
  ) {
    return null;
  }

  // // plans with amount > 0 and without trial period (business), start in incomplete status.
  // // In order to correctly display such plans, we allow incomplete subscriptions with no trial period
  // if (subscription.status === 'incomplete' && subscription.trial_end) {
  //   return null;
  // }

  const baseProductStripePriceId = (plan?.prices || []).find((price) => {
    return price.product.type === 'base_plan';
  })?.stripe_price_id;

  const baseSubscriptionItem = subscription.items.data.find(
    (item) => item.price.id === baseProductStripePriceId,
  );
  if (!baseSubscriptionItem) {
    return null;
  }
  const { trial_end, status, latest_invoice } = subscription;
  return { ...baseSubscriptionItem, trial_end, status, latest_invoice };
};

export const displayPrice = (
  price,
  { currency = 'usd', decimals = 2 } = {},
) => {
  return price < 0
    ? `-${currency === 'inr' ? '₹' : '$'}${(Math.abs(price) / 100).toFixed(
        decimals,
      )}`
    : `${currency === 'inr' ? '₹' : '$'}${(Math.abs(price) / 100).toFixed(
        decimals,
      )}`;
};

export const isFreePlan = (planTier) => planTier === 'free';

export const isPastDueSub = (subscriptions) =>
  subscriptions.find(({ status }) => status === 'past_due');

export const displayFeature = (item) => {
  const { items_count, price_cents } = item;
  switch (item.product.type) {
    case 'logs': {
      return {
        title: 'Logs Ingestion',
        value:
          items_count === 0
            ? `${displayPrice(price_cents * 1000)}/GB`
            : formatBytesWithType(items_count, 'MB', 0),
      };
    }
    case 'traces': {
      return {
        title: 'Traces Ingestion',
        value:
          items_count === 0
            ? `${displayPrice(price_cents * 1000)}/GB`
            : formatBytesWithType(items_count, 'MB', 0),
      };
    }
    case 'metrics': {
      return {
        title: 'Metrics Ingestion',
        value:
          items_count === 0
            ? `${displayPrice(price_cents * 1000000)}/mil`
            : formatCount(items_count),
      };
    }
  }
};

export const parseInvoiceData = (invoice) => {
  return {
    billingDate: moment(invoice.period_end * 1000).format('MMMM DD, YYYY'),
    status: invoice.status,
    paid: invoice.status === 'paid' ? 'Yes' : 'No',
    amount: displayPrice(invoice.total, {
      decimals: 2,
      currency: invoice.currency,
    }),
    amount_paid: displayPrice(invoice.amount_paid, {
      decimals: 2,
      currency: invoice.currency,
    }),
    pdfUrl: invoice.invoice_pdf,
    paymentUrl: invoice.hosted_invoice_url,
    requireAction: ['requires_payment_method', 'requires_action'].includes(
      invoice.payment_intent?.status,
    ),
    lastPaymentError: invoice.payment_intent?.last_payment_error?.message,
  };
};

export const parseCoupon = (proration) => {
  const coupon = proration?.discount?.coupon;
  return (
    coupon &&
    coupon.valid && {
      id: coupon.id,
      display_name: `Coupon Applied (${coupon.id}) - ${
        coupon.percent_off
          ? `${coupon.percent_off}%`
          : `${displayPrice(coupon.amount_off, {
              decimals: 2,
              currency: coupon.currency,
            })}`
      } off`,
    }
  );
};
