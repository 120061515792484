import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import OrderBy from './OrderBy';
import Limit from './Limit';
import { getColumns } from './utils';
import { useMemo } from 'react';

const TablePanelConfig = ({ name, values, setFieldValue }) => {
  // nodes to which order by has been applied
  const nodeIdx = values.node_configs
    .map((nc, idx) => ({
      idx,
      orderApplied: (nc.orderBy || []).length > 0,
    }))
    .filter((nc) => Boolean(nc.orderApplied))
    .map((nc) => nc.idx);

  const columns = useMemo(() => {
    return getColumns(values.node_configs);
  }, [values.node_configs]);

  return (
    <Paper variant="outlined" sx={{ mb: 2, px: 2, py: 1 }}>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <OrderBy
            name={name}
            columns={columns}
            nodeIdx={nodeIdx}
            queryOptions={values}
            updateQueryOptions={setFieldValue}
          />
        </Grid>
        <Grid item sm={4}>
          <Limit
            name={name}
            nodeIdx={nodeIdx}
            queryOptions={values}
            updateQueryOptions={setFieldValue}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TablePanelConfig;
