import Cookies from 'universal-cookie';

const cookies = new Cookies();

const cookie = {
  get: (key) => cookies.get(key),
  set: (key, val, options) => {
    cookies.set(key, val, options);
  },
  remove: (key, options) => cookies.remove(key, options),
};

export default cookie;
