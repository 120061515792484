import {
  STORE_SESSION,
  SET_ACTIVE_WORKSPACE,
  UPDATE_USER,
  SET_COST_BY_SERVICE,
  SET_COST_BY_REGION,
  SET_COST_DATA,
  SET_FORECAST_DATA,
  SET_LAMBDA_DASHBOARD_METRICS,
  TOGGLE_THEME,
  SET_DASHBOARD_TEMPLATES,
  SET_LAMBDA_LIST_METRICS,
} from './types';

export const init = () => ({
  user: null,
  workspace: null,
  dashboard: {
    cost: null,
    forecast: null,
    costByService: null,
    costByRegion: null,
    lambdaMetrics: null,
  },
  theme: 'dark',
});

const initialState = init();

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_SESSION:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        workspace: payload,
        dashboard: init().dashboard,
      };
    case SET_COST_DATA: {
      const accData = state.dashboard[payload.accountId] || {};
      accData.cost = payload.data;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [payload.accountId]: accData,
        },
      };
    }

    case SET_FORECAST_DATA: {
      const accData = state.dashboard[payload.accountId] || {};
      accData.forecast = payload.data;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [payload.accountId]: accData,
        },
      };
    }

    case SET_COST_BY_SERVICE: {
      const accData = state.dashboard[payload.accountId] || {};
      accData.costByService = payload.data;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [payload.accountId]: accData,
        },
      };
    }

    case SET_COST_BY_REGION: {
      const accData = state.dashboard[payload.accountId] || {};
      accData.costByRegion = payload.data;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [payload.accountId]: accData,
        },
      };
    }
    case SET_DASHBOARD_TEMPLATES:
      return {
        ...state,
        dashboard_templates: payload,
      };
    case TOGGLE_THEME:
      return {
        ...state,
        theme: payload,
      };
    default:
      return state;
  }
};

export default reducer;
