// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'small',
          },
          style: {
            fontSize: 10,
          },
        },
      ],
    },
  };
}
