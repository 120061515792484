import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'store';
import { GET_ORG } from 'graphql/organizations/queries';
import { ORG_STATUS, STATUS_REASON_LABELS } from 'constants/index';

const Root = styled(Box)`
  top: 0;
  margin: 0;
  position: sticky;
  z-index: 1;
  clear: both;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrgSuspendedBanner = () => {
  const { org } = useSelector((state) => state.workspace);
  const orgQuery = useQuery(GET_ORG, { variables: { id: org.id } });
  const isSuspended = orgQuery.data?.row?.status === ORG_STATUS.SUSPENDED;

  if (isSuspended) {
    return (
      <Root
        sx={{
          bgcolor: 'error.main',
          color: 'secondary.contrastText',
          display: 'inline-flex',
          p: 1,
        }}
      >
        <Typography fontWeight={600} color="inherit">
          Your data ingestion is on hold -{' '}
          {STATUS_REASON_LABELS[orgQuery.data.row.status_reason]}! To continue
          using KloudMate, please{' '}
          <MuiLink
            underline="always"
            component={Link}
            to={`/settings/${org.id}/subscription`}
          >
            unsuspend your account
          </MuiLink>
        </Typography>
      </Root>
    );
  }
  return null;
};

export default OrgSuspendedBanner;
