import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { useSelector } from '../../store';

export const NavItem = ({ to, exact, text }) => (
  <ListItem
    button
    component={NavLink}
    to={to}
    exact={exact}
    activeClassName="Mui-selected"
  >
    <ListItemText
      primary={text}
      primaryTypographyProps={{
        fontWeight: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
      }}
    />
  </ListItem>
);

const IncidentsSubMenu = () => {
  const { workspace } = useSelector((state) => state.workspace);
  return (
    <Box mb={1}>
      <NavItem exact to={`/${workspace.id}/im`} text="Overview" />
      <NavItem exact to={`/${workspace.id}/im/incidents`} text="Incidents" />
      <NavItem exact to={`/${workspace.id}/im/services`} text="Services" />
      <NavItem
        exact
        to={`/${workspace.id}/im/integrations`}
        text="Integrations"
      />
      <NavItem
        exact
        to={`/${workspace.id}/im/escalation-policies`}
        text="Escalation Policies"
      />
      <NavItem exact to={`/${workspace.id}/im/settings`} text="Settings" />
    </Box>
  );
};

export default IncidentsSubMenu;
