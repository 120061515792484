import { useQuery } from '@apollo/client';
import { ALARM_STATES } from 'constants/index';
import { QUERY_STATE_HISTORY } from 'graphql/alarms/queries';
import { GET_ISSUE_ANNOTATIONS } from 'graphql/issues/queries';
import sortBy from 'lodash/sortBy';
import partition from 'lodash/partition';
import { useSelector } from 'store';
import moment from 'moment';
import { useMemo } from 'react';

const SERVICE_NAME_KEYS = [
  'serviceName',
  'service.name',
  'FunctionName', // in order to show AWS datasource lambda alarms in Otel datasource panels
];

const useDefaultAnnotations = ({
  node_configs = [],
  frameLabels,
  from: _from,
  to: _to,
}) => {
  const { workspace } = useSelector((store) => store.workspace);

  const from = moment.utc(_from).toISOString();
  const to = moment.utc(_to).toISOString();

  const { stateHistoryQuery, issuesQuery } = useMemo(() => {
    const [otelNodes, awsNodes] = partition(
      node_configs.filter(({ type }) => type === 'query'),
      {
        datasourceId: 'kloudmate',
      },
    );

    const groupingReducer = (acc, curr) => {
      if (acc[curr.key]) {
        return {
          ...acc,
          [curr.key]: Array.from(new Set([...acc[curr.key], ...curr.values])),
        };
      }
      return { ...acc, [curr.key]: curr.values };
    };

    let otelServices = [];
    if (otelNodes.length) {
      const otelServicesFromFilters = Object.entries(
        otelNodes
          .flatMap((config) => {
            return (config.filters || [])
              .filter((filter) =>
                SERVICE_NAME_KEYS.some((key) => filter.field === key),
              )
              .map((filter) => {
                let values = filter.value;
                if (typeof filter.value === 'string') {
                  values = filter.value.split(',');
                } else if (!Array.isArray(values)) {
                  values = [];
                }

                return (values || []).filter(Boolean);
              })
              .flatMap((values) => {
                return SERVICE_NAME_KEYS.map((key) => ({ key, values }));
              });
          })
          .reduce(groupingReducer, {}),
      ).map(([key, values]) => ({ key, values }));

      otelServices = otelServicesFromFilters.length
        ? otelServicesFromFilters
        : frameLabels.flatMap((labels) => {
            return Object.keys(labels)
              .filter((label) => SERVICE_NAME_KEYS.includes(label))
              .map((label) => ({ key: label, values: [labels[label]] }));
          });
    }

    const awsServices = awsNodes.length
      ? frameLabels.flatMap((labels) =>
          Object.keys(labels).map((label) => ({
            key: label,
            values: [labels[label]],
          })),
        )
      : [];

    const getConditions = (services) =>
      services.flatMap(({ key, values }) => {
        if (!values) return [];
        return values
          .filter((value) => value !== undefined)
          .map((value) => {
            return {
              state_reason: { _ilike: `%${key}=${value}%` },
            };
          });
      });

    const services = [...otelServices, ...awsServices]
      .filter(({ values }) => !!values)
      .flatMap(({ values }) => values);

    let issuesQuery = {
      last_occurrence_at: { _gte: from, _lte: to },
      workspace_id: { _eq: workspace.id },
    };

    let stateHistoryQuery = {
      new_state: { _eq: ALARM_STATES.Alerting },
      created_at: { _gte: from, _lte: to },
      alarm: {
        workspace_id: { _eq: workspace.id },
      },
    };

    if (services.length) {
      issuesQuery = {
        ...issuesQuery,
        service_name: {
          _in: Array.from(new Set(services)),
        },
      };

      stateHistoryQuery = {
        ...stateHistoryQuery,
        _or: getConditions([...otelServices, ...awsServices]),
      };
    }

    if (awsNodes.length) {
      const awsAccounts = awsNodes.map((config) => config.datasourceId);
      issuesQuery = {
        ...issuesQuery,
        account: {
          id: {
            _in: awsAccounts,
          },
        },
      };
    }

    if (!services.length) {
      issuesQuery = null;
      stateHistoryQuery = null;
    }

    return { stateHistoryQuery, issuesQuery };
  }, [frameLabels, from, to]);

  const { data: alarmsData } = useQuery(QUERY_STATE_HISTORY, {
    variables: {
      query: stateHistoryQuery,
    },
    fetchPolicy: 'cache-first',
    skip: !stateHistoryQuery,
  });

  const { data: issuesData } = useQuery(GET_ISSUE_ANNOTATIONS, {
    variables: {
      query: issuesQuery,
    },
    fetchPolicy: 'cache-first',
    skip: !issuesQuery,
  });

  const alarmAnnotations = (alarmsData?.rows || []).map((row) => {
    return {
      axis: 'x',
      axisValue: moment
        .utc(row.created_at)
        .add(30, 'seconds')
        .startOf('minute')
        .valueOf(), // round to nearest minute
      metadata: [
        {
          ...row,
          id: row.alarm.id,
          name: row.alarm.name,
          type: 'alarm',
        },
      ],
    };
  });
  const issueAnnotations = (issuesData?.rows || []).map((row) => ({
    axis: 'x',
    axisValue: moment
      .utc(row.last_occurrence_at)
      .add(30, 'seconds')
      .startOf('minute')
      .valueOf(), // round to nearest minute
    metadata: [
      {
        ...row,
        type: 'issue',
      },
    ],
  }));

  const annotations = [...alarmAnnotations, ...issueAnnotations];

  // depends on RANGE of x-axis (to - from) and MIN_DIST_PERCENTAGE. If distance between two annotations is less than minDist =  MIN_DIST_PERCENTAGE of RANGE then they are grouped
  const groupedAnnotations = useMemo(() => {
    const groupedAnnotations = [];
    if (!annotations.length) {
      return groupedAnnotations;
    }
    const MIN_DIST_PERCENTAGE = 0.75;
    const RANGE = to - from;
    const minDist = (MIN_DIST_PERCENTAGE * RANGE) / 100;

    const sorted = sortBy(annotations, 'axisValue');
    let anchor = sorted[0];
    sorted.forEach((annotation, idx) => {
      if (idx === 0) return;
      const dist = Math.abs(anchor.axisValue - annotation.axisValue);
      if (dist <= minDist) {
        anchor.metadata = anchor.metadata.concat(annotation.metadata);
      } else {
        groupedAnnotations.push(anchor);
        anchor = annotation;
      }
    });

    if (!groupedAnnotations.find((ann) => ann.axisValue === anchor.axisValue)) {
      groupedAnnotations.push(anchor);
    }

    return groupedAnnotations;
  }, [to, from, annotations]);

  return groupedAnnotations;
};

export default useDefaultAnnotations;
