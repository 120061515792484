import { context, trace, Span, SpanStatusCode } from '@opentelemetry/api';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { env } from 'utils/env';

if (env.REACT_APP_COLLECTOR_URL) {
  const collector = new OTLPTraceExporter({
    url: `${env.REACT_APP_COLLECTOR_URL}/v1/traces`,
    headers: {
      Authorization: env.REACT_APP_WORKSPACE_KEY,
    },
  });

  const resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'kloudmate-app',
  });
  const provider = new WebTracerProvider({
    resource: resource,
  });

  provider.addSpanProcessor(new BatchSpanProcessor(collector));

  // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  const instrumentations = getWebAutoInstrumentations({
    '@opentelemetry/instrumentation-fetch': {
      ignoreUrls: [/api.segment/],
    },
  });

  registerInstrumentations({
    instrumentations,
    tracerProvider: provider,
  });
}

export const getCurrentSpan = (name) => {
  const span = trace.getSpan(context.active());
  if (!span) {
    return trace.getTracer('tracer').startSpan(name);
  }
  return span;
};