// ----------------------------------------------------------------------

import { listItemClasses } from '@mui/material/ListItem';

export default function List(theme) {
  const { palette } = theme;
  return {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          [`&.${listItemClasses.selected}`]: {
            backgroundColor: palette.action.selected,
          },
          [`&.${listItemClasses.selected}:hover`]: {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body1',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
  };
}
