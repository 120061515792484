import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Panel from './Panel';
import Legend from './Legend';
import Axes from './Axes';
import Tooltip from './Tooltip';
import StatConfig from './StatConfig';
import StatCalculation from './StatCalculation';
import Threshold from './Threshold';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import TablePanelColumns from './TablePanelColumns';
import TimeRangeOptions from './TimeRangeOptions';

export const ListTitle = ({ children }) => (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    variant="subtitle1"
    color="text.secondary"
  >
    {children}
  </Typography>
);

const PanelSettings = ({ setFieldValue, values }) => {
  const theme = useTheme();

  const graphOptions = [
    {
      id: 'panel',
      Component: Panel,
      titleNode: <ListTitle>Panel</ListTitle>,
    },
    {
      id: 'legend',
      Component: Legend,
      props: {
        setFieldValue,
      },
      titleNode: <ListTitle>Legend</ListTitle>,
    },
    {
      id: 'axis',
      Component: Axes,
      props: {
        setFieldValue,
        values,
      },
      titleNode: <ListTitle>Axes</ListTitle>,
    },
    {
      id: 'tooltip',
      Component: Tooltip,
      props: {
        setFieldValue,
        values,
      },
      titleNode: <ListTitle>Tooltip</ListTitle>,
    },
    {
      id: 'threshold',
      Component: Threshold,
      props: {
        setFieldValue,
        values,
        config: 'timeseries.threshold',
      },
      titleNode: <ListTitle>Threshold</ListTitle>,
    },
    {
      id: 'time_options',
      Component: TimeRangeOptions,
      titleNode: <ListTitle>Time Range</ListTitle>,
    },
  ];

  const statOptions = [
    {
      id: 'panel',
      Component: Panel,
      titleNode: <ListTitle>Panel</ListTitle>,
    },
    {
      id: 'statConfig',
      Component: StatConfig,
      props: {
        setFieldValue,
        values,
      },
      titleNode: <ListTitle>Stat Config</ListTitle>,
    },
    {
      id: 'statCalculation',
      Component: StatCalculation,
      titleNode: <ListTitle>Calculations</ListTitle>,
    },
    {
      id: 'statThreshold',
      Component: Threshold,
      props: {
        setFieldValue,
        values,
        config: 'statConfig',
      },
      titleNode: <ListTitle>Thresholds</ListTitle>,
    },
    {
      id: 'time_options',
      Component: TimeRangeOptions,
      titleNode: <ListTitle>Time Range</ListTitle>,
    },
  ];

  const tableOptions = [
    {
      id: 'panel',
      Component: Panel,
      titleNode: <ListTitle>Panel</ListTitle>,
    },
    {
      id: 'column',
      Component: TablePanelColumns,
      props: {
        setFieldValue,
        values,
      },
      titleNode: <ListTitle>Columns</ListTitle>,
    },
    {
      id: 'time_options',
      Component: TimeRangeOptions,
      titleNode: <ListTitle>Time Range</ListTitle>,
    },
  ];

  let items;
  switch (values.panelType) {
    case 'STATS':
      items = statOptions;
      break;
    case 'table':
      items = tableOptions;
      break;
    case 'TimeSeries':
    default:
      items = graphOptions;
      break;
  }

  const [expanded, setExpanded] = useState([items[1].id]);

  useEffect(() => {
    setExpanded([items[1].id]);
  }, [values.panelType]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? expanded.concat([panel])
        : expanded.filter((item) => item !== panel),
    );
  };

  return (
    <Box>
      {items.map(({ id, Component, props, titleNode }) => {
        return (
          <Accordion
            id={id}
            key={id}
            expanded={expanded.includes(id)}
            disableGutters
            onChange={handleChange(id)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              {titleNode}
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: theme.palette.background.light }}
            >
              <Component {...props} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default PanelSettings;
