import React from 'react';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Dropdown = ({ id, options, onSelect, children }) => {
  const menuState = usePopupState({ popupId: id, variant: 'popover' });

  const handleClick = (type, originalClick) => (e) => {
    e.stopPropagation();
    menuState.close();
    if (onSelect) {
      onSelect(type);
    }
    if (originalClick) {
      return originalClick(e, type);
    }
  };

  const trigger = bindTrigger(menuState);

  const onDropdownClick = (e) => {
    e.stopPropagation();
    trigger.onClick(e);
  };

  return (
    <>
      {React.cloneElement(children, {
        ...trigger,
        onClick: onDropdownClick,
      })}
      <Menu
        {...bindMenu(menuState)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {options.map(({ key, onClick, label, children, ...other }) => (
          <MenuItem
            dense
            key={key}
            onClick={handleClick(key, onClick)}
            {...other}
          >
            {label ?? children}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Dropdown;
