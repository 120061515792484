import { gql } from '@apollo/client';

// TODO this is only for cloudwatch now
export const GET_NAMESPACES = gql`
  query getNamespaces {
    rows: metric_namespaces {
      label
      value
    }
  }
`;

export const GET_NAMESPACE_METRICS = gql`
  query getNamespaceMetrics(
    $namespace: String!
    $accountId: String!
    $region: String!
  ) {
    rows: metric_namespace_metrics(
      namespace: $namespace
      accountId: $accountId
      region: $region
    ) {
      value
      label
    }
  }
`;

export const GET_METRIC_DIMENSION_KEYS = gql`
  query getMetricDimensionKeys(
    $accountId: String!
    $namespace: String!
    $region: String!
    $metricName: String!
    $dimensionFilters: JSON
  ) {
    rows: metric_namespace_dimension_keys(
      namespace: $namespace
      accountId: $accountId
      region: $region
      metricName: $metricName
      dimensionFilters: $dimensionFilters
    ) {
      label
      value
    }
  }
`;

export const GET_DIMENSION_VALUES = gql`
  query getDimensionValues(
    $accountId: String!
    $region: String!
    $namespace: String!
    $metricName: String!
    $dimensionKey: String!
    $dimensionFilters: JSON
  ) {
    rows: metric_dimension_values(
      accountId: $accountId
      region: $region
      namespace: $namespace
      metricName: $metricName
      dimensionKey: $dimensionKey
      dimensionFilters: $dimensionFilters
    ) {
      value
      label
    }
  }
`;
