import AwsRegions from 'constants/aws-regions';
const regionGroupsNames = {
  america: 'North America / South America',
  asia: 'Asia / Pacific',
  europe: 'Europe / Middle East / Africa',
};

const regionNames = AwsRegions.reduce((acc, curr) => {
  acc[curr.region] = curr.name;
  return acc;
}, {});

const markers = AwsRegions.map((i) => ({
  group: i.geo,
  name: regionGroupsNames[i.geo],
  region: i.region,
  niceName: i.name,
  coordinates: i.coordinates,
}));

export { markers, regionNames, regionGroupsNames };
