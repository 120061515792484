import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from 'components/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFormikContext } from 'formik';
import CloudWatchSource from './cloudwatch';
import KloudMateSource from './kloudmate';
import ExpressionCondition from './ExpressionCondition';
import { getNextAlphabet } from '../utils';
import { DATA_SOURCES } from 'constants/data-sources';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const QueryEditor = ({
  name,
  value,
  workspace,
  onRemove,
  onChange,
  result,
  nodeConfig,
  querySourceConfig,
  compact,
  displayResult = true,
  variableOptions,
  unresolvedValue,
  timeRange,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  const [expanded, setExpanded] = useState(true);
  const formik = useFormikContext();
  const nodes = formik.values[nodeConfig];
  const isHidden = value.hidden;

  const toggleVisibility = () => {
    const updatedValue = { ...value, hidden: !isHidden };
    const updatedNodeConfigs = nodes.map((node) => {
      if (node.nodeId === updatedValue.nodeId) {
        return updatedValue;
      }
      return node;
    });

    formik.setFieldValue(nodeConfig, [...updatedNodeConfigs]);
  };

  const onCopy = () => {
    const copied = cloneDeep(unresolvedValue || value);
    copied.nodeId = getNextAlphabet(nodes[nodes.length - 1]?.nodeId);
    formik.setFieldValue(nodeConfig, [...nodes, copied]);
  };

  return (
    <Paper variant="outlined" sx={{ mb: 2 }}>
      <Box
        borderBottom={expanded ? 1 : 0}
        borderColor="divider"
        px={2}
        py={0.5}
        display="flex"
        alignItems="center"
      >
        <IconButton
          edge="start"
          onClick={() => setExpanded(!expanded)}
          icon={expanded ? <DownIcon /> : <RightIcon />}
        />
        <Box ml={1} display="flex" alignItems="center" flex={1}>
          <Typography color="primary.main" variant="subtitle1" sx={{ mr: 2 }}>
            {value.nodeId}
          </Typography>
          {value.type === 'expression' ? (
            <Typography color="text.secondary">Expression</Typography>
          ) : (
            querySourceConfig
          )}
        </Box>
        <IconButton
          size="small"
          title="Copy node"
          icon={<ContentCopyIcon fontSize="inherit" />}
          onClick={onCopy}
        />
        <IconButton
          size="small"
          title="Hide node"
          onClick={toggleVisibility}
          icon={isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
        />

        <IconButton
          size="small"
          title="Delete node"
          icon={<DeleteIcon />}
          onClick={onRemove}
        />
      </Box>
      <Collapse in={expanded}>
        <Box px={2} py={1}>
          {value.type === 'expression' ? (
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <ExpressionCondition
                name={name}
                values={value}
                result={result}
                nodeConfig={nodeConfig}
                compact={compact}
              />
            </Grid>
          ) : (
            <>
              {value.datasourceId === DATA_SOURCES.KLOUDMATE ? (
                <KloudMateSource
                  workspace={workspace}
                  name={name}
                  values={value}
                  onChange={onChange}
                  result={result}
                  displayResult={displayResult}
                  compact={compact}
                  variableOptions={variableOptions}
                  timeRange={timeRange}
                  onChangeUnit={onChangeUnit}
                  resolvedVariablesValues={resolvedVariablesValues}
                />
              ) : (
                <CloudWatchSource
                  name={name}
                  values={value}
                  onChange={onChange}
                  result={result}
                  displayResult={displayResult}
                  compact={compact}
                  variableOptions={variableOptions}
                  timeRange={timeRange}
                  resolvedVariablesValues={resolvedVariablesValues}
                />
              )}
            </>
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default QueryEditor;
