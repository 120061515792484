import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormListener = ({ onChange }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
};

export default FormListener;
