import { createContext, useReducer, useContext, useMemo } from 'react';
import reducer, { init } from './reducer';

export const StoreContext = createContext();

export const useSelector = (selector) => {
    const { state } = useContext(StoreContext);
    const value = useMemo(() => selector(state), [state]);
    return value;
}

export const useDispatch = () => {
    const { dispatch } = useContext(StoreContext);
    return dispatch;
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, init(), init);

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default Provider;