import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, state } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (state?.noScroll || history.action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
