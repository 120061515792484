import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'store';
import { setDashboardTemplates } from 'store/actions';
import { hasuraClient } from 'api';

const useDashboardTemplates = () => {
  const { dashboard_templates } = useSelector((s) => s);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState();

  const fetchTemplates = async () => {
    if (!dashboard_templates) {
      const res = await hasuraClient.get(`/dashboard-templates`);
      setTemplates(res.dashboards);
      dispatch(setDashboardTemplates(res.dashboards));
    } else {
      setTemplates(dashboard_templates);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return templates;
};

export default useDashboardTemplates;
