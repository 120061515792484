export const getInitials = (name) => {
  if (!name) return '';
  const parts = name.trim().split(' ');
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
  }
  return name.substr(0, 2).toUpperCase();
};

export const stringToColor = (stringInput, theme = 'dark') => {
  if (!stringInput) return;
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, ${
    theme === 'dark' ? '80%' : '35%'
  })`;
};

export const getFirstName = (name) => {
  if (!name) return;
  const parts = name.split(' ');
  if (parts.length > 1) {
    return parts[0];
  }
  return name;
};

export const shortId = () =>
  (Math.random() * Math.random()).toString(36).substr(2);
