//
import { menuItem } from '../css';

// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenu: {
      defaultProps: {
        transitionDuration: 0,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...menuItem(theme),
        },
      },
    },
  };
}
