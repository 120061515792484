import React, { useState, useMemo } from 'react';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import List from './List';
import CreateEditVariable from './CreateEditVariable';

const LIST = 'list';
const CREATE_EDIT = 'createOrEdit';

const Variables = ({ dashboard, onSave }) => {
  const [view, setView] = useState(LIST);
  const [selected, setSelected] = useState();

  return (
    <>
      {view === LIST ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', my: 1 }}>
            <Button
              onClick={() => {
                setSelected(null);
                setView(CREATE_EDIT);
              }}
            >
              Add variable
            </Button>
          </Box>
          <List
            dashboard={dashboard}
            onSave={onSave}
            setView={(variable) => {
              setSelected(variable);
              setView(CREATE_EDIT);
            }}
          />
        </Box>
      ) : (
        <CreateEditVariable
          allVariables={(dashboard.data.variables || []).map(
            ({ name, config, is_multi }) => ({
              label: `$${name}`,
              value: `$${name}`,
              groupBy: 'Template Variables',
              queryType: config?.queryType,
              isMulti: is_multi,
            }),
          )}
          variable={selected || null}
          onSave={onSave}
          onClose={() => setView(LIST)}
          resolvedVariablesValues={dashboard.data.variables}
          defaultRegion={dashboard.data.default_region}
        />
      )}
    </>
  );
};

export default Variables;
