import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Label = ({ text, color, highlight }) => (
  <Stack direction="row" alignItems="center" spacing={0.75} mr={1.5}>
    <Box
      width={10}
      height={10}
      sx={{ backgroundColor: color, aspectRatio: '1/1' }}
    />
    <Typography
      color={highlight ? 'text.primary' : 'text.secondary'}
      variant="body2"
      fontWeight={highlight ? 500 : 400}
    >
      {text}
    </Typography>
  </Stack>
);

export default Label;
