import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import { shortId } from 'utils';

const Popover = function (props) {
  return (
    <Popper
      {...props}
      style={{ minWidth: 'fit-content', ...props.style }}
      placement="bottom-start"
    />
  );
};

export default function CustomSelect({
  className,
  placeholder = 'Please select',
  label,
  hint,
  options,
  value = null,
  onChange,
  hasError,
  helpText,
  isLoading,
  paginate,
  transformValue,
  isMulti = false,
  cacheOptions = true,
  isClearable,
  size,
  styles,
  sx,
  fullWidth = true,
  margin,
  isOptionSelected,
  freeSolo,
  getOptionLabel = (option) => option.label || option,
  isOptionEqualToValue = (option, value) => option.value === value.value,
  ...rest
}) {
  const formatOptions = useMemo(() => {
    const reformat = [];
    options.forEach((ele) => {
      if (ele.options !== undefined) {
        ele.options.forEach((ele2) => {
          reformat.push({ groupBy: ele.label, ...ele2 });
        });
      }
    });
    return reformat.length === 0 ? options : reformat;
  }, [options]);

  const handleChange = (value, newValue, reason, details) => {
    if (reason === 'createOption') {
      onChange(!newValue ? null : newValue, {
        action: 'create-option',
        option: details?.option,
        name: undefined,
        removedValue: null,
      });
      return;
    }
    if (transformValue) {
      onChange(
        !newValue
          ? null
          : isMulti
          ? newValue.map((v) => v.value || v)
          : newValue.value,
        {
          action: 'select-option',
          option: details?.option,
          name: undefined,
          removedValue: reason === 'removeOption' ? details.option : null,
        },
      );
    } else {
      onChange(!newValue ? null : newValue, {
        action: 'select-option',
        option: details?.option,
        name: undefined,
        removedValue: reason === 'removeOption' ? details.option : null,
      });
    }
  };

  const getValue = () => {
    if (isMulti && transformValue) {
      let opts = [];
      (value || []).forEach((v) => {
        const isMatch = (options || []).find(
          (o) => JSON.stringify(o.value) === JSON.stringify(v),
        );
        if (isMatch) {
          opts.push(isMatch);
        } else if (freeSolo) {
          opts.push(v);
        }
      });
      return opts;
    }
    if (transformValue && options) {
      let option = null;
      options.find((i) => {
        if (i.options) {
          const isMatch = i.options.find((i) => i.value === value);
          if (isMatch) {
            option = isMatch;
          } else if (freeSolo) {
            option = value;
            return true;
          }
          return isMatch;
        }
        let isMatch;
        if (typeof i.value === 'object') {
          isMatch = JSON.stringify(i.value) === JSON.stringify(value);
        } else {
          isMatch = i.value === value;
        }
        if (isMatch) {
          option = i;
        } else if (freeSolo) {
          option = value;
          return true;
        }
        return isMatch;
      });
      return option;
    }
    return value;
  };
  return (
    <FormControl
      variant="standard"
      margin={margin}
      className={className}
      sx={sx}
      fullWidth={fullWidth}
      error={hasError}
      size={size}
    >
      {label && <FormLabel sx={{ mb: 0.5 }}>{label}</FormLabel>}
      <Autocomplete
        selectOnFocus={false}
        loading={isLoading}
        multiple={isMulti}
        options={formatOptions}
        placeholder={placeholder}
        value={isMulti ? (getValue() !== null ? getValue() : []) : getValue()}
        groupBy={(option) => (!option.groupBy ? null : option.groupBy)}
        getOptionLabel={getOptionLabel}
        disableClearable={!isClearable}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue}
        disableCloseOnSelect={isMulti}
        size={size}
        renderInput={(params) => {
          const inputProps = params.inputProps;
          inputProps.autoComplete = 'off';
          return (
            <InputBase
              fullWidth={fullWidth}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              placeholder={placeholder}
              startAdornment={params.InputProps.startAdornment}
              endAdornment={params.InputProps.endAdornment}
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={shortId()}>
              {option.icon ? (
                <Stack
                  direction={!option.endIcon ? 'row' : 'row-reverse'}
                  spacing={1}
                  alignItems="center"
                >
                  {option.icon}
                  <span>{getOptionLabel(option)}</span>
                </Stack>
              ) : (
                option.label
              )}
            </li>
          );
        }}
        componentsProps={{
          paper: {
            elevation: 1,
          },
        }}
        freeSolo={freeSolo}
        PopperComponent={Popover}
        {...rest}
      />

      {hint && (
        <Typography variant="caption" color="textSecondary">
          {hint}
        </Typography>
      )}
      {hasError && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }),
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  transformValue: PropTypes.bool,
};
