import { gql } from '@apollo/client';
import { accountSyncFields } from './fragments';

export const GET_ACCOUNT_SYNC_SETTINGS = gql`
  query getAccountSyncSettings($accountId: String!) {
    account_sync_settings(
      where: { account_id: { _eq: $accountId } }
      order_by: { service_definition: { label: asc } }
    ) {
      ...AccountSyncSettings
      service_definition {
        label
      }
    }
    account: accounts_by_pk(id: $accountId) {
      id
      sync_allowed_tags
    }
  }
  ${accountSyncFields}
`;

export const GET_ACCOUNT_SYNC_SETTINGS_BY_SERVICE = gql`
  query getAccountSyncSettings($account_id: String!, $service_type: String!) {
    row: account_sync_settings_by_pk(
      account_id: $account_id
      service_type: $service_type
    ) {
      ...AccountSyncSettings
      sync_errors
      last_synced_at
    }
  }
  ${accountSyncFields}
`;

export const UPDATE_ACCOUNT_SYNC_SETTINGS = gql`
  mutation updateAccountSyncSettings(
    $account_id: String!
    $service_type: String!
    $payload: account_sync_settings_set_input
    $removeSyncErrorForRegion: account_sync_settings_delete_key_input
  ) {
    row: update_account_sync_settings_by_pk(
      pk_columns: { account_id: $account_id, service_type: $service_type }
      _set: $payload
      _delete_key: $removeSyncErrorForRegion
    ) {
      ...AccountSyncSettings
      sync_errors
    }
  }
  ${accountSyncFields}
`;

export const SUB_ACCOUNT_SYNC_SETTINGS_PROGRESS = gql`
  subscription subAccountSyncSettings($account_id: String!) {
    account_sync_settings_aggregate(
      where: {
        account_id: { _eq: $account_id }
        last_synced_at: { _is_null: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
