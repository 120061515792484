import React from 'react';
import { Helmet } from 'react-helmet';
import { useSubscription } from '@apollo/client';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useSelector } from 'store';
import { SUB_ACCOUNT_DETAILS } from 'graphql/accounts/queries';
import { ROLES } from 'constants/roles';
import ConnectAccount from 'modules/onboarding/components/ConnectAccount';
import PageContainer from 'components/PageContainer';
import LoaderWithLogo from 'components/LoaderWithLogo';

const AccountState = ({ children }) => {
  const { workspace, org } = useSelector((state) => state.workspace);
  const { loading, error, data } = useSubscription(SUB_ACCOUNT_DETAILS, {
    variables: {
      id: workspace.id,
    },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  // TODO: Remove this
  return children;

  if (loading) {
    return (
      <Box flex={1} height="100vh">
        <Helmet title="Loading..." />
        <LoaderWithLogo tip="Loading your account..." />
      </Box>
    );
  }

  if (error) {
    if (error.message === 'undefined') {
      return (
        <Alert severity="error">
          <Typography>
            Network Error. Please check your network connection and reload this
            page.
          </Typography>
        </Alert>
      );
    }
    return (
      <Alert severity="error">
        {error.message || 'Unknown error. Please reload the page'}
      </Alert>
    );
  }

  if (!data?.workspace) {
    return (
      <Alert severity="error">You do not have access to this account</Alert>
    );
  }

  return children;
};

export default AccountState;
