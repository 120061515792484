import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Formik, Form, Field } from 'formik';
import Button from 'components/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import ButtonDateTimePicker from 'components/Form/ButtonDateTimePicker';
import TextField from 'components/Form/Formik/FormikInput';
import { parse as parseRte } from 'rte-moment';
import { hasOnlyNegativeOffset } from './helper';

const DEFAULT_START_TIME = {
  hour: 0,
  minute: 0,
  seconds: 0,
};
const DEFAULT_END_TIME = {
  hour: 23,
  minute: 59,
  seconds: 59,
};

const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

const formatDate = (value) =>
  typeof value === 'object' ? value.format(DATE_FORMAT) : value;

function CustomTime({ value, onChange, onCancel, onlyRelativeTime }) {
  const currentDate = moment();
  const monthAgoDate = moment().subtract(30, 'd');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const allowAbsoluteTime = !onlyRelativeTime;

  useEffect(() => {
    let start, end;
    if (value && value.includes(':')) {
      const [from, to] = value.split(':').map((p) => parseInt(p));
      if (isFinite(from)) {
        start = moment(from);
      } else {
        start = value.split(':')[0];
      }
      if (isFinite(to)) {
        end = moment(to);
      } else {
        end = value.split(':')[1];
      }
    } else {
      start = moment(DEFAULT_START_TIME);
      end = moment(DEFAULT_END_TIME);
    }
    setStartDate(start);
    setEndDate(end);
  }, [value]);

  const onSubmit = (values) => {
    const { startDate, endDate } = values;
    let from, to;
    if (typeof startDate === 'string') {
      const date = moment(startDate, DATE_FORMAT, true);
      if (date.isValid()) {
        from = date.valueOf()
      } else {
        from = startDate;
      }
    } else {
      from = startDate.clone().valueOf();
    }

    if (typeof endDate === 'string') {
      const date = moment(endDate, DATE_FORMAT, true);
      if (date.isValid()) {
        to = date.valueOf()
      } else {
        to = endDate;
      }
    } else {
      to = endDate.clone().valueOf();
    }
    onChange(`${from}:${to}`);
  };

  const dateValidator = useCallback(
    (value) => {
      if (allowAbsoluteTime) {
        const isValid = moment(value, DATE_FORMAT, true).isValid();
        if (isValid) {
          return true;
        }
      }
      try {
        const parsed = parseRte(value)
        if (onlyRelativeTime) {
          return hasOnlyNegativeOffset(parsed)
        }
      } catch (err) {
        return false;
      }
      return true;
    },
    [onlyRelativeTime, allowAbsoluteTime],
  );

  return (
    <Formik
      initialValues={{
        startDate: startDate,
        endDate: endDate,
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.mixed().test(
          'startDate',
          `Please enter a valid ${onlyRelativeTime ? 'relative time' : 'start date'}`,
          dateValidator,
        ),
        endDate: Yup.mixed().test(
          'endDate',
          `Please enter a valid ${onlyRelativeTime ? 'relative time' : 'end date'}`,
          dateValidator,
        ),
      })}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => {
        return (
          <>
            <Form>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Typography>Start time</Typography>
                <Field
                  fullWidth
                  disableFuture
                  margin="dense"
                  minDate={monthAgoDate}
                  maxDate={currentDate}
                  name="startDate"
                  component={TextField}
                  value={formatDate(values.startDate)}
                  endAdornment={
                    allowAbsoluteTime && (
                      <ButtonDateTimePicker
                        onChange={(date) => setFieldValue('startDate', date)}
                      />
                    )
                  }
                />
                <Typography sx={{ mt: 1 }}>End time</Typography>
                <Field
                  fullWidth
                  disableFuture
                  margin="dense"
                  minDate={monthAgoDate}
                  maxDate={currentDate}
                  name="endDate"
                  component={TextField}
                  value={formatDate(values.endDate)}
                  endAdornment={
                    allowAbsoluteTime && (
                      <ButtonDateTimePicker
                        onChange={(date) => setFieldValue('endDate', date)}
                      />
                    )
                  }
                />
                <Stack
                  direction="row"
                  spacing={1}
                  mt="auto"
                  justifyContent="flex-end"
                >
                  <Button onClick={onCancel} variant="text">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Apply
                  </Button>
                </Stack>
              </LocalizationProvider>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default CustomTime;
