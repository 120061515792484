import React, { useMemo, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Field } from 'formik';
import TextField from 'components/Form/Formik/FormikInput';
import TextInput from 'components/Form/TextInput';

const ConstantVariable = ({
  variable,
  setPreview,
  isViewMode,
  viewModeOnChange,
}) => {
  const [variableValue, setVariableValue] = useState(variable.value);

  useEffect(() => {
    setVariableValue(variable.value);
  }, [variable.value]);

  useEffect(() => {
    setPreview && setPreview(variable.value ? [{ label: variable.value }] : []);
  }, [variable.value]);

  return !isViewMode ? (
    <Grid container columnSpacing={2} sx={{ mb: 1 }}>
      <Grid item md={6}>
        <Field
          transformValue
          margin="none"
          name={`value`}
          label="Default Value"
          placeholder="Default value, if any"
          component={TextField}
        />
      </Grid>
    </Grid>
  ) : (
    <TextInput
      margin="none"
      size="small"
      value={variableValue}
      placeholder="Enter value"
      onChange={(e) => {
        setVariableValue(e.target.value);
      }}
      onBlur={(e) => {
        setVariableValue(e.target.value);
        viewModeOnChange(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          setVariableValue(e.target.value);
          viewModeOnChange(e.target.value);
        }
      }}
      sx={{
        width: 190,
      }}
    />
  );
};

export default ConstantVariable;
