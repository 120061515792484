import { gql } from '@apollo/client';
import { inviteFields } from './fragments';

export const GET_INVITE_BY_ID = gql`
  query getInviteById($id: uuid!) {
    invites_by_pk(id: $id) {
      ...Invite
    }
  }
  ${inviteFields}
`;

export const GET_INVITES = gql`
  query getInvites {
    invites(where: { status: { _eq: "pending" } }) {
      ...Invite
    }
  }
  ${inviteFields}
`;

export const GET_OWN_INVITES = gql`
  query getOwnInvites($email: String!) {
    invites(where: { email: { _eq: $email } }) {
      ...Invite
    }
  }
  ${inviteFields}
`;

export const CREATE_INVITE = gql`
  mutation createInvite($data: invites_insert_input!) {
    insert_invites_one(object: $data) {
      ...Invite
    }
  }
  ${inviteFields}
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($id: uuid!) {
    delete_invites_by_pk(id: $id) {
      ...Invite
    }
  }
  ${inviteFields}
`;
