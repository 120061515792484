import moment from 'moment';
import prettyMs from 'pretty-ms';
import parseToMoment, { parse as parseRte } from 'rte-moment';

const units = {
  s: { unit: 's', title: 'Second' },
  m: { unit: 'm', title: 'Minute' },
  h: { unit: 'h', title: 'Hour' },
  d: { unit: 'd', title: 'Day' },
  w: { unit: 'w', title: 'Week' },
  M: { unit: 'M', title: 'Month' },
};

const TZ_KEY = 'timezone';

export const parseLabel = (value, { showReadableLabel }) => {
  if (value) {
    const parsed = parseInput(value);
    if (parsed) {
      return `Last ${parsed.value} ${units[parsed.unit].title}`;
    } else {
      const parts = value.split(':');
      if (showReadableLabel) {
        if (parts[1] === 'now') {
          try {
            const parsed = parseRte(parts[0]);
            if (hasOnlyNegativeOffset(parsed)) {
              const { number, unit } = parsed.body[0];
              return `Last ${number} ${units[unit].title}${
                number === 1 ? '' : 's'
              }`;
            }
          } catch (err) {}
        }
      }
      let str = '';
      if (parts[0]) {
        const parsedInt = parseInt(parts[0]);
        if (parsedInt) {
          const from = moment(parsedInt);
          str += `${from.format('MMM D h:mma')}`;
        } else {
          try {
            parseRte(parts[0]);
            str += parts[0];
          } catch (err) {
            str += '';
          }
        }
      }
      if (parts[1]) {
        const parsedInt = parseInt(parts[1]);
        if (parsedInt) {
          const to = moment(parsedInt);
          const endDate = to.format('MMM D');
          const endTime = to.format('h:mma');
          if (str.includes(endDate)) {
            str += ` - ${endTime}`;
          } else {
            str += ` - ${endDate} ${endTime}`;
          }
        } else {
          try {
            parseRte(parts[1]);
            str += ` - ${parts[1]}`;
          } catch (err) {
            str += ' - ';
          }
        }
      }
      return str;
    }
  }
  return 'Time Range';
};

const regex = /^(\d+)([smhdwM])$/;

export const parseOptions = (timeFormats) => {
  return Object.keys(timeFormats).reduce((arr, unit) => {
    for (let i = 0; i < timeFormats[unit].periods.length; i++) {
      const period = timeFormats[unit].periods[i];
      const title = timeFormats[unit].title;
      arr.push({
        period,
        unit: timeFormats[unit].unit,
        title: period === 1 ? title.slice(0, title.length - 1) : title,
      });
    }
    return arr;
  }, []);
};

export const parseInput = (query) => {
  if (typeof query === 'number') {
    return query;
  }
  if (query) {
    const match = query.match(regex);
    if (!match) return;
    const number = parseInt(match[1]);
    const unit = match[2];
    return {
      value: number,
      unit,
    };
  }
};

export const parseValue = (value, timeshift) => {
  if (value) {
    const now = moment().valueOf();
    const isRangeExpr = value.includes(':');
    let timeShift = '';
    try {
      const { body } = parseRte(timeshift);
      const offSet = body.find((b) => b.op === '-' && b.type === 'Offset');
      timeShift = offSet.op + offSet.number + offSet.unit;
    } catch (err) {}
    if (!isRangeExpr) {
      try {
        return {
          from: parseToMoment(`-${value}${timeShift}`).valueOf(),
          to: parseToMoment(`now${timeShift}`).valueOf(),
        };
      } catch (err) {
        return {
          from: now,
          to: now,
        };
      }
    } else {
      let start, end;
      const [from, to] = value.split(':').map((v) => parseInt(v));

      if (from) {
        start = from;
      } else {
        const relFrom = value.split(':')[0];
        try {
          start = parseToMoment(`${relFrom}${timeShift}`).valueOf();
        } catch (err) {
          start = now;
        }
      }

      if (to) {
        end = to;
      } else {
        const relTo = value.split(':')[1];
        try {
          end = parseToMoment(`${relTo}${timeShift}`).valueOf();
        } catch (err) {
          end = now;
        }
      }

      return {
        from: start,
        to: end,
      };
    }
  }
};

export const setTimeZone = (value) => {
  try {
    localStorage.setItem(TZ_KEY, value);
  } catch (e) {
    //
  }
};

export const getTimeZone = () => {
  try {
    return localStorage.getItem(TZ_KEY);
  } catch (e) {
    return 'local';
  }
};

export const secondstoRelativeTime = (value) => {
  if (!value) {
    return 'now';
  }
  const relTime = prettyMs(value * 1000, {
    secondsDecimalDigits: 0,
    compact: true,
  });
  return `now-${relTime}`;
};

export const relTimeToSeconds = (value) => {
  if (!value) {
    return 0;
  }
  try {
    const parsed = parseToMoment(value);
    return moment().diff(parsed, 'seconds');
  } catch (err) {
    return 0;
  }
};

export const hasOnlyNegativeOffset = (parsed) => {
  // allow string 'now'
  if (parsed.body.length === 0) {
    return true;
  }
  if (parsed.body.length !== 1) {
    return false;
  }
  const { type, op } = parsed.body[0];
  if (type !== 'Offset' || op !== '-') {
    return false;
  }
  return true;
};
