import * as React from 'react';
import MuiTextField from '../TextInput';
import { getIn } from 'formik';
import CustomCheckBox from '../Checkbox';

export function fieldToTextField({
  disabled,
  field,
  form: { isSubmitting, touched, errors },
  ...props
}) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    ...field,
    hasError: showError,
    helpText: showError ? fieldError : props.helperText,
    disabled: disabled ?? isSubmitting,
    variant: props.variant,
    fullWidth: true,
    ...props,
  };
}

export default function TextField({ children, ...props }) {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}

export function FormCustomCheckBox({ children, ...props }) {
  return (
    <CustomCheckBox {...fieldToTextField(props)}>{children}</CustomCheckBox>
  );
}
