import React from 'react';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import TextField from 'components/Form/Formik/FormikInput';
import { PromQLAggFuncs } from 'constants/aggregations';

const Range = ({ name }) => {
  const { values } = useFormikContext();

  const showRange = PromQLAggFuncs.includes(
    get(values, `${name}.aggregation.stat`),
  );

  return (
    showRange && (
      <>
        <Typography gutterBottom variant="body2">
          Range
        </Typography>
        <Field
          size="small"
          name={`${name}.aggregation.range`}
          placeholder={'1m'}
          margin={'none'}
          component={TextField}
        />
      </>
    )
  );
};

export default Range;
