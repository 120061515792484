import { gql } from '@apollo/client';

export const inviteFields = gql`
  fragment Invite on invites {
    id
    workspace_permissions
    organization_id
    email
    role
    status
    created_at
    updated_at
  }
`;
