import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  mapMain: {
    'display': 'flex',
    'flex': '1',
    '& path': {
      fill: '#C5CAE9',
      outline: 'none',
      cursor: 'pointer',
      animation: 'fadein 2s',
    },
    '& #us, #za, #hk, #in, #jp, #kr, #sg, #au, #ca, #de, #ie, #gb, #it, #fr, #se, #bh, #br':
      {
        fill: theme.palette.primary.light,
        transition: 'all .4s ease-in',
        pointerEvents: 'all',
      },
  },
  circleStyles: {
    'cursor': 'pointer',
    '&:hover': {
      fill: theme.palette.secondary.main,
      strokeWidth: 8,
    },
  },
}));
