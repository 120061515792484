import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Select from 'components/Form/Select';
import Dropdown from 'components/Form/Dropdown';
import { AggregateFunctions, PromQLAggFuncs } from 'constants/aggregations';
import Typography from '@mui/material/Typography';
import { DATA_SET } from 'constants/data-sources';
import KeysField from './KeysField';
import ValueField from './ValuesField';
import { getUnitFormatting } from 'modules/dashboards/components/PanelSettings/Formatter';

export const defaultAggregate = {
  stat: AggregateFunctions[0],
  field: '',
  type: '',
};

const Aggregation = ({
  name,
  queryOptions,
  updateQueryOptions,
  from,
  to,
  variableOptions,
  onChangeUnit,
}) => {
  const isMetricDS = queryOptions.dataset === DATA_SET.METRICS;
  const aggFuncs = (
    isMetricDS ? AggregateFunctions.concat(PromQLAggFuncs) : AggregateFunctions
  ).map((i) => ({
    label: i,
    value: i,
  }));

  return (
    <Box px={1}>
      <Typography gutterBottom variant="body2">
        Select metric to aggregate
      </Typography>
      <Grid container spacing={1}>
        <Grid item>
          <Dropdown
            transformValue
            size="small"
            placeholder="Calculate"
            margin="none"
            component={Select}
            options={aggFuncs}
            value={queryOptions.aggregation?.stat}
            onChange={(val) => {
              updateQueryOptions(`${name}.aggregation.stat`, val);
            }}
          />
        </Grid>
        <Grid item flex={1}>
          {isMetricDS ? (
            <ValueField
              dataset={DATA_SET.METRICS}
              field="metric"
              placeholder="Select metric"
              type={queryOptions.aggregation?.type}
              name={`${name}.aggregation`}
              value={queryOptions.aggregation?.metric}
              isOptionEqualToValue={(option, value) =>
                option.value === value?.value
              }
              onChange={(aggName, val) => {
                updateQueryOptions(aggName, {
                  ...queryOptions.aggregation,
                  metric: val.value,
                  metricType: val.metricType,
                  temporality: val.temporality,
                  unit: val.unit,
                });

                onChangeUnit({
                  format: getUnitFormatting(val.unit),
                  nodeId: queryOptions.nodeId,
                });
              }}
              from={from}
              to={to}
              variableOptions={variableOptions}
            />
          ) : (
            <KeysField
              placeholder="Select field"
              name={`${name}.aggregation`}
              dataset={queryOptions.dataset}
              value={queryOptions.aggregation}
              onChange={(name, val) =>
                updateQueryOptions(name, {
                  ...queryOptions.aggregation,
                  field: val.field,
                  type: val.type,
                })
              }
              from={from}
              to={to}
              variableOptions={variableOptions}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Aggregation;
