export const calcVertAxisWidth = (options) => (_, values) => {
  const defaultWidth = options?.defaultWidth || 48;
  const offset = options?.offset || 2.5;
  const multiplier = options?.multiplier || 8;

  let max = 0;
  (values || []).forEach((val) => {
    const len = String(val).length;
    if (len > max) {
      max = len;
    }
  });
  return (max + offset) * multiplier || defaultWidth;
};
