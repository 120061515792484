import Annotation from 'components/UPlot/Annotation';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { TriangleUpIcon } from 'constants/Icons';
import PreviewAnnotations from './PreviewAnnotations';
import groupBy from 'lodash/groupBy';
import { useTheme } from '@mui/styles';

const DefaultAnnotation = ({ annotation, addHooks, tz }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const groups = groupBy(annotation.metadata, 'type');

  return (
    <Annotation
      ref={ref}
      addHooks={addHooks}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
      axis={annotation.axis}
      axisValue={annotation.axisValue}
      width={11}
      height={6}
    >
      <TriangleUpIcon width={16} color={'cyan'} />
      <Popper
        id={annotation.axisValue}
        sx={{
          pointerEvents: 'none',
          cursor: 'default',
          zIndex: theme.zIndex.tooltip,
        }}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 20,
        }}
        open={showPopup}
      >
        <PreviewAnnotations
          issues={groups.issue}
          alarms={groups.alarm}
          tz={tz}
          timestamp={annotation.axisValue}
        />
      </Popper>
    </Annotation>
  );
};

export default DefaultAnnotation;
