import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const TOOLTIP_MODES = {
  single: {
    value: 'single',
    name: 'Single',
  },
  all: {
    value: 'all',
    name: 'All',
  },
  hidden: {
    value: 'hidden',
    name: 'Hidden',
  },
};

const Tooltip = ({ setFieldValue, values }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1" color="text.secondary">
        Tooltip Mode
      </Typography>
      <ToggleButtonGroup
        exclusive
        variant="outlined"
        size="small"
        value={values.tooltip?.mode || TOOLTIP_MODES.single.value}
        onChange={(e, v) => {
          setFieldValue(`tooltip.mode`, v);
        }}
      >
        {Object.values(TOOLTIP_MODES).map(({ name, value }, index) => {
          return (
            <ToggleButton disableRipple key={index} value={value}>
              {name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default Tooltip;
