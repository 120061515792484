import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import RelativeTime from './RelativeTime';
import CustomTime from './CustomTime';
import Timezones from './Timezones';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LanguageIcon from '@mui/icons-material/Language';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Popover from '@mui/material/Popover';
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { pickerStyles } from './styles';

import { parseLabel, getTimeZone, setTimeZone } from './helper';
import { analyticsTrack } from 'utils/segment';

const DateRangePicker = ({
  value,
  onChange,
  showReadableLabel,
  onlyRelativeTime,
  hideTzToggle,
  hideCustomTimeToggle,
  hideIcon,
  sx: buttonSx,
  ...buttonProps
}) => {
  const buttonRef = useRef();
  const [showTz, setShowTz] = useState(false);
  const [showAbsolutePicker, setShowAbsolutePicker] = useState(false);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'date-range-picker',
  });

  useEffect(() => {
    if (value?.value && value.value.includes(':')) {
      setShowAbsolutePicker(true);
    } else {
      setShowAbsolutePicker(false);
    }
  }, [popupState, value?.value]);

  const label = useMemo(() => {
    return parseLabel(value?.value, { showReadableLabel });
  }, [value, showReadableLabel]);

  const classes = pickerStyles();

  const handleChange = (timeValue) => {
    popupState.close();
    if (!timeValue.includes(':')) {
      analyticsTrack('Interval Changed', {
        interval: timeValue,
      });
    }
    onChange({ ...value, value: timeValue });
  };

  const onTzSelect = (zone) => {
    setTimeZone(zone);
    onChange({ ...value, tz: zone });
    if (value?.value && value?.value.includes(':')) {
      setShowTz(false);
      setShowAbsolutePicker(true);
    }
  };

  const onShowTz = () => {
    setShowTz(true);
    setShowAbsolutePicker(false);
  };

  const onShowAbsolutePicker = () => {
    setShowTz(false);
    setShowAbsolutePicker(true);
  };

  return (
    <>
      <Button
        variant="contained"
        ref={buttonRef}
        sx={{ whiteSpace: 'nowrap', px: 1, ...buttonSx }}
        startIcon={!hideIcon && <AccessTimeIcon fontSize="small" />}
        {...bindTrigger(popupState)}
        {...buttonProps}
      >
        {label}
      </Button>

      <Popover
        {...bindPopover(popupState)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          popupState.close();
          setShowAbsolutePicker(false);
        }}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.left}>
            <RelativeTime onChange={handleChange} />
            <Divider />
            <List dense>
              {!hideCustomTimeToggle && (
                <ListItemButton onClick={onShowAbsolutePicker}>
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Start & End time`} />
                  <ArrowForwardIosIcon fontSize="2rem" />
                </ListItemButton>
              )}
              {!hideTzToggle && (
                <ListItemButton onClick={onShowTz}>
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Time zone`} />
                  <ListItemText
                    primaryTypographyProps={{
                      color: 'text.secondary',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      textAlign: 'right',
                      pr: 1,
                    }}
                  >
                    {value.tz}
                  </ListItemText>
                  <ArrowForwardIosIcon fontSize="2rem" />
                </ListItemButton>
              )}
            </List>
          </Box>
          {showAbsolutePicker && (
            <Box className={classes.right}>
              <CustomTime
                value={value.value}
                onChange={handleChange}
                onCancel={() => popupState.close()}
                onlyRelativeTime={onlyRelativeTime}
              />
            </Box>
          )}
          {showTz && (
            <Box className={classes.right}>
              <Timezones value={value.tz} onChange={onTzSelect} />
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

DateRangePicker.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

DateRangePicker.defaultProps = {
  defaultValue: {},
  onChange: () => {},
  title: '',
};

export { parseValue, parseInput } from './helper';
export default DateRangePicker;
