import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import { useDispatch, useSelector } from 'store';
import { useApolloClient, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import StepLabel from '@mui/material/StepLabel';
import CreateWorkspace from './components/CreateWorkspace';
import AddDataSource from './components/AddDataSource';
import { setActiveWorkspace, storeSession } from 'store/actions';
import CreateOrganization from './components/CreateOrganization';
import { GET_LOGGED_IN_USER_DETAILS } from 'graphql/users/queries';
import {
  UPDATE_WORKSPACE,
  GET_WORKSPACE,
  ADD_WORKSPACE,
} from 'graphql/workspace/queries';
import { useAuthWrapperStyles } from 'modules/auth/AuthWrapper';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  DEFAULT_PLAN,
  PRODUCT_KEY,
  USE_TIMER_KEY,
  USE_TIMER_VALUE,
} from 'store/types';
import GenericHeader from 'components/Header/GenericHeader';
import { analyticsGroup, analyticsTrack } from 'utils/segment';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { createOrg } from 'api/org';

const Onboarding = ({ history }) => {
  const authClasses = useAuthWrapperStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const client = useApolloClient();
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const { user } = store;
  const [step, setStep] = useState(0);
  const [org, setOrg] = useState();
  const [workspace, setWorkspace] = useState();
  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);
  const [createWorkspace] = useMutation(ADD_WORKSPACE);

  useEffect(() => {
    const org = store.user.acl[0].organization;
    if (org) {
      setOrg(org);
      setStep(1);
      if (user.acl[0].workspace_id) {
        client
          .query({
            query: GET_WORKSPACE,
            variables: {
              workspaceId: user.acl[0].workspace_id,
            },
          })
          .then(({ data }) => {
            setWorkspace(data.row);
            setStep(2);
          });
      }
    }
  }, [store.user]);

  const onCreateOrganization = async (name) => {
    try {
      const resp = await createOrg(
        name,
        localStorage.getItem(USE_TIMER_KEY) === USE_TIMER_VALUE,
      );

      localStorage.removeItem(USE_TIMER_KEY);

      setOrg(resp);
      setWorkspace(resp.workspaces[0]);
      handleNext();
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onUpdateWorkspace = async (name) => {
    try {
      if (!workspace) {
        const { data } = await createWorkspace({
          variables: {
            name,
            orgId: org.id,
          },
        });
        setWorkspace(data.row);
      } else {
        const { data } = await updateWorkspace({
          variables: {
            workspaceId: workspace.id,
            values: {
              name,
            },
          },
        });
        setWorkspace(data.row);
      }
      handleNext();
    } catch (e) {
      toast.error(e.message);
    }
  };
  const onFinish = async () => {
    try {
      const { data } = await client.query({
        query: GET_LOGGED_IN_USER_DETAILS,
        variables: {
          id: user.id,
        },
        fetchPolicy: 'network-only',
      });
      const { users } = data;
      dispatch(storeSession(users[0]));

      const acl = users[0].acl.find((o) => o.org_id === org.id);
      dispatch(
        setActiveWorkspace(
          {
            id: org.id,
            name: org.name,
            role: 'owner',
            created_at: acl.organization.created_at,
            plan: acl.organization.product_key,
            plan_tier: acl.organization.plan?.plan_tier,
          },
          {
            id: workspace.id,
            name: workspace.name,
            role: 'owner',
          },
        ),
      );

      analyticsTrack('Workspace Created', {
        userId: user.id,
        account_name: workspace.name,
        plan: localStorage.getItem(PRODUCT_KEY) || DEFAULT_PLAN,
        context: {
          groupId: org.id,
        },
      });
      analyticsGroup(org.id, {
        companyId: org.id,
        name: org.name,
        createdAt: acl.organization.created_at,
        plan: acl.organization.product_key,
      });
      history.push('/');
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    {
      label: 'Organization',
      Component: CreateOrganization,
      props: { onCreateOrganization },
    },
    {
      label: 'Workspace',
      Component: CreateWorkspace,
      props: { onUpdateWorkspace, workspace },
    },
    {
      label: 'Send data to KloudMate',
      Component: AddDataSource,
      props: { workspace, onFinish },
    },
  ];

  return (
    <Box className={authClasses.root}>
      <Helmet title="Onboarding" />
      <GenericHeader />
      {isLargeScreen ? (
        <Box
          className={authClasses.leftContainer}
          sx={{ mt: 3, mb: 3, display: { xs: 'none', md: 'block' } }}
        >
          <Grid container mt={10}>
            <Grid item xs={12} md={4} lg={3}>
              <Stepper
                activeStep={step}
                orientation="vertical"
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        (index === 0 && org?.name) ||
                        (index === 1 && workspace?.name)
                      }
                      sx={{
                        '& .MuiStepLabel-label': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              {React.createElement(steps[step].Component, {
                onNext: handleNext,
                isLast: steps[step].isLast,
                ...steps[step].props,
              })}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
          }}
        >
          <Paper square sx={{ py: 2, px: 4 }}>
            <Typography>{steps[step].label}</Typography>
          </Paper>
          <Box sx={{ flexGrow: 1 }}>
            {React.createElement(steps[step].Component, {
              onNext: handleNext,
              isLast: steps[step].isLast,
              ...steps[step].props,
              mobileStepperProps: {
                variant: 'progress',
                steps: steps.length,
                position: 'static',
                activeStep: step,
                sx: {
                  backgroundColor: 'background.paper',
                  p: 2,
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                },
              },
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Onboarding;
