import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import cookie from 'utils/cookie';
import { useSelector } from 'store';
import useSubscription from 'modules/settings/containers/subscription/hooks/useSubscription';
import capitalize from 'lodash/capitalize';

const Root = styled(Box)`
  top: 0;
  margin: 0;
  position: relative;
  clear: both;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TrialBanner = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { org } = useSelector((state) => state.workspace);
  const { isTrial, baseSubscriptionItem } = useSubscription();
  const isOverLimit = false;

  useEffect(() => {
    const isHidden = cookie.get('trial_banner_hidden');
    if (isHidden) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, []);

  const onStart = () => {
    history.push(`/settings/${org.id}/subscription/plans`);
  };

  const onClose = () => {
    cookie.set('trial_banner_hidden', '1', {
      maxAge: 60 * 60 * 24 * 10,
      path: '/',
    });
    setVisible(false);
  };

  if (isTrial && visible) {
    return (
      <Root
        sx={{
          bgcolor: 'secondary.darker',
          color: 'secondary.contrastText',
          display: 'inline-flex',
          p: 1,
        }}
      >
        {isOverLimit ? (
          <Typography color="inherit" fontWeight={600}>
            You have exceeded your trial limit. Please select a plan to
            continue.
          </Typography>
        ) : (
          <Typography fontWeight={600} color="inherit">
            You have{' '}
            {moment.utc(baseSubscriptionItem.trial_end * 1000).fromNow(true)}{' '}
            remaining on your KloudMate {capitalize(org.plan_tier)} free trial.
          </Typography>
        )}
        <Button
          sx={{
            ml: 1,
          }}
          onClick={onStart}
        >
          Select a plan
        </Button>
        <IconButton
          sx={{ position: 'absolute', right: 10, top: 10 }}
          icon={<CloseIcon sx={{ color: 'inherit' }} />}
          onClick={onClose}
        />
      </Root>
    );
  }
  return null;
};

export default TrialBanner;
