import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from 'components/Button';
import Input from 'components/Form/Formik/FormikInput';
import useLoginFlow from 'hooks/auth/useLoginFlow';
import { useHistory } from 'react-router-dom';

const MFASecretLogin = () => {
  const history = useHistory();
  const { onSubmit, error } = useLoginFlow({ useMFA: true });

  const handleSubmit = async (values) => {
    const { error } = await onSubmit({
      type: 'lookup_secret',
      lookup_secret: values.mfaSecret,
    });

    if (!error) {
      history.replace('/');
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          mfaSecret: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            {error && <Alert severity="error">{error.text}</Alert>}
            <Field
              fullWidth
              name="mfaSecret"
              label="MFA Recovery Secret"
              helperText="You must have MFA recovery secret received when you enabled MFA. Contact us if you have lost your secret code."
              component={Input}
            />
            <Box mt={3}>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                size="large"
                type="submit"
                fullWidth
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MFASecretLogin;
