import { gql } from '@apollo/client';
import { accountFields } from './fragments';

export const GET_USER_ACCOUNTS = gql`
  query getUserAccounts {
    accounts {
      ...Account
    }
  }
  ${accountFields}
`;

export const GET_ORG_ACCOUNTS = gql`
  query getOrgAccounts($orgId: String!) {
    accounts(where: { organization_id: { _eq: $orgId } }) {
      ...Account
    }
  }
  ${accountFields}
`;

export const GET_ACCOUNTS_BY_WORKSPACE_ID = gql`
  query getAccountsByWorkspaceId($workspaceId: String!) {
    accounts(where: { workspace_id: { _eq: $workspaceId } }) {
      ...Account
    }
  }
  ${accountFields}
`;

export const GET_ACCOUNT_BY_ID = gql`
  query getAccountById($id: String!) {
    account: accounts_by_pk(id: $id) {
      ...Account
    }
  }
  ${accountFields}
`;

export const DELETE_ACCOUNT_BY_ID = gql`
  mutation deleteAccount($id: String!) {
    account: delete_accounts_by_pk(id: $id) {
      id
    }
  }
`;

export const SUB_ACCOUNT_DETAILS = gql`
  subscription subAccountDetails($id: String!) {
    account: accounts_by_pk(id: $id) {
      ...Account
    }
  }
  ${accountFields}
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: String!, $payload: accounts_set_input) {
    account: update_accounts_by_pk(pk_columns: { id: $id }, _set: $payload) {
      ...Account
    }
  }
  ${accountFields}
`;
