import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useStyles } from './styles';
import { menuItems } from './MenuItems';

const StyledMenuItem = styled(ListItem)(({ theme, ...rest }) => {
  return {
    'flexDirection': 'column',
    'padding': 0,
    'backgroundColor': rest.isActiveMainMenu
      ? theme.palette.action.selected
      : '',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  };
});

const MenuItem = ({
  to,
  Icon,
  text,
  children,
  activeMenu,
  setActiveMenu,
  basePath,
  setOpenMenu,
  collapsed,
  isActive,
  ...rest
}) => {
  const location = useLocation();
  const isOpen = activeMenu === text;
  const isActiveMainMenu = basePath && location.pathname.includes(basePath);
  const popupState = usePopupState({
    variant: 'menu',
    popupId: 'submenu',
  });

  useEffect(() => {
    popupState.close();
  }, [location]);

  useEffect(() => {
    setActiveMenu(popupState.isOpen ? text : null);
  }, [popupState.isOpen]);

  const toggle = () => {
    if (isOpen) {
      setActiveMenu();
    } else {
      setActiveMenu(text);
    }
  };

  const childMenu = children ? (
    !collapsed ? (
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{ ml: 3, color: 'white' }}
      >
        {children}
      </Collapse>
    ) : (
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          color: 'text.primary',
        }}
      >
        {children}
      </Menu>
    )
  ) : null;

  const itemProps = childMenu && collapsed ? bindTrigger(popupState) : {};

  return (
    <>
      <StyledMenuItem
        button
        isActiveMainMenu={isActiveMainMenu}
        component={to && NavLink}
        to={to}
        onClick={children && toggle}
        isActive={isActive}
        activeClassName="Mui-selected"
        {...itemProps}
        {...rest}
      >
        <Icon width={20} height={20} />
        {!collapsed && (
          <ListItemText
            primaryTypographyProps={{
              fontWeight: 'inherit',
              fontSize: 'inherit',
            }}
            sx={{
              mb: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            primary={text}
          />
        )}
        {children && !collapsed && (
          <>{isOpen ? <ExpandLess /> : <ExpandMore />}</>
        )}
      </StyledMenuItem>
      {childMenu}
    </>
  );
};

function Sidebar({ show, setOpen }) {
  const classes = useStyles();
  const [activeMenu, setActiveMenu] = useState();

  const logoPath = `/assets/images/logo_full_white.svg`;

  return (
    <>
      <Drawer
        className={cx(classes.drawerStyles, {
          [classes.drawerStylesShow]: show,
          [classes.drawerStylesHide]: !show,
        })}
        variant="permanent"
        anchor="left"
      >
        <Box
          className={cx(classes.logoContainer, {
            [classes.logoContainerShow]: show,
            [classes.logoContainerHide]: !show,
          })}
        >
          <img alt="KloudMate" src={logoPath} />
        </Box>
        <Divider sx={{ borderColor: 'grey.700' }} />
        <Box p={1} pb={1} flex={1}>
          <List disablePadding>
            {menuItems.map((item, index) => {
              const Icon = item.Icon;
              const menuItem = (
                <MenuItem
                  key={index}
                  className={classes.menuListItem}
                  to={item.to}
                  Icon={Icon}
                  text={item.text}
                  isActive={(match, location) => {
                    if (item.to === '/') {
                      return location.pathname === '/';
                    }
                    return `/${location.pathname.split('/')?.[2]}` === item.to;
                  }}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  collapsed={!show}
                  children={item.children}
                  basePath={item.basePath}
                />
              );

              if (!show) {
                return (
                  <Tooltip
                    title={activeMenu === item.text ? null : item.text}
                    arrow
                    placement="right"
                  >
                    <span>{menuItem}</span>
                  </Tooltip>
                );
              }
              return menuItem;
            })}
            <StyledMenuItem
              button
              dense
              className={classes.menuListItem}
              key="settings"
              component={NavLink}
              to="/settings"
              activeClassName="Mui-selected"
            >
              <SettingsIcon width={20} height={20} />
              {show && (
                <ListItemText
                  primary={'Settings'}
                  primaryTypographyProps={{ fontWeight: 500, fontSize: 14 }}
                />
              )}
            </StyledMenuItem>
          </List>
        </Box>
        <StyledMenuItem
          button
          dense
          className={classes.menuListItem}
          onClick={() => {
            setOpen(!show);
          }}
        >
          {show ? (
            <KeyboardDoubleArrowLeftIcon
              width={20}
              height={20}
              style={{ marginLeft: 0 }}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              width={20}
              height={20}
              style={{ marginLeft: 0 }}
            />
          )}
        </StyledMenuItem>
      </Drawer>
    </>
  );
}

export default Sidebar;
