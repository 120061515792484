import {
  HomeIcon,
  InventoryIcon,
  LambdaIcon,
  SearchIcon,
  AlarmIcon,
  MonitorIcon,
  TraceIcon,
  IncidentIcon,
  RumIcon,
} from 'constants/Icons';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LanIcon from '@mui/icons-material/LanOutlined';
import IssueIcon from '@mui/icons-material/BugReportOutlined';
import IncidentsSubMenu from './IncidentsSubMenu';

export const menuItems = [
  {
    text: 'Home',
    to: '/',
    Icon: HomeIcon,
  },
  {
    text: `Dashboards`,
    to: '/dashboards',
    Icon: MonitorIcon,
  },
  // {
  //   text: 'Explore',
  //   to: '/visualization',
  //   Icon: QueryStatsIcon,
  // },
  {
    text: `Explore`,
    to: '/explore',
    Icon: QueryStatsIcon,
  },
  // {
  //   text: 'Inventory',
  //   to: '/inventory',
  //   Icon: InventoryIcon,
  // },
  {
    text: 'Lambda',
    to: '/lambda',
    Icon: LambdaIcon,
  },
  {
    text: 'Logs',
    to: '/logs',
    Icon: SearchIcon,
  },
  {
    text: 'Traces',
    to: '/traces',
    Icon: TraceIcon,
  },
  {
    text: 'Issues',
    to: '/issues',
    Icon: IssueIcon,
  },
  {
    Icon: AlarmIcon,
    text: 'Alarms',
    to: '/alarms',
  },
  {
    Icon: IncidentIcon,
    text: 'Incident Management',
    children: <IncidentsSubMenu />,
    basePath: '/im',
  },
  {
    Icon: LanIcon,
    text: 'Service Map',
    to: '/service-map',
  },
  // {
  //   text: `Insights`,
  //   to: '/smart-insights',
  //   Icon: InsightIcon,
  // },
  {
    Icon: RumIcon,
    text: 'Real User Monitoring',
    to: '/rum',
  },
];
