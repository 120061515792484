import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  rightItem: {
    'display': 'flex',
    'justifyContent': 'flex-end',
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
    },
  },
  menu: {
    '&  li': {
      width: 165,
    },
  },
  spinCenter: {
    flex: 1,
    justifyContent: 'center',
  },
  runningServiceGreen: {
    color: theme.palette.success.contrastText,
    background: theme.palette.success.light,
    fontWeight: 600,
    padding: 6,
    borderRadius: 3,
    marginRight: 8,
    width: 34,
    lineHeight: '1.6',
    textAlign: 'center',
    display: 'inline-block',
  },
  stoppedServiceRed: {
    color: theme.palette.error.contrastText,
    background: theme.palette.error.light,
    fontWeight: 600,
    padding: 6,
    borderRadius: 3,
    marginRight: 8,
    width: 34,
    lineHeight: '1.6',
    textAlign: 'center',
    display: 'inline-block',
  },
}));
