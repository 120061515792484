import { Field, FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import Select from 'components/Form/Formik/FormikReactSelect';
import Button from 'components/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import AxisPlacement from './AxisPlacement';
import Threshold from '../Threshold';
import Formatter from '../Formatter';
import pick from 'lodash/pick';
import { legendValues } from '../Legend';

export const PROPERTY_OPTIONS = {
  axisPlacement: {
    value: 'axisPlacement',
    name: 'Axis Placement',
  },
  thresholds: {
    value: 'thresholds',
    name: 'Thresholds',
  },
  units: {
    value: 'units',
    name: 'Units',
  },
  calculation: {
    value: 'calculation',
    name: 'Calculation',
  },
};

const Properties = ({ prefix, properties = [], setFieldValue, values }) => {
  const theme = useTheme();

  const propertyOptions =
    values.panelType === 'STATS'
      ? pick(PROPERTY_OPTIONS, ['thresholds', 'units', 'calculation'])
      : pick(PROPERTY_OPTIONS, ['axisPlacement']);

  return (
    <FieldArray name={`${prefix}.properties`}>
      {({ push, remove }) => {
        return (
          <Stack spacing={2}>
            <Stack spacing={2}>
              {properties.map((property, idx) => {
                return (
                  <>
                    <Stack
                      width={'100%'}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <>
                        {!property.type && (
                          <Field
                            fullWidth
                            margin="none"
                            name={`${prefix}.properties.${idx}.type`}
                            label={`Property ${idx + 1}`}
                            component={Select}
                            transformValue
                            options={Object.values(propertyOptions).map(
                              ({ name, value }) => ({ label: name, value }),
                            )}
                          />
                        )}
                        {property.type && (
                          <Stack spacing={1} width="100%">
                            {property.type && (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                >
                                  {`Property ${idx + 1} - ${
                                    PROPERTY_OPTIONS[property.type].name
                                  }`}
                                </Typography>
                                <IconButton
                                  size="small"
                                  onClick={() => remove(idx)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Stack>
                            )}
                            {property.type ===
                              PROPERTY_OPTIONS.axisPlacement.value && (
                              <AxisPlacement
                                property={property}
                                idx={idx}
                                prefix={prefix}
                                setFieldValue={setFieldValue}
                              />
                            )}
                            {property.type ===
                              PROPERTY_OPTIONS.thresholds.value && (
                              <Threshold
                                config={`${prefix}.properties.${idx}`}
                                setFieldValue={setFieldValue}
                                thresholds={property?.thresholds || []}
                                baseColor="#4CBB17"
                              />
                            )}
                            {property.type === PROPERTY_OPTIONS.units.value && (
                              <Formatter
                                name={`${prefix}.properties.${idx}`}
                                values={values}
                              />
                            )}
                            {property.type ===
                              PROPERTY_OPTIONS.calculation.value &&
                              legendValues({
                                title: 'Choose a Calculation',
                                name: `${prefix}.properties.${idx}.calculation`,
                              })}
                          </Stack>
                        )}
                      </>
                    </Stack>
                    <Divider
                      sx={{ borderColor: theme.palette.background.lighter }}
                    />
                  </>
                );
              })}
            </Stack>
            <Button
              size="small"
              sx={{ maxWidth: 175 }}
              variant="contained"
              color="neutral"
              onClick={() => {
                push({
                  id: uuidv4(),
                  baseColor: '#4CBB17',
                });
              }}
            >
              + Add Override Property
            </Button>
          </Stack>
        );
      }}
    </FieldArray>
  );
};
export default Properties;
