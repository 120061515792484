import { Field } from 'formik';
import Box from '@mui/material/Box';
import TextField from 'components/Form/Formik/FormikInput';

const Panel = () => {
  return (
    <Box>
      <Field fullWidth name="title" label="Name" component={TextField} />
      <Field
        fullWidth
        name="description"
        label="Description"
        component={TextField}
        inputProps={{
          multiline: true,
          maxRows: 4,
          minRows: 3,
        }}
      />
    </Box>
  );
};

export default Panel;
