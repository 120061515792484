import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import { analyticsTrack } from 'utils/segment';
import { useSelector } from 'store';

const TimeRangeSelect = ({ value, onChange }) => {
  const store = useSelector((state) => state);
  const theme = useTheme();
  const handleChange = (e, v) => {
    if (v) {
      analyticsTrack('Interval Changed', {
        interval: `${v}H`,
      });
      onChange(v);
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      size="small"
      value={value}
      onChange={handleChange}
      sx={{
        '& .MuiToggleButton-root': {
          lineHeight: '1.2',
        },
        '& .MuiToggleButton-root.Mui-selected': {
          color: theme.palette.primary.contrastText,
          backgroundColor: 'grey.800',
        },
      }}
    >
      <ToggleButton value={24}>24H</ToggleButton>
      <ToggleButton value={12}>12H</ToggleButton>
      <ToggleButton value={6}>6H</ToggleButton>
      <ToggleButton value={1}>1H</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TimeRangeSelect;
