import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

function SwitchLabels({
  id,
  label,
  hasError,
  helpText,
  size,
  checked,
  onChange,
  name,
  value,
  hint,
  disabled = false,
  labelPlacement = 'end',
  formControlLabelProps = {},
  ...other
}) {
  return (
    <FormControl margin="normal" error={hasError} {...other}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              name={name}
              disabled={disabled}
              size={size}
              checked={checked}
              onChange={onChange}
              value={value}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          {...formControlLabelProps}
        />
        {hint && (
          <Typography variant="caption" color="textSecondary">
            {hint}
          </Typography>
        )}
        {hasError && <FormHelperText>{helpText}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
}

export default SwitchLabels;

export const Checkbox = SwitchLabels;
