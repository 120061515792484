import { gql } from '@apollo/client';

export const accountSyncFields = gql`
  fragment AccountSyncSettings on account_sync_settings {
    sync_frequency
    account_id
    enabled
    regions
    service_type
  }
`;
