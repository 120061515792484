import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  gridItemTitle: {
    '&:hover': {
      cursor: 'move',
      backgroundColor: theme.palette.action.hover,
    },
  },
}));
