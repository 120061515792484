import { legendValues } from './Legend';

const StatCalculation = () => {
  return legendValues({
    title: 'Choose a Calculation',
    name: 'statConfig.calculation',
  });
};

export default StatCalculation;
