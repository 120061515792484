import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import Dropdown from 'components/Form/Formik/FormikDropdown';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from 'components/Form/Formik/FormikInput';
import Button from 'components/Button';
import ExpressionResult from './ExpressionResult';

const OPERATORS = [
  { label: 'OR', value: 'or' },
  { label: 'AND', value: 'and' },
];

const EVALUATORS = [
  { label: 'IS ABOVE', value: 'gt' },
  { label: 'IS SAME OR ABOVE', value: 'gte' },
  { label: 'IS BELOW', value: 'lt' },
  { label: 'IS SAME OR BELOW', value: 'lte' },
];

const REDUCERS = [
  { label: 'mean()', value: 'mean' },
  { label: 'max()', value: 'max' },
  { label: 'min()', value: 'min' },
  { label: 'sum()', value: 'sum' },
  { label: 'last()', value: 'last' },
  { label: 'count()', value: 'count' },
];

const EXPRESSIONS_TYPES = [
  { label: 'Math expression', value: 'math' },
  { label: 'Reduce', value: 'reducer' },
  { label: 'Condition expression', value: 'condition_expression' },
];

const ReduceCondition = ({ name, queryOptions }) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      sx={{ display: 'inline-flex' }}
    >
      <Grid item>
        <Field
          size="small"
          margin="none"
          name={`${name}.reducer`}
          component={Dropdown}
          options={REDUCERS}
        />
      </Grid>
      <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          fontWeight={500}
          textAlign="center"
          color="primary.main"
          sx={{ mr: 2 }}
        >
          Input
        </Typography>
        <Field
          size="small"
          margin="none"
          name={`${name}.query`}
          component={Dropdown}
          options={queryOptions ?? []}
        />
      </Grid>
    </Grid>
  );
};

const ConditionExpression = ({ name, values, queryOptions, push, remove }) => {
  return (
    <>
      {values.conditions?.map((condition, index) => {
        return (
          <Grid
            container
            key={index}
            alignItems="center"
            columnSpacing={2}
            sx={{ mb: 1 }}
          >
            <Grid item>
              {index === 0 ? (
                <Typography color="primary" fontWeight={600} variant="body2">
                  WHEN
                </Typography>
              ) : (
                <Field
                  size="small"
                  margin="none"
                  name={`${name}.conditions.${index}.operator`}
                  component={Dropdown}
                  options={OPERATORS}
                />
              )}
            </Grid>
            <Grid item>
              <Field
                size="small"
                margin="none"
                name={`${name}.conditions.${index}.reducer`}
                component={Dropdown}
                options={REDUCERS}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                textAlign="center"
                color="primary"
                sx={{ mr: 1 }}
              >
                OF
              </Typography>
              <Field
                size="small"
                margin="none"
                name={`${name}.conditions.${index}.query`}
                component={Dropdown}
                options={queryOptions ?? []}
              />
            </Grid>
            <Grid item>
              <Field
                size="small"
                margin="none"
                name={`${name}.conditions.${index}.evaluator.type`}
                component={Dropdown}
                options={EVALUATORS}
              />
            </Grid>

            <Grid item>
              <Field
                sx={{ width: 100 }}
                size="small"
                margin="none"
                type="number"
                placeholder="Value"
                name={`${name}.conditions.${index}.evaluator.params.0`}
                component={TextField}
                variant="outlined"
              />
            </Grid>
            {index > 0 && (
              <Grid item>
                <IconButton size="medium" onClick={() => remove(index)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
      <Box>
        <Button
          size="small"
          variant="outlined"
          onClick={() => push({ operator: 'and' })}
        >
          Add condition
        </Button>
      </Box>
    </>
  );
};

const ExpressionCondition = ({ name, values, result, nodeConfig }) => {
  const formik = useFormikContext();
  const formValues = formik.values;
  const queryOptions = formValues[nodeConfig]
    ?.map((i) => i.nodeId)
    .filter((i) => i !== values.nodeId)
    .map((i) => ({ label: i, value: i }));

  const onTypeChange = (type) => {
    switch (type) {
      case 'condition_expression': {
        formik.setFieldValue(`${name}.conditions`, [{}]);
        break;
      }
    }
  };

  return (
    <Box flex={1} px={1}>
      <Field
        transformValue
        fullWidth
        size="small"
        margin="normal"
        name={`${name}.expressionType`}
        placeholder="Type"
        component={Select}
        onChange={onTypeChange}
        options={EXPRESSIONS_TYPES}
        sx={{ width: 200 }}
      />
      {values.expressionType === 'math' && (
        <Box>
          <Field
            fullWidth
            margin="dense"
            name={`${name}.expression`}
            placeholder="For example $A > 10"
            component={TextField}
          />
        </Box>
      )}
      {values.expressionType === 'reducer' && (
        <ReduceCondition name={name} queryOptions={queryOptions} />
      )}
      {values.expressionType === 'condition_expression' && (
        <FieldArray name={`${name}.conditions`}>
          {({ push, remove }) => {
            return (
              <ConditionExpression
                name={name}
                queryOptions={queryOptions}
                values={values}
                push={push}
                remove={remove}
              />
            );
          }}
        </FieldArray>
      )}
      {result && <ExpressionResult data={result} />}
    </Box>
  );
};

export default ExpressionCondition;
