import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNTS_BY_WORKSPACE_ID } from 'graphql/accounts/queries';
import Spin from 'components/Spin';
import CreateAccount from './CreateAccount';
import ConnectAccount from './ConnectAccount';
import { creatAccount, updateAccount } from 'api/account';
import { toast } from 'react-hot-toast';
import Finish from './Finish';

const AWSDataSource = ({ workspace, onFinish, onCancel }) => {
  const [account, setAccount] = useState('');
  const [connected, setConnected] = useState(false);
  const { data, loading } = useQuery(GET_ACCOUNTS_BY_WORKSPACE_ID, {
    variables: {
      workspaceId: workspace.id,
    },
    fetchPolicy: 'network-only',
    onCompleted1: (data) => {},
  });

  useEffect(() => {
    if (data) {
      if (data.accounts.length > 0) {
        const acc = data.accounts[0];
        setAccount(acc);
        if (acc.role_arn) {
          setConnected(true);
        }
      }
    }
  }, [data]);

  const onSaveAccount = async (name) => {
    try {
      const resp = await creatAccount(name, workspace.id);
      setAccount(resp);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onAddRoleArn = async (value) => {
    try {
      await updateAccount(account.id, {
        role_arn: value,
        workspaceId: workspace.id,
      });
      setConnected(true);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Spin spinning={loading}>
      {!account && (
        <CreateAccount onUpdateAccount={onSaveAccount} onCancel={onCancel} />
      )}
      {account && !connected && (
        <ConnectAccount account={account} onAddRoleArn={onAddRoleArn} />
      )}
      {connected && <Finish onFinish={onFinish} account={account} />}
    </Spin>
  );
};

export default AWSDataSource;
