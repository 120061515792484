import { grey } from '../colors';
import { pxToRem } from '../typography';

export default function Table(theme) {
  const isDark = theme.palette.mode === 'dark';
  const palettes = theme.palette;
  return {
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // borderRadius: 4,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${isDark ? grey[800] : grey[200]}`,
          borderRadius: 4,
          backgroundColor: palettes.background.paper,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: isDark ? grey[800] : grey[300],
          fontSize: pxToRem(14),
          fontWeight: 600,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.8125rem',
        },
        root: {
          borderColor: isDark ? grey[800] : grey[200],
          fontSize: pxToRem(14),
        },
        head: {
          borderBottom: 'none',
        },
      },
    },
  };
}
