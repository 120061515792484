import { alpha } from '@mui/material/styles';
import { chipClasses } from '@mui/material/Chip';
// components

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

// ----------------------------------------------------------------------

export default function Chip(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 600,
        },
        sizeSmall: {
          borderRadius: '4px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            backgroundColor: alpha(theme.palette.error.main, 0.1),
          },
        },
        {
          props: { variant: 'outlined', color: 'neutral' },
          style: {
            backgroundColor: alpha(theme.palette.neutral.main, 0.1),
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            backgroundColor: alpha(theme.palette.error.main, 0.1),
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: '50px',
          },
        },
      ],
    },
  };
}
