import { gql } from '@apollo/client';
import { issueFragments } from './fragments';

export const GET_ISSUES = gql`
  query getIssues($query: issues_bool_exp, $offset: Int!, $limit: Int!) {
    rows: issues(
      where: $query
      offset: $offset
      limit: $limit
      order_by: { last_occurrence_at: desc }
    ) {
      ...Issue
    }
    aggregate: issues_aggregate(where: $query) {
      aggregate {
        count
      }
    }
  }
  ${issueFragments}
`;

export const GET_SERVICE_NAMES = gql`
  query getServiceNames($query: issues_bool_exp) {
    rows: issues(where: $query, distinct_on: service_name) {
      service_name
    }
  }
`;

export const GET_RECENT_ISSUES = (serviceName, dataset) => gql`
  query getIssues(
    $workspaceId: String!
    $startTime: timestamptz!
    $endTime: timestamptz!
    $offset: Int!
    $limit: Int!
    $serviceName: String
    $dataset: String
  ) {
    rows: issues(
      where: {
        workspace_id: { _eq: $workspaceId }
        status: { _eq: "open" }
        last_occurrence_at: { _gte: $startTime, _lte: $endTime }
        ${serviceName ? `service_name: { _eq: $serviceName }` : ''}
        ${dataset ? `dataset: { _eq: $dataset }` : ''}
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      ...Issue
    }
    aggregate: issues_aggregate(
      where: {
        workspace_id: { _eq: $workspaceId }
        status: { _eq: "open" }
        last_occurrence_at: { _gte: $startTime, _lte: $endTime }
        ${serviceName ? `service_name: { _eq: $serviceName }` : ''}
        ${dataset ? `dataset: { _eq: $dataset }` : ''}
      }
    ) {
      aggregate {
        count
      }
    }
  }

  ${issueFragments}
`;

export const SUB_GET_ISSUE = gql`
  subscription getIssue($id: uuid!) {
    row: issues_by_pk(id: $id) {
      ...Issue
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${issueFragments}
`;

export const UPDATE_ISSUES = gql`
  mutation updateIssues($ids: [uuid!]!, $update: issues_set_input!) {
    update_issues(where: { id: { _in: $ids } }, _set: $update) {
      returning {
        ...Issue
      }
    }
  }
  ${issueFragments}
`;

export const GET_ISSUE_ANNOTATIONS = gql`
  query getIssueAnnotations($query: issues_bool_exp) {
    rows: issues(where: $query, limit: 100) {
      id
      message
      error_type
      last_occurrence_at
      count
    }
  }
  ${issueFragments}
`;
