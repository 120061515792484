import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function useQueryParams() {
  const location = useLocation();
  const getParams = () => {
    const searchQuery = qs.parse(location.search.replace('?', ''));
    return searchQuery;
  };

  const [params, setParams] = useState(getParams);

  useEffect(() => {
    setParams(getParams());
  }, [location.search]);
  return params;
}
