/* eslint-disable import/no-anonymous-default-export */
import { createTheme } from '@mui/material/styles';
import { menuItemClasses } from '@mui/material/MenuItem';

import { themeTypography } from './typography';
import { DARK, LIGHT, grey } from './colors';
import { palette } from './palette';
import componentsOverrides from './components';
import { customShadows } from './custom-shadows';
import { shadows } from './shadows';

export default (mode = 'light') => {
  const isDark = mode === 'dark';
  // const palettes = isDark ? DARK : LIGHT;
  // palettes.mode = mode;
  const typography = themeTypography();

  const theme = createTheme({
    typography,
    palette: palette(mode),
    components: {
      MuiDrawer: {
        defaultProps: {
          PaperProps: {
            elevation: 0,
          },
        },
      },
    },
    shape: {
      borderRadius: 4,
    },
    spacing: 8,

    customShadows: customShadows(mode),
    shadows: shadows(mode),
  });

  theme.components = componentsOverrides(theme);

  return theme;
};
