import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import Editor from '@monaco-editor/react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import AdditionalOptions from '../AdditionalOptions';

export const PROMQL_QUERY_TYPE = {
  INSTANT: 'instant',
  RANGE: 'range',
};

const PromQLQuery = ({ name }) => {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();

  const queryKey = `${name}.exp`;
  const debouncedQuery = useCallback(debounce(setFieldValue, 200), []);

  const queryTypeKey = `${name}.promql_query_type`;
  const handleQueryTypeChange = (_, value) => {
    setFieldValue(queryTypeKey, value);
  };

  return (
    <Box pb={1}>
      <Typography gutterBottom variant="body2">
        PromQL Query
      </Typography>
      <Stack spacing={0.5}>
        <Editor
          height={60}
          theme={theme.palette.mode === 'dark' ? 'vs-dark' : 'light'}
          onChange={(value) => debouncedQuery(queryKey, value)}
          value={get(values, queryKey)}
          options={{
            lineNumbers: 'off',
            glyphMargin: false,
            folding: false,
            wordWrap: 'on',
            overviewRulerLanes: 0,
            minimap: {
              enabled: false,
            },
            padding: {
              top: 5,
            },
          }}
        />
        <Stack direction="row" alignItems={'center'} spacing={0.5}>
          <Typography variant="body2">Type</Typography>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={get(values, queryTypeKey) || PROMQL_QUERY_TYPE.RANGE}
            onChange={handleQueryTypeChange}
          >
            <ToggleButton value={PROMQL_QUERY_TYPE.RANGE}>
              {PROMQL_QUERY_TYPE.RANGE}
            </ToggleButton>
            <ToggleButton value={PROMQL_QUERY_TYPE.INSTANT}>
              {PROMQL_QUERY_TYPE.INSTANT}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <AdditionalOptions name={name} />
      </Stack>
    </Box>
  );
};

export default PromQLQuery;
