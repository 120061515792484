export const STORE_SESSION = 'STORE_SESSION';
export const SET_ACTIVE_WORKSPACE = 'SET_ACTIVE_WORKSPACE';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_COST_DATA = 'set_cost_data';
export const SET_FORECAST_DATA = 'set_forecast_data';
export const SET_COST_BY_SERVICE = 'set_cost_by_service';
export const SET_COST_BY_REGION = 'set_cost_by_region';
export const SET_LAMBDA_DASHBOARD_METRICS = 'set_lambda_dashboard_metrics';
export const SET_DASHBOARD_TEMPLATES = 'set_dashboard_templates';
export const SET_LAMBDA_LIST_METRICS = 'set_lambda_list_metrics';
export const TOGGLE_THEME = 'toggle_theme';
export const PRODUCT_KEY = 'plan';
export const DEFAULT_PLAN = 'pro_monthly';
export const USE_TIMER_KEY = 'use_timer';
export const USE_TIMER_VALUE = 'true';
export const PROJECT_KEY = 'project';
