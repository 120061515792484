import { gql } from '@apollo/client';

const Resource = gql`
  fragment Resource on resources {
    id
    account_id
    type
    arn
    name
    region
    external_id
    attributes
    tags
    ignored
    log_enabled
  }
`;

export const GET_ALL_RESOURCES = gql`
  query GetAllResources($accountId: String!) {
    resources(
      where: { account_id: { _eq: $accountId } }
      order_by: [{ type: asc, region: asc, name: asc }]
    ) {
      id
      name
      type
      region
      external_id
    }
  }
`;

export const GET_RESOURCE_GROUPINGS = gql`
  query getAllResources($accountId: String) {
    v_resources_groupings(
      where: { account_id: { _eq: $accountId } }
      order_by: [{ type: asc }]
    ) {
      account_id
      total_region
      total_type
      type
    }
  }
`;

export const GET_ALL_RESOURCES_WITH_TYPE = gql`
  query getAllResourcesWithType($type: String, $accountId: String) {
    resources(
      where: { type: { _eq: $type }, account_id: { _eq: $accountId } }
    ) {
      ...Resource
    }
  }
  ${Resource}
`;

export const GET_RESOURCES_WITH_METRICS = gql`
  query getAllResourcesWithType($type: String, $accountId: String) {
    resources(
      where: { type: { _eq: $type }, account_id: { _eq: $accountId } }
    ) {
      ...Resource
      lambda_metrics {
        avg_execution_time
        avg_memory_usage
        cold_start_rate
        total_executions
        total_errors
        error_rate
      }
    }
  }
  ${Resource}
`;

export const GET_RESOURCE_WITH_ID = gql`
  query getResourcesWithId($id: String!) {
    row: resources_by_pk(id: $id) {
      ...Resource
    }
  }
  ${Resource}
`;

export const SUB_RESOURCE_WITH_ID = gql`
  subscription subResourceWithId($id: String!) {
    row: resources_by_pk(id: $id) {
      ...Resource
    }
  }
  ${Resource}
`;

export const GET_RESOURCE_WITH_EXTERNAL_ID = gql`
  query getResourcesWithExternalId($id: String) {
    resources(where: { external_id: { _eq: $id } }) {
      external_id
      id
    }
  }
`;

export const GET_RESOURCE_IN_REGION = gql`
  query getAggregateRegionData($accountId: String) {
    v_region_groupings_aggregate(
      where: {
        account_id: { _eq: $accountId }
        type: { _nin: ["AWS::EC2::Vpc"] }
      }
    ) {
      nodes {
        account_id
        region
        type
        total_type
      }
      aggregate {
        sum {
          total_type
        }
      }
    }
  }
`;

export const SEARCH_RESOURCES = gql`
  query getUserSearchData($userInput: String, $accountId: String) {
    resources(
      where: {
        account_id: { _eq: $accountId }
        _or: [
          { name: { _ilike: $userInput } }
          { arn: { _ilike: $userInput } }
          { external_id: { _ilike: $userInput } }
        ]
      }
    ) {
      external_id
      name
      region
      type
      id
      arn
    }
  }
`;

export const UPDATE_RESOURCE_BY_ID = gql`
  mutation update_resources_by_id($id: String!, $payload: resources_set_input) {
    update_resources_by_pk(pk_columns: { id: $id }, _set: $payload) {
      ...Resource
    }
  }
  ${Resource}
`;

export const GET_RESOURCE_METRICS = gql`
  query getResourceMetrics(
    $id: String!
    $metrics: [MetricQueryInput!]!
    $startDate: String!
    $endDate: String!
  ) {
    row: resources_by_pk(id: $id) {
      id
      metrics(metrics: $metrics, startDate: $startDate, endDate: $endDate) {
        period
        metrics {
          label
          metric
          values
          timestamps
          status
        }
      }
    }
  }
`;

export const GET_LOGS = gql`
  query getLog($accountId: String!, $ServiceId: String!) {
    sync_logs(accountId: $accountId, serviceId: $ServiceId) {
      region
      createdAt
      startTime
      status
      statusReason
      tryCount
    }
  }
`;

export const GET_RESOURCES_WITH_SERVICE = gql`
  query getResourcesWithServices($accountId: String!, $service: [String!]!) {
    rows: resources(
      where: { account_id: { _eq: $accountId }, type: { _in: $service } }
    ) {
      id
      name
    }
  }
`;
