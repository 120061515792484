import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'store';
import { GET_RECENT_ISSUES } from 'graphql/issues/queries';
import { ReactComponent as RecentIssueIcon } from 'assets/icons/recent-issue-icon.svg';
import Card from '../Card';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'components/IconButton';

const RecentIssues = ({
  title,
  endTime,
  startTime,
  isEditable,
  onDelete,
  serviceName,
  dataset,
}) => {
  const { workspace } = useSelector((s) => s.workspace);
  const { data, loading, error } = useQuery(
    GET_RECENT_ISSUES(serviceName, dataset),
    {
      variables: {
        limit: 5,
        offset: 0,
        workspaceId: workspace.id,
        startTime,
        endTime,
        serviceName,
        dataset,
      },
    },
  );

  return (
    <Card
      keyField="id"
      title={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1">{title}</Typography>
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      }
      loading={loading}
      error={error?.message}
      isDraggable
      isEditable={isEditable}
      rows={data?.rows}
      getPrimaryText={(item) => item.error_type || `Unknown error`}
      getSecondaryText={(item) => item.message}
      renderActions={(item) => (
        <Box>
          <Typography textAlign="right">
            {item.count} {item.count === 1 ? 'time' : 'times'}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {moment(item.last_occurrence_at).fromNow(false)}
          </Typography>
        </Box>
      )}
      Icon={RecentIssueIcon}
      iconColor="error"
      count={data?.aggregate.aggregate.count}
      getLink={(item) => `/${item.workspace_id}/issues/${item.id}`}
    />
  );
};

export default RecentIssues;
