import React, { createContext, useState, useCallback, useRef } from 'react';

const uuid = () => (Math.random() * Math.random()).toString(36).substr(2);

const isValidInput = (value) => {
  return value !== undefined && value !== null && value !== '';
};

export const AlertContext = createContext(null);

export const TYPES = {
  PROMPT: 'prompt',
  CONFIRM: 'confirm',
  DIALOG: 'dialog',
};

const DEFAULT_OPTIONS = {
  type: TYPES.CONFIRM,
  // severity: 'info',
  dialogSize: 'xs',
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Yes',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
};

const DEFAULT_PROMPT_OPTIONS = {
  ...DEFAULT_OPTIONS,
  type: TYPES.PROMPT,
  inputValue: '',
  confirmationText: 'Submit',
  isValidInput: isValidInput,
  onConfirm: (v) => v,
};

const DEFAULT_DIALOG_OPTIONS = {
  type: TYPES.DIALOG,
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
};

const buildOptions = (defaultOptions, options) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
  };
};

export const AlertProvider = ({ children, component: AlertComponent }) => {
  const alertContext = useRef(null);
  const [alerts, setAlerts] = useState([]);

  const remove = (id) => {
    if (!id) {
      id = alerts[alerts.length - 1].id;
    }
    setAlerts((prev) => prev.filter((i) => i.id !== id));
  };

  const removeAll = () => setAlerts([]);

  const show = useCallback((options) => {
    if (typeof options === 'string') {
      options = { ...DEFAULT_OPTIONS };
      options.title = options;
    } else if (!options.type) {
      options = Object.assign({}, { ...DEFAULT_OPTIONS }, options);
    }

    const alert = {
      id: uuid(),
      options,
    };

    alert.close = () => remove(alert.id);

    setAlerts((prev) => prev.concat(alert));
    return alert;
  }, []);

  const confirm = useCallback((options) => {
    const alertOptions = { ...DEFAULT_OPTIONS };
    if (typeof options === 'string') {
      alertOptions.title = options;
    } else {
      Object.assign(alertOptions, options);
    }

    return new Promise((resolve) => {
      alertOptions.onConfirm = options.onConfirm || (() => resolve(true));
      alertOptions.onCancel = options.onCancel || (() => resolve(false));
      return show(alertOptions);
    });
  }, []);

  const prompt = useCallback((options) => {
    const alertOptions = { ...DEFAULT_PROMPT_OPTIONS };
    if (typeof options === 'string') {
      alertOptions.title = options;
    } else {
      Object.assign(alertOptions, options);
    }

    return show(alertOptions);
  }, []);

  const dialog = useCallback((options) => {
    const alertOptions = { ...DEFAULT_DIALOG_OPTIONS };
    return show(Object.assign(alertOptions, options));
  }, []);

  alertContext.current = {
    show,
    confirm,
    prompt,
    dialog,
    remove,
    removeAll,
  };

  return (
    <AlertContext.Provider value={alertContext.current}>
      {children}
      {alerts.map((alert) => (
        <AlertComponent key={alert.id} {...alert} />
      ))}
    </AlertContext.Provider>
  );
};
