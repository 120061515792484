const colors = [
  '#FF5733',
  '#FFC300',
  '#7FFF00',
  '#00FF00',
  '#00FF7F',
  '#00FFFF',
  '#1E90FF',
  '#8A2BE2',
  '#FF00FF',
  '#FF1493',
  '#FF69B4',
  '#FF8C00',
  '#FFD700',
  '#7CFC00',
  '#00CED1',
  '#4169E1',
  '#4B0082',
  '#800000',
  '#FF0000',
  '#FF4500',
];

const colors2 = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#393b79',
  '#637939',
  '#8c6d31',
  '#843c39',
  '#7b4173',
  '#5254a3',
  '#a1a1a1',
  '#6b6ecf',
  '#bd9e39',
  '#ad494a',
];

const colors3 = [
  '#1E90FF',
  '#17becf',
  '#bcbd22',
  '#8A2BE2',
  '#7eb26d',
  '#d44b44',
  '#8dd3c7',
  '#ffffb3',
  '#00FFFF',
  '#ff7f0e',
  '#00CED1',
  '#d62728',
  '#FF00FF',
  '#e377c2',
  '#8c564b',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#393b79',
];

export const defaultColors = colors3;
