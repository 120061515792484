/* eslint-disable no-undef */
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import ScrollToTop from 'components/ScrollToTop';
import { AlertProvider } from 'context/alert';
import AlertComponent from './components/AlertComponent/AlertComponent';
import GlobalStyles from './components/GlobalStyles';
import apolloClient from 'utils/apolloClient';
import AcceptInvite from './modules/invitation/accept';
import Routes from './Routes';
import makeTheme from './theme';
import { useSelector, useDispatch } from 'store';
import { toggleTheme } from 'store/actions';
import NotFound from 'modules/misc/NotFound';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

function App() {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme);

  useEffect(() => {
    const prev = localStorage.getItem('theme');
    if (prev) {
      dispatch(toggleTheme(prev));
    }
  }, []);

  const theme = useMemo(() => makeTheme(themeMode), [themeMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <Router>
            <Helmet titleTemplate="%s - KloudMate" />
            <Toaster toastOptions={{ duration: 5000 }} />
            <CssBaseline />
            <GlobalStyles />
            <ScrollToTop />
            <AlertProvider component={AlertComponent}>
              <Switch>
                <Route path="/404" component={NotFound} />
                <Route path="/invitation/accept/:id" component={AcceptInvite} />
                <Routes />
              </Switch>
            </AlertProvider>
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
