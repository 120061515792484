import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import General from './General';
import Overrides from './Overrides';
import { Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';

const Header = ({ children }) => (
  <Typography
    sx={{ textTransform: 'uppercase' }}
    fontWeight={600}
    color="text.secondary"
  >
    {children}
  </Typography>
);

const types = [
  { label: 'TimeSeries', value: 'TimeSeries' },
  { label: 'Stats', value: 'STATS' },
  { label: 'Table', value: 'table' },
];

const tabs = [
  { name: 'General', Component: General },
  { name: 'Overrides', Component: Overrides },
];

const PanelSettings = ({ values, setFieldValue, frames }) => {
  const [tab, setTab] = useState('0');

  const onTabChange = (e, val) => {
    setTab(val);
  };

  const tabs = [{ name: 'General', Component: General }];

  if (values?.panelType !== 'table') {
    tabs.push({ name: 'Overrides', Component: Overrides });
  }

  return (
    <Stack spacing={2} padding={1} component={Paper}>
      <Header>Settings</Header>

      <Stack spacing={1}>
        <Field
          fullWidth
          transformValue
          margin="none"
          name="panelType"
          label="Panel Type"
          component={Select}
          options={types.filter((type) => {
            if (type.value !== 'table') {
              return true;
            }
            const hasCWNodes = (values.node_configs || []).some(
              (nc) => nc.type === 'query' && nc.datasourceId !== 'kloudmate',
            );
            return !hasCWNodes;
          })}
        />
      </Stack>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
          <TabList onChange={onTabChange}>
            {tabs.map(({ name }, idx) => (
              <Tab key={idx} value={String(idx)} label={name} />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ Component, name }, idx) => (
          <TabPanel key={idx} value={String(idx)} sx={{ px: 0, pt: 0.5 }}>
            <Component
              setFieldValue={setFieldValue}
              values={values}
              frames={frames}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Stack>
  );
};

export default PanelSettings;
