import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import QueryBuilder from './QueryBuilder';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import PromQLQuery from './PromQLQuery';
import { DATA_SET } from 'constants/data-sources';

const QB_TAB = 'qb';
const PROMQL_TAB = 'promql';

const KloudMateQueryForm = ({
  name,
  values,
  variableOptions,
  timeRange,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  const [tab, setTab] = useState(values.query_type || QB_TAB);
  const { setFieldValue } = useFormikContext();

  const onTabChange = (_, val) => {
    setFieldValue(`${name}.query_type`, val);
    setTab(val);
  };

  const tabs = [
    {
      id: QB_TAB,
      name: 'Query Builder',
      Component: QueryBuilder,
      props: {
        name,
        values,
        variableOptions,
        timeRange,
        onChangeUnit,
        resolvedVariablesValues,
      },
    },
    {
      id: PROMQL_TAB,
      name: 'PromQL',
      Component: PromQLQuery,
      props: { name },
    },
  ];

  const isMetricDS = values.dataset === DATA_SET.METRICS;

  return isMetricDS ? (
    <TabContext value={tab}>
      <TabList onChange={onTabChange}>
        {tabs.map(({ name, id }) => (
          <Tab sx={{ maxHeight: 10 }} key={id} value={id} label={name} />
        ))}
      </TabList>
      {tabs.map(({ Component, props, id }) => (
        <TabPanel key={id} value={id} sx={{ px: 0, pt: 1.5, pb: 0 }}>
          <Component {...props} />
        </TabPanel>
      ))}
    </TabContext>
  ) : (
    <QueryBuilder {...tabs[0].props} />
  );
};

export default KloudMateQueryForm;
