import { useMemo } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { runMultiAggregateQuery } from 'api/otel';
import { useSelector } from 'store';
import { formatCount, formatNanoSeconds } from 'utils/formatter';
import useRequest from 'hooks/useRequest';
import DataTable from 'components/DataTable';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import IconButton from 'components/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useStyles as usePanelStyles } from 'modules/dashboards/styles';
import Stack from '@mui/material/Stack';

const durationStats = {
  P50: 'P50',
  P90: 'P90',
  P99: 'P99',
  MAX: 'MAX',
};

const TraceSummary = ({
  timestamp,
  hours,
  isEditable,
  onDelete,
  width,
  height,
}) => {
  const history = useHistory();
  const workspaceId = useSelector((s) => s.workspace.workspace.id);
  const panelClasses = usePanelStyles();

  const makeRequest = (params) => runMultiAggregateQuery('traces', params);
  const payload = {
    workspaceId,
    ...timestamp,
    aggregations: Object.values(durationStats)
      .map((stat) => ({
        stat,
        field: 'duration',
        type: 'root',
      }))
      .concat([
        {
          stat: 'COUNT',
          field: 'spanId',
          type: 'root',
        },
      ]),
    groupBy: [{ field: 'serviceName', type: 'root' }],
  };

  const [data, { loading, error }] = useRequest(makeRequest, payload);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.aggregates.map((aggs) => {
      return {
        ...aggs.labels,
        ...aggs.aggregates,
      };
    });
  }, [data]);

  const columns = [
    {
      text: 'Service',
      dataField: 'serviceName',
    },
    {
      text: 'SPANS/MIN',
      dataField: 'count',
      formatter: (val) => formatCount(val / (hours * 60), 0),
    },
    {
      text: durationStats.P50,
      dataField: durationStats.P50,
      formatter: formatNanoSeconds,
    },
    {
      text: durationStats.P90,
      dataField: durationStats.P90,
      formatter: formatNanoSeconds,
    },
    {
      text: durationStats.P99,
      dataField: durationStats.P99,
      formatter: formatNanoSeconds,
    },
    {
      text: durationStats.MAX,
      dataField: durationStats.MAX,
      formatter: formatNanoSeconds,
    },
  ];

  return (
    <Card
      sx={{
        width: '100%',
        height: height,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        p={1}
        className={isEditable && panelClasses.gridItemTitle}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="subtitle1">Services Overview</Typography>
        <Stack direction="row" justifyContent={'flex-end'} alignItems="center">
          {isEditable && (
            <IconButton
              title={'Delete Widget'}
              onClick={onDelete}
              icon={<DeleteIcon />}
            />
          )}
        </Stack>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <DataTable
        loading={loading}
        columns={columns}
        data={tableData}
        stickyHeader
        rowEvents={{
          onClick: (e, row) => {
            history.push({
              pathname: `/${workspaceId}/traces`,
              search: qs.stringify({
                filters: [
                  {
                    type: 'root',
                    field: 'serviceName',
                    value: [row.serviceName],
                  },
                ],
              }),
            });
          },
        }}
        emptyMessage={`No services found. Get started by sending Trace data to KloudMate`}
      />
    </Card>
  );
};

export default TraceSummary;
