import { getSession } from 'utils/auth';
import { storeSession, setActiveWorkspace } from 'store/actions';
import { useDispatch } from 'store';
import { useApolloClient } from '@apollo/client';
import { GET_LOGGED_IN_USER_DETAILS } from 'graphql/users/queries';
import { analyticsIdentify } from 'utils/segment';

export default () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const getKMSessionFromAuthSession = async () => {
    const session = await getSession();

    const { data } = await client.query({
      query: GET_LOGGED_IN_USER_DETAILS,
      variables: {
        id: session.identity.id,
      },
    });
    const {
      users: [user],
    } = data;

    const acl = user.acl[0];

    let org, workspace;
    if (acl && acl.organization && acl.account) {
      org = {
        id: acl.org_id,
        name: acl.organization.name,
        role: acl.org_role,
        created_at: acl.organization.created_at,
        plan: acl.organization.product_key,
        plan_tier: acl.organization.plan.plan_tier,
        default_workspace_id: acl.organization.default_workspace_id,
      };

      workspace = {
        id: acl.account.id,
        name: acl.account.name,
        role: acl.account_role,
      };
    }

    const usr = {
      ...user,
      isVerified: session.identity.verifiable_addresses[0].verified,
    };
    dispatch(setActiveWorkspace(org, workspace));
    dispatch(storeSession(usr));

    analyticsIdentify(
      usr.id,
      {
        email: usr.email,
        name: usr.name,
        createdAt: usr.created_at,
      },
      usr.crm_hash,
    );

    return {
      user: usr,
      org,
      workspace,
    };
  };

  return {
    getKMSessionFromAuthSession,
  };
};
