import TextField from 'components/Form/Formik/FormikInput';
import Select from 'components/Form/Formik/FormikReactSelect';
import { Field, FieldArray } from 'formik';
import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { shortId } from 'utils';
import randomColor from 'randomcolor';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ColorPicker from 'components/Form/ColorPicker';
import get from 'lodash/get';

export const Threshold = ({
  config,
  values,
  setFieldValue,
  thresholds: _thresholds,
  baseColor: _baseColor,
}) => {
  const thresholds = _thresholds || get(values || {}, config)?.thresholds || [];
  const baseColor = _baseColor || get(values || {}, config)?.baseColor;
  const isTS = config.startsWith('timeseries');

  const THRESHOLD_MODE = [
    { label: 'Absolute', value: 'absolute' },
    { label: 'Percentage', value: 'percentage' },
  ];

  const THRESHOLD_LINE_TYPES = [
    { label: 'Off', value: 'off' },
    { label: 'Line', value: 'line' },
    { label: 'Lines (Dashed)', value: 'line-dashed' },
  ];

  return (
    <Stack>
      <FieldArray name={`${config}.thresholds`}>
        {({ push, remove }) => {
          return (
            <Stack spacing={2}>
              <Stack spacing={1}>
                {thresholds.map((threshold, index) => {
                  return (
                    <Stack
                      key={threshold.id}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Field
                        fullWidth
                        name={`${config}.thresholds.${index}.color`}
                        component={ColorPicker}
                        value={threshold.color}
                        onChange={(color) => {
                          setFieldValue(
                            `${config}.thresholds.${index}.color`,
                            color,
                          );
                        }}
                      />
                      <Field
                        fullWidth
                        type="number"
                        margin="none"
                        name={`${config}.thresholds.${index}.value`}
                        component={TextField}
                      />
                      {isTS && (
                        <Field name={`${config}.thresholds.${index}.yAxis`}>
                          {({ field }) => {
                            return (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems={'center'}
                              >
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  whiteSpace={'nowrap'}
                                >
                                  Y-Axis:
                                </Typography>
                                <ToggleButtonGroup
                                  value={field.value || 'left'}
                                  onChange={(e, val) => {
                                    setFieldValue(field.name, val);
                                  }}
                                  size="small"
                                  exclusive
                                >
                                  {[
                                    { label: 'Left', value: 'left' },
                                    { label: 'Right', value: 'right' },
                                  ].map((view) => (
                                    <ToggleButton value={view.value}>
                                      {view.label}
                                    </ToggleButton>
                                  ))}
                                </ToggleButtonGroup>
                              </Stack>
                            );
                          }}
                        </Field>
                      )}

                      <IconButton size="small" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  );
                })}
              </Stack>
              {baseColor && (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Field
                    name={`${config}.baseColor`}
                    component={ColorPicker}
                    value={baseColor}
                    onChange={(color) => {
                      setFieldValue(`${config}.baseColor`, color);
                    }}
                  />
                  <Typography>Default color</Typography>
                </Stack>
              )}
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                  const id = shortId();
                  push({
                    id,
                    value:
                      thresholds.length > 0
                        ? thresholds[thresholds.length - 1].value + 10
                        : 10,
                    color: randomColor(),
                    yAxis: isTS ? 'left' : undefined,
                  });
                }}
              >
                + Add Threshold
              </Button>
            </Stack>
          );
        }}
      </FieldArray>
      <Field name={`${config}.thresholdMode`}>
        {({ field }) => {
          return (
            <Stack direction="column" paddingTop={2} spacing={1}>
              <Typography gutterBottom variant="body1" color="text.secondary">
                Threshold Mode
              </Typography>
              <ToggleButtonGroup
                value={field.value || 'absolute'}
                onChange={(e, val) => {
                  setFieldValue(field.name, val);
                }}
                size="small"
                exclusive
              >
                {THRESHOLD_MODE.map((view) => (
                  <ToggleButton value={view.value}>{view.label}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          );
        }}
      </Field>

      {isTS && (
        <Field
          fullwidth
          transformValue
          size="small"
          label="Line Type"
          name={`${config}.lineType`}
          component={Select}
          value={get(values || {}, config)?.lineType || 'line'}
          options={THRESHOLD_LINE_TYPES}
        />
      )}
    </Stack>
  );
};

export default Threshold;
