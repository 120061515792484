import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    padding: theme.spacing(1.5),
    margin: '1px auto',
  },
  logoContainerShow: {
    'margin': 'unset',
    '& img': {
      height: '25px',
      transition: 'width 0.3s ease',
    },
  },
  logoContainerHide: {
    '& img': {
      height: '25px',
      width: '27px',
      objectFit: 'cover',
      objectPosition: 'left',
    },
  },
  imageIcon: {
    'objectFit': 'contain',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'marginTop': theme.spacing(1),
    'marginBottom': theme.spacing(0.5),
    '& img': {
      width: 40,
    },
  },
  drawerStyles: {
    'position': 'sticky',
    'top': 0,
    '& .MuiDrawer-paper': {
      // 'borderRight': theme.palette.divider,
      'borderRight': 'none',
      // 'padding': theme.spacing(1),
      'backgroundColor': theme.palette.background.navbar,
      'transitionDelay': '100ms',
      'transition': 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'overflowX': 'hidden',
      '& .MuiListItemText-root': {
        transitionDelay: '100ms',
        transition: 'opacity 50ms ease ',
      },
    },
  },
  drawerStylesShow: {
    '& .MuiDrawer-paper': {
      'width': 250,
      '& .MuiListItemText-root': {
        opacity: 1,
      },
    },
  },
  drawerStylesHide: {
    '& .MuiDrawer-paper': {
      'width': 68,
      '& .MuiListItemText-root': {
        opacity: 0,
      },
    },
  },
  collapseIcon: {
    'background': theme.palette.grey['A800'],
    'boxShadow': '0px 2px 6px rgba(0, 0, 0, 0.08)',
    'color': theme.palette.grey[400],
    'borderRadius': '4px',
    'position': 'fixed',
    'top': theme.spacing(4),
    'left': ({ open }) => (open ? '243px' : '62px'),
    'zIndex': theme.zIndex.drawer + 1,
    'padding': theme.spacing(0.5),
    '&:hover': {
      background: theme.palette.grey['800'],
    },
    'transitionDelay': '100ms',
    'transition': 'left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  menuListItem: {
    'flexFlow': 'nowrap',
    'gap': 12,
    'padding': 10,
    'height': 41,
    'borderRadius': 4,
    'transition': '0.4s',
    'justifyContent': 'center',
    'color': 'white',
    'marginBottom': theme.spacing(1),
    '& svg': {
      // marginLeft: 10,
      minHeight: 20,
      minWidth: 20,
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
    },
    '&.selected': {
      'background': theme.palette.grey['700'],
      '& .MuiListItemText-root': {
        fontWeight: 600,
      },
      '& svg': {
        color: 'inherit',
      },
    },
    '&:hover': {
      background: theme.palette.grey['700'],
      color: theme.palette.primary.contrastText,
    },
  },
}));
