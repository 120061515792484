export default function Dialog(theme) {
  return {
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 1,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2, 3),
        },
      },
    },
  };
}
