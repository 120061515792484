import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Button from 'components/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { resendCode, verifyEmail } from 'api/auth';
import { toast } from 'react-hot-toast';
import OTPInput from 'components/OTPInput';
import usePageEvent from 'hooks/usePageEvent';

const ConfirmSignup = ({ username, onAuthStateChange, mode = 'signup' }) => {
  const [error, setError] = useState();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  usePageEvent('Verification Code', {
    section: 'New Account',
    flow: 'Sign Up',
  });

  useEffect(() => {
    if (value.length === 6) {
      onConfirm();
    }
  }, [value]);

  const onResent = async (e) => {
    e.preventDefault();
    try {
      setError();
      await resendCode(username);
      toast.success('Code sent!');
      // return onAuthStateChange('login', { username });
    } catch (e) {
      setError(e.message);
    }
  };

  const onConfirm = async () => {
    try {
      setError();
      setLoading(true);
      await verifyEmail(username, value);
      if (mode === 'reset') {
        toast.success('Password reset successful! Please login.');
      } else {
        toast.success('Your account is confirmed!');
      }
      await onAuthStateChange('confirmedSignup', { username });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}

      <Typography variant="h4" fontWeight={700} mb={2}>
        Verify your email address
      </Typography>

      <Typography paragraph color="textSecondary">
        Please enter the code we just sent to <em>{username}</em>
      </Typography>

      <Box
        sx={{
          '.otpInput': {
            textAlign: 'center',
            borderRadius: 3,
          },
        }}
      >
        <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <OTPInput
            autoFocus
            isNumberInput
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otp) => setValue(otp)}
          />
        </Paper>
      </Box>
      <Box mt={2} textAlign="right">
        Lost your code?{' '}
        <MuiLink color="primary" href="#" onClick={onResent}>
          Resend Code
        </MuiLink>
      </Box>
      <Box mt={4}>
        <Button
          onClick={onConfirm}
          disabled={!value || loading || (value.length === 6 && !error)}
          loading={loading}
          size="large"
          type="submit"
          fullWidth
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmSignup;
