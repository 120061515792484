import { gql } from '@apollo/client';

export const GET_USER_DASHBOARD = gql`
  query getUserDashboardByAccountIdAndUserId(
    $workspaceId: String
    $userId: String
  ) {
    rows: user_dashboards(
      where: { workspace_id: { _eq: $workspaceId }, user_id: { _eq: $userId } }
    ) {
      dashboard
      id
      workspace_id
      user_id
    }
  }
`;

export const SAVE_USER_DASHBOARD = gql`
  mutation saveUserDashboard(
    $workspaceId: String
    $userId: String
    $dashboard: jsonb
  ) {
    row: insert_user_dashboards_one(
      object: {
        workspace_id: $workspaceId
        user_id: $userId
        dashboard: $dashboard
      }
    ) {
      id
      workspace_id
      user_id
      dashboard
    }
  }
`;

export const UPDATE_USER_DASHBOARD = gql`
  mutation updateUserDashboard($id: Int!, $dashboard: jsonb) {
    row: update_user_dashboards_by_pk(
      pk_columns: { id: $id }
      _set: { dashboard: $dashboard }
    ) {
      id
      user_id
      workspace_id
      dashboard
    }
  }
`;
