import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'store';
import QueryVariable from './DashboardSettings/Variables/QueryVariable';
import ConstantVariable from './DashboardSettings/Variables/ConstantVariable';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';

const VariablesContainer = ({ children, direction }) => {
  return (
    <>
      {direction === 'column' && (
        <Grid container alignItems="center">
          {children}
        </Grid>
      )}
      {direction === 'row' && (
        <Stack direction="row" spacing={1} alignItems="center">
          {children}
        </Stack>
      )}
    </>
  );
};

const VariablesBar = ({
  variables,
  onVariableChange,
  defaultRegion,
  compact,
  direction = 'row',
  timeRange,
}) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);

  const onViewModeVariableChange = (value, variable) => {
    onVariableChange(value, variable);
  };

  return (
    <Stack
      direction={direction}
      alignItems={'center'}
      flexWrap={'wrap'}
      rowGap={direction === 'row' && 1}
      my={1}
      spacing={1}
    >
      {(variables || []).map((variable) => {
        return (
          <VariablesContainer direction={direction}>
            <Grid item xs={3}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography>{variable.name}</Typography>
                {variable.hint && (
                  <Tooltip title={variable.hint} placement="top" arrow>
                    <SvgIcon fontSize="1rem">
                      <InfoOutlinedIcon />
                    </SvgIcon>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid item xs={5}>
              {variable.type === 'query' && (
                <QueryVariable
                  isViewMode
                  workspaceId={workspace.id}
                  variable={variable}
                  viewModeOnChange={(value) =>
                    onViewModeVariableChange(value, variable)
                  }
                  resolvedVariablesValues={variables}
                  defaultRegion={defaultRegion}
                  timeRange={timeRange}
                />
              )}
              {variable.type === 'constant' && (
                <ConstantVariable
                  isViewMode
                  variable={variable}
                  viewModeOnChange={(value) =>
                    onViewModeVariableChange(value, variable)
                  }
                />
              )}
            </Grid>
          </VariablesContainer>
        );
      })}
    </Stack>
  );
};

export default VariablesBar;
