import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Input from 'components/Form/TextInput';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import MuiLink from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from 'components/Button';
import { Helmet } from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';
import ConnectAws from './AWSDataSource';
import { analyticsTrack, analyticsPage } from 'utils/segment';
import { env } from 'utils/env';

const AddDataSource = ({
  onUpdateWorkspace,
  mobileStepperProps,
  workspace,
  onFinish,
}) => {
  const [value, setValue] = useState('');
  const [method, setMethod] = useState('');

  usePageEvent('Add Datasource', {
    section: 'Info',
    flow: 'Sign Up',
  });

  useEffect(() => {
    if (method === 'otel') {
      analyticsPage('OTel Integration', {
        section: 'Configuration',
        flow: 'Sign Up',
      });
    }
  }, [method]);

  const NextBtn = React.memo(
    (props) => (
      <Button
        disabled={!value}
        size="large"
        type="primary"
        onClick={() => onUpdateWorkspace(value)}
        {...props}
      >
        Next
      </Button>
    ),
    [value, onUpdateWorkspace],
  );

  return (
    <>
      <Box>
        <Helmet title="Send data to KloudMate" />
        {!method && (
          <Typography paragraph variant="h5" fontWeight={600}>
            How Do You Want to Send Your Data?
          </Typography>
        )}
        {!method && (
          <Stack spacing={1}>
            <ListItem
              button
              onClick={() => setMethod('otel')}
              sx={{ border: 1, borderColor: 'divider' }}
              secondaryAction={<ArrowForwardIcon />}
            >
              <ListItemText
                primary="OpenTelemetry"
                secondary="Send data to KloudMate using OpenTelemetry"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => setMethod('aws')}
              sx={{ border: 1, borderColor: 'divider' }}
              secondaryAction={<ArrowForwardIcon />}
            >
              <ListItemText
                primary="Using AWS Integration"
                secondary="Send data to KloudMate using AWS Integration"
              />
            </ListItem>
          </Stack>
        )}
        {method === 'otel' && (
          <>
            <Typography variant="h5" gutterBottom fontWeight={600}>
              OpenTelemetry
            </Typography>
            <Typography mb={3} color="text.secondary" lineHeight="150%">
              Use OpenTelemetry to collect data from your application. If you're
              already using OpenTelemetry Collector, you can send data to the
              following endpoint along with the api key below as Authorization
              header. Read our documenatation{' '}
              <MuiLink
                target="_blank"
                href="https://docs.kloudmate.com/sending-data-to-kloudmate"
              >
                here
              </MuiLink>
              .
            </Typography>
            <Box mb={2}>
              <Typography>Endpoint:</Typography>
              <code>{env.REACT_APP_UI_URL.replace('app', 'otel') + ':4318'}</code>
              <br />
              <Typography>API Key:</Typography>
              <code>{workspace.private_key}</code>
            </Box>
            <Stack direction="row" spacing={1}>
              <Button variant="text" size="small" onClick={() => setMethod()}>
                Back
              </Button>
              <Button
                size="small"
                onClick={async (e) => {
                  analyticsTrack('Onboarding Completed', {
                    integrationType: 'otel',
                  });
                  await onFinish(e);
                }}
              >
                Go to Dashboard
              </Button>
            </Stack>
          </>
        )}
        {method === 'aws' && (
          <ConnectAws
            workspace={workspace}
            onFinish={onFinish}
            onCancel={setMethod}
          />
        )}
      </Box>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<NextBtn />} />
      )}
    </>
  );
};

export default AddDataSource;
