import React from 'react';
import { regionNames } from '../Map/awsRegions';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Typography from '@mui/material/Typography';

function CostTable({ costByRegion, costByService, loading, type }) {
  const columns = [
    {
      dataField: 'id',
      text: type,
      formatExtraData: 'id',
      formatter: function (name, row, index, formatExtraData) {
        return regionNames[name]
          ? `${regionNames[name]} (${name})`
          : name
          ? name
          : '-';
      },
    },
    {
      dataField: 'cost',
      text: 'COST',
      formatExtraData: 'cost',
      formatter: function (value, row, index, formatExtraData) {
        return `$${value}`;
      },
    },
  ];

  const data = type === 'REGION' ? costByRegion : costByService;

  return (
    <>
      {data?.length > 0 ? (
        <Box>
          <DataTable
            data={data}
            keyField="id"
            loading={loading}
            columns={columns}
          />
        </Box>
      ) : (
        !loading && data && <Typography>No data found</Typography>
      )}
    </>
  );
}

export default CostTable;
