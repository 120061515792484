import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'store';
import { Helmet } from 'react-helmet';
import { Link, Tooltip } from '@mui/material';
import { HomeIcon } from 'constants/Icons';

const useStyles = makeStyles(() => ({
  logo: {
    marginTop: 8,
    marginBottom: 24,
    height: 25,
    margin: 'auto',
    display: 'inherit',
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const theme = useSelector((state) => state.theme);
  const isDarkMode = theme === 'dark';
  const logoPath = isDarkMode
    ? `/assets/images/logo_full_white.svg`
    : `/assets/images/logo_full_black.svg`;

  return (
    <Fragment>
      <Helmet title={'Page not found'} />
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: 460,
            margin: 'auto',
          }}
        >
          <Paper elevation={1} sx={{ p: 3, width: '100%', mt: 2 }}>
            <img className={classes.logo} alt="KloudMate" src={logoPath} />
            <Typography textAlign="center">Page not found.</Typography>
            <Tooltip title="Click to go to home page.">
              <Link
                underline="none"
                sx={{
                  mt: 2,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                href={'/'}
              >
                <HomeIcon />
                <Typography sx={{ ml: 1 }}>Home</Typography>
              </Link>
            </Tooltip>
          </Paper>
        </Box>
      </Container>
    </Fragment>
  );
};

export default NotFound;
