import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'store';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MoreVert from '@mui/icons-material/MoreVert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-hot-toast';
import IconButton from 'components/IconButton';
import Dropdown from 'components/Dropdown';
import { ROLES } from 'constants/roles';
import DataTable from 'components/DataTable';
import Usage from './Usage';
import useAlert from 'hooks/useAlert';
import Typography from '@mui/material/Typography';
import { getVariableUsage } from '../../../utils';

const VAR_USED_MSG = 'This variable is referenced by a panel or variable';
const VAR_NOT_USED_MSG =
  'This variable is not referenced by any panel or variable';

const VariablesList = ({ dashboard, setView, onSave }) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);
  const alert = useAlert();

  const variables = useMemo(() => {
    return (dashboard.data.variables || []).map((variable) => {
      return {
        ...variable,
        ...getVariableUsage(
          variable,
          dashboard.data.panels,
          dashboard.data.variables,
        ),
      };
    });
  }, [dashboard.data.panels, dashboard.data.variables]);

  const onActionSelect = async (key, row) => {
    try {
      const variable = variables.find((v) => v.id === row.id);
      switch (key) {
        case 'delete': {
          alert.confirm({
            title: `Delete Variable: $${row?.name}`,
            description:
              (row.usedInPanels.length + row.usedInVariables.length !== 0
                ? `${VAR_USED_MSG}. `
                : '') + 'Are you sure you want to delete this variable?',
            onConfirm: async () => {
              try {
                await onSave('variables-settings-delete', {
                  payload: dashboard.data.variables.filter(
                    (v) => v.id !== row.id,
                  ),
                  postSave: () => toast.success('Variable Deleted'),
                });
              } catch (e) {
                toast.error(e.message);
              }
            },
          });

          break;
        }
        case 'copy': {
          await onSave('variables-settings', {
            payload: {
              ...variable,
              id: uuidv4(),
              name: `${variable.name}_copy`,
            },
            postSave: () => toast.success('Variable Copied'),
          });
          break;
        }
        case 'view_usage': {
          alert.dialog({
            title: `Usage of variable: ${row.name}`,
            render: () => (
              <Usage
                panels={row.usedInPanels || []}
                variables={row.usedInVariables || []}
              />
            ),
            showCancelButton: false,
            confirmationText: 'Close',
          });
          break;
        }
        case 'edit': {
          setView(row);
          break;
        }
        default: {
          break;
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const hasPermission = workspace.role === ROLES.ADMIN;

  const columns = [
    {
      text: 'Name',
      dataField: 'name',
      sx: {
        width: '20%',
      },
    },
    {
      text: 'Description',
      dataField: 'description',
      sx: {
        width: '70%',
        maxWidth: 400,
      },
      formatter: (val) => {
        return (
          <Tooltip title={val} placement="top" arrow>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {val}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      dataField: 'id',
      sx: {
        width: '10%',
      },
      formatter: (id, row) => {
        if (!hasPermission) return null;
        const options = [
          { key: 'edit', children: 'Edit' },
          { key: 'copy', children: 'Copy' },
          { key: 'view_usage', children: 'View Usage' },
          { key: 'delete', children: 'Delete' },
        ].filter((option) =>
          row.usedInPanels.length + row.usedInVariables.length === 0
            ? option.key !== 'view_usage'
            : true,
        );
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            {row.usedInPanels.length + row.usedInVariables.length === 0 ? (
              <Tooltip title={VAR_NOT_USED_MSG} placement="top" arrow>
                <WarningAmberIcon color="warning" />
              </Tooltip>
            ) : (
              <Tooltip title={VAR_USED_MSG} placement="top" arrow>
                <CheckIcon color="success" />
              </Tooltip>
            )}
            <Dropdown
              options={options}
              onSelect={(action) => onActionSelect(action, row)}
            >
              <IconButton icon={<MoreVert />} />
            </Dropdown>
          </Stack>
        );
      },
    },
  ];

  return (
    <DataTable
      hover={false}
      size="small"
      keyField="id"
      data={variables || []}
      columns={columns}
      rowEvents={{
        onClick: (e, { usedInPanels, usedInVariables, ...variable }) => {
          setView(variable);
        },
      }}
      emptyMessage={`No Variables configured`}
    />
  );
};

export default VariablesList;
