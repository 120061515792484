import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';

export const useRightSideInfoStyles = makeStyles((theme) => ({
  rightPanel: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  rightPanelImg: {
    'width': '100%',
    'maxWidth': '90%',
    'textAlign': 'center',
    '& img': {
      width: '100%',
      maxWidth: '592px',
    },
  },
}));

const RightSideInfo = () => {
  const classes = useRightSideInfoStyles();
  const history = useHistory();
  const theme = useSelector((state) => state.theme);
  const isDarkMode = theme === 'dark';

  const isSingup = history.location.pathname === '/signup';

  const rightPanelImg = isDarkMode
    ? isSingup
      ? `/assets/images/signUpDark.svg`
      : `/assets/images/LogInDark.svg`
    : isSingup
    ? `/assets/images/signUpLight.svg`
    : `/assets/images/LogInLight.svg`;

  return (
    <Box className={classes.rightPanel}>
      <Box className={classes.rightPanelImg}>
        <img alt="KloudMate" src={rightPanelImg} />
      </Box>
      <Box mt={1} pt={4}>
        <Typography fontWeight={100} variant="h5" whiteSpace="pre-wrap">
          Best-in-class monitoring for your distributed applications &
          microservices.
        </Typography>
      </Box>
    </Box>
  );
};

export default RightSideInfo;
