import Select from 'components/Form/Select';
import Dropdown from 'components/Form/Dropdown';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const OrderBy = ({
  name,
  columns,
  queryOptions,
  updateQueryOptions,
  nodeIdx,
}) => {
  const orderBy = queryOptions.node_configs[nodeIdx[0]]?.orderBy || [
    { field: '' },
  ];

  const options = columns
    .filter((c) => Boolean(c.isRemoteSortable))
    .concat([{ label: 'None', value: undefined, nodeIdx }]);

  const onChangeField = ({ value, option, order }) => {
    for (const idx of nodeIdx) {
      updateQueryOptions(`${name}.${idx}.orderBy`, null);
      updateQueryOptions(`${name}.${idx}.limit`, null);
    }

    for (const idx of option.nodeIdx) {
      const newKey = `${name}.${idx}.orderBy.0`;
      updateQueryOptions(`${newKey}.field`, value);
      updateQueryOptions(`${newKey}.order`, order.order || 'desc');
    }
  };

  const onChangeOrder = ({ value }) => {
    for (const idx of nodeIdx) {
      updateQueryOptions(`${name}.${idx}.orderBy.0.order`, value);
    }
  };

  return (
    <Stack>
      <Typography gutterBottom color="text.secondary">
        Order By
      </Typography>
      <Stack>
        {orderBy.map((order) => {
          return (
            <Grid container>
              <Grid item sm={8}>
                <Select
                  fullWidth
                  transformValue
                  margin="none"
                  size="small"
                  placeholder="Field"
                  component={Select}
                  options={options}
                  onChange={(value, { option }) =>
                    onChangeField({ option, value, order })
                  }
                  value={order.field}
                />
              </Grid>
              <Grid item sm={4}>
                <Dropdown
                  fullWidth
                  size="small"
                  placeholder="Order"
                  margin="none"
                  options={[
                    { label: 'ASC', value: 'asc' },
                    { label: 'DESC', value: 'desc' },
                  ]}
                  onChange={(value) => onChangeOrder({ value })}
                  value={order.order || 'desc'}
                />
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default OrderBy;
