import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Login from 'modules/auth/pages/Login';
import SignUp from 'modules/auth/pages/Signup';
import Button from 'components/Button';
import toast from 'react-hot-toast';
import Spin from 'components/Spin';
import { useSelector } from 'store';
import { analyticsTrack, analyticsGroup } from 'utils/segment';
import { apiClient } from 'api';
import { getMe } from 'api/auth';

const useStyles = makeStyles(() => ({
  logo: {
    marginTop: 8,
    marginBottom: 24,
    height: 25,
    margin: 'auto',
    display: 'inherit',
  },
}));

const AcceptInvite = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const store = useSelector((state) => state);
  const { theme } = store;
  const isDarkMode = theme === 'dark';
  const logoPath = isDarkMode
    ? `/assets/images/logo_full_white.svg`
    : `/assets/images/logo_full_dark.svg`;

  useEffect(() => {
    Promise.all([
      apiClient.get(`users/invitations/${id}`, {}),
      getMe().catch((err) => {
        console.log(err);
      }),
    ])
      .then(([invitation, user]) => {
        setData(invitation);
        setUser(user);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleAccept = () => {
    setLoading(true);
    return apiClient
      .put(`users/invitations/${id}`, {})
      .then((resp) => {
        analyticsTrack('Account Added User', {
          role: data?.invite.role,
          context: {
            groupId: data?.invite.organization.id,
          },
        });

        history.replace('/');
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: 460,
            margin: 'auto',
          }}
        >
          <Paper elevation={1} sx={{ p: 3, width: '100%', mt: 2 }}>
            <img className={classes.logo} alt="KloudMate" src={logoPath} />
            <Spin spinning={loading}>
              {data ? (
                <Box p={2}>
                  {data.userStatus === 'exists' ? (
                    <Box>
                      {user ? (
                        <Box>
                          <Typography gutterBottom>Hi, {user.name}!</Typography>
                          <Typography paragraph>
                            You've been invited to join{' '}
                            <b>{data.invite.organization.name}</b>.
                          </Typography>
                          <Box textAlign="center">
                            <Button size="large" onClick={handleAccept}>
                              Accept & Join
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            paragraph
                            variant="subtitle2"
                            textAlign="center"
                          >
                            User account already exists. Please login to to join{' '}
                            {data.invite.organization.name}.
                          </Typography>
                          <Login hideHeader onLoginSuccess={handleAccept} />
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <Typography paragraph textAlign="center">
                        Welcome {data.invite.email}! Please signup to accept the
                        invitation to join{' '}
                        <b>{data.invite.organization.name}</b>
                      </Typography>
                      <SignUp
                        hideHeader
                        disableEmail
                        email={data.invite.email}
                        onSignUpSuccess={handleAccept}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                !loading &&
                !data && (
                  <Typography textAlign="center">
                    Invitation not found.
                  </Typography>
                )
              )}
            </Spin>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default AcceptInvite;
