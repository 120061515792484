import moment from 'moment';
import keyBy from 'lodash/keyBy';
import { DEFAULT_REGION } from 'constants/aws-regions';
import { DATA_SOURCES, DATA_SET } from 'constants/data-sources';
import { defaultAgg } from 'modules/alarms/utils';
import { parseValue } from 'components/DateRangePickerNew';
import {
  OTEL_KEY,
  OTEL_VALUES,
} from '../components/DashboardSettings/Variables/QueryVariable';
import isNil from 'lodash/isNil';
import { otelClient } from 'api';

export const calculateTimeRange = (val, timeshift) => {
  const { from, to } = parseValue(val, timeshift);
  return {
    from: moment().utc().diff(from, 'seconds'),
    to: moment().utc().diff(to, 'seconds'),
  };
};

const buildPayload = (values, workspaceId) => {
  const isTablePanel = values.panelType === 'table';
  return {
    params: values.node_configs.map((ele) => {
      return {
        ...ele,
        time_range: calculateTimeRange(
          values.timeRange,
          values.time_options?.time_shift,
        ),
        legendFormat: !isTablePanel && ele.legendFormat,
      };
    }),
    workspace_id: workspaceId,
  };
};

const transformTimeSeries = (nodes, input) => {
  const nodeConfigs = keyBy(input.node_configs, 'nodeId');

  const labelsHash = {};
  const modifiedNodes = nodes.map((node, index) => {
    if (node.legend) {
      return { ...node, name: node.legend };
    }
    if (node.type !== 'series') {
      return node;
    }
    const { nodeId, labels } = node;
    const config = nodeConfigs[node.nodeId];
    let name;
    if (node.name === nodeId) {
      name = `${nodeId} ${labels ? Object.values(labels).join(' ') : ''} ${
        config.metric || ''
      }`;
    } else if (Object.keys(labels).length) {
      name = `${node.name} ${config.metric || ''}`;
    } else {
      name = node.name;
    }
    if (labelsHash[name]) {
      labelsHash[name] = labelsHash[name].concat(index);
    } else {
      labelsHash[name] = [index];
    }
    return node.type === 'series'
      ? {
          ...node,
          name,
        }
      : node;
  });

  const duplicateLabels = Object.values(labelsHash).reduce(
    (prev, curr) => (curr.length > 1 ? prev.concat(curr) : prev),
    [],
  );

  return modifiedNodes.map((node, index) => {
    return duplicateLabels.includes(index)
      ? { ...node, name: `${node.name} ${nodeConfigs[node.nodeId].stat || ''}` }
      : node;
  });
};

const resolveConfig = (config, variableValues, defaultRegion) => {
  let resolvedConfig = JSON.stringify(config);
  const isPromQL =
    config.query_type === 'promql' &&
    config.datasourceId === DATA_SOURCES.KLOUDMATE;

  const getVariableReplacements = (variable) => {
    const replacements = [];
    if (isNil(variable.value)) {
      return replacements;
    }

    const variableType = variable.config?.queryType;
    let replaceWith;

    // multi value variables
    if (variable.is_multi) {
      if (variable.value.some((v) => v === 'none')) {
        const regex = '\\$' + variable.name;
        replaceWith = '';
        replacements.push({
          regex,
          replaceWith,
        });
        return replacements;
      }

      if (isPromQL) {
        const regex = '\\$' + variable.name;
        if (variable.value.some((v) => v === '*')) {
          replaceWith = '.*';
        } else if (variableType === OTEL_VALUES) {
          // in promql join multiple values using |
          replaceWith = variable.value.map((v) => v.field || v).join('|');
        } else if (variableType === OTEL_KEY) {
          // in promql join multiple keys using ,
          replaceWith = variable.value.map((v) => v.field || v).join(', ');
        }

        replaceWith &&
          replacements.push({
            regex,
            replaceWith,
          });

        return replacements;
      }

      const regex = '"\\$' + variable.name + '"';
      if (variableType === OTEL_VALUES) {
        replaceWith = `"${variable.value.join(',')}"`;
      } else if (variableType === OTEL_KEY) {
        replaceWith = JSON.stringify(
          variable.value.map((v) =>
            typeof v === 'string' ? { field: v, type: 'string' } : v,
          ),
        )
          .slice(1)
          .slice(0, -1); // remove first and last array brackets
      } else if (variableType === 'dimension_values') {
        replaceWith = JSON.stringify(variable.value).slice(1).slice(0, -1);
      } else {
        replaceWith = `"${variable.value}`;
      }

      replacements.push({
        regex,
        replaceWith,
      });

      return replacements;
    }

    // single value variables

    if (variable.value === 'none') {
      const regex = '\\$' + variable.name;
      replaceWith = '';
      replacements.push({
        regex,
        replaceWith,
      });
      return replacements;
    }

    if (isPromQL) {
      const regex = '\\$' + variable.name;
      if (variable.value === '*') {
        replaceWith = '.*';
      } else if (variableType === OTEL_VALUES) {
        replaceWith = variable.value;
      } else if (variableType === OTEL_KEY) {
        replaceWith = variable.value.field;
      }
      replacements.push({
        regex,
        replaceWith,
      });

      return replacements;
    }

    const regex = '"\\$' + variable.name + '"';
    if (variableType === OTEL_VALUES) {
      replaceWith = `"${variable.value}"`;
    } else if (variableType === OTEL_KEY) {
      [
        { find: 'field', replace: 'field' },
        { find: 'type', replace: 'type' },
        { find: 'key', replace: 'field' },
      ].forEach(({ find, replace }) => {
        const regex = `(?<=\"${find}\":)` + '"\\$' + variable.name + '"';
        replacements.push({
          regex,
          replaceWith: `"${variable.value[replace]}"`,
        });
      });

      replaceWith = JSON.stringify(
        typeof variable.value === 'string'
          ? { field: variable.value, type: 'string' }
          : variable.value,
      );
    } else {
      replaceWith = `"${variable.value}"`;
    }

    replacements.push({
      regex,
      replaceWith,
    });
    return replacements;
  };

  (variableValues || [])
    .concat({ name: 'default', value: defaultRegion || DEFAULT_REGION })
    .forEach((variable) => {
      const replacements = getVariableReplacements(variable);

      replacements.forEach(({ regex, replaceWith }) => {
        const regExp = new RegExp(regex, 'gi');
        resolvedConfig = resolvedConfig.replace(regExp, replaceWith);
      });
    });

  try {
    return JSON.parse(resolvedConfig);
  } catch (error) {
    return config;
  }
};

export const resolveTemplateVariables = (
  values,
  variableValues,
  defaultRegion,
) => {
  if (Array.isArray(values.node_configs)) {
    return {
      ...values,
      node_configs: values.node_configs.map((config) =>
        resolveConfig(config, variableValues, defaultRegion),
      ),
    };
  }
  return resolveConfig(values, variableValues, defaultRegion);
};

export const runQuery = async (values, workspaceId) => {
  const res = await otelClient.post(
    'queries/run',
    buildPayload(values, workspaceId),
  );

  if (!res) {
    return [];
  }

  return res;
};

export const runAlarmQuery = async (values, workspaceId) => {
  if (!values?.node_configs) {
    return null;
  }

  const res = await runQuery(values, workspaceId);

  const nodes = Object.keys(res)
    .map((nodeId) => res[nodeId])
    .flatMap((node) => node.frames);

  return transformTimeSeries(nodes, values);
};

export const FOLDERS = 'folder';
export const DASHBOARDS = 'dashboard';

export const getVariableUsage = (variable, panels, variables) => {
  const regExp = (varName) => new RegExp('\\$' + varName, 'gi');
  const variableConfig = JSON.stringify(variable.config);

  return {
    usedInPanels: panels
      .filter(
        (panel) => JSON.stringify(panel).match(regExp(variable.name))?.length,
      )
      .map((panel) => ({ id: panel.id, name: panel.title })),
    usedInVariables: variables
      .filter((v) => v.id !== variable.id)
      .filter((v) => JSON.stringify(v).match(regExp(variable.name))?.length)
      .map((v) => ({ id: v.id, name: v.name })),
    usesVariables: variables
      .filter((v) => variableConfig.match(regExp(v.name))?.length)
      .map((v) => ({ id: v.id, name: v.name })),
  };
};

export const DEFAULT_PANEL_CONFIG = {
  nodeId: 'A',
  type: 'query',
  stat: '',
  region: '',
  source: DATA_SOURCES.KLOUDMATE,
  namespace: '',
  dimensions: [],
  aggregation: defaultAgg,
  filters: [],
  interval: '',
  groupBy: [],
  orderBy: [],
  limit: '',
  metricType: '',
  metric: '',
  dataset: DATA_SET.METRICS,
  time_range: {
    from: 600,
    to: 0,
  },
  hidden: false,
};

export const DEFAULT_PANEL_GRID_CONFIG = {
  w: 4,
  h: 2,
  x: 0,
  y: 0,
  minW: 2,
  minH: 1,
};
