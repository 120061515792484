import useRequest from 'hooks/useRequest';
import React, { useMemo, useState, useEffect } from 'react';
import Select from 'components/Form/Select';
import { fetchKeys } from 'api/otel';
import { useSelector } from 'store';
import DataTypeIcon from 'components/DataTypeIcon';
import debounce from 'lodash/debounce';
import { Operators } from 'constants/aggregations';
import { resolveTemplateVariables } from 'modules/dashboards/utils';
import { OTEL_KEY } from 'modules/dashboards/components/DashboardSettings/Variables/QueryVariable';
import { toast } from 'react-hot-toast';

const getFilters = (filters) =>
  filters.filter((f) => {
    if (!f.field) return false;
    const requiresValue =
      Operators.find((o) => o.value === f.operator).requiresValue !== false;
    return requiresValue ? f.value !== undefined : true;
  });

const KeyField = ({
  name,
  value,
  onChange,
  dataset,
  from,
  to,
  variableOptions,
  filters = [],
  freeSolo,
  isMulti,
  resolvedVariablesValues,
  ...rest
}) => {
  const workspaceId = useSelector((state) => state.workspace.workspace.id);
  const [query, setQuery] = useState('');
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        freeSolo && onChange(name, value);
        setQuery(value);
      }, 400),
    [],
  );

  const [data, { loading, error }] = useRequest(
    fetchKeys,
    {
      dataset,
      query: {
        query,
        workspaceId,
        from,
        to,
        filters: resolveTemplateVariables(
          getFilters(filters),
          resolvedVariablesValues,
        ),
      },
    },
    {
      skip: !dataset,
    },
  );

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const filterKeys = useMemo(() => {
    return (data ?? []).map((i) => ({
      ...i,
      icon: <DataTypeIcon type={i.type} />,
      groupBy: variableOptions?.length ? 'Keys' : undefined,
    }));
  }, [data]);

  const getLabel = (option) => {
    return option.field || option.label || '';
  };

  const isOptionEqualToValue = (option, value) => {
    if (value.value) {
      return option.value === value.value;
    }
    return option.field === value.field && option.type === value.type;
  };

  return (
    <Select
      fullWidth
      isLoading={loading}
      size="small"
      margin="none"
      placeholder="Field"
      component={Select}
      options={(variableOptions || [])
        .filter((opt) => isMulti || opt.isMulti === isMulti)
        .filter((opt) => opt.queryType === OTEL_KEY)
        .concat(filterKeys)}
      value={value}
      getOptionLabel={getLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(val) => {
        onChange(name, val);
      }}
      onInputChange={(e, v, reason) => {
        if (reason === 'input') {
          debouncedSearch(v);
        }
      }}
      freeSolo={freeSolo}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default KeyField;
