import axios from 'axios';
import { env } from 'utils/env';

const requestInterceptor = (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const apiClient = axios.create({
  baseURL: env.REACT_APP_API_URL,
});

export const otelClient = axios.create({
  baseURL: env.REACT_APP_OTEL_API_URL,
});

export const hasuraClient = axios.create({
  baseURL: env.REACT_APP_HASURA_REST_URL,
});

otelClient.interceptors.request.use(requestInterceptor);
apiClient.interceptors.request.use(requestInterceptor);

const successInterceptor = (response) => {
  return response.data;
};

const errorInterceptor = (error) => {
  if (error.response) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
};

apiClient.interceptors.response.use(successInterceptor, errorInterceptor);
otelClient.interceptors.response.use(successInterceptor, errorInterceptor);
hasuraClient.interceptors.response.use(successInterceptor, errorInterceptor);

export const changeIncidentStatus = (id, workspaceId, action) => {
  return apiClient.post(`im/incidents/${id}/${action}`, {
    workspaceId,
  });
};

export const createIncident = (data) => {
  return apiClient.post('im/incidents', data);
};

export const testTemplate = ({ payload, template }) => {
  return apiClient.post('im/test-template', {
    payload,
    template,
  });
};

export const getStripeCustomer = (orgId) =>
  apiClient.get(`billing/${orgId}/customer`, {});

export const setDefaultPaymentMethod = (orgId, paymentMethodId) =>
  apiClient.post(`billing/${orgId}/payment-method`, {
    payment_method: paymentMethodId,
  });
