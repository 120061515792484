import React, { useMemo } from 'react';
import DataTable from 'components/DataTable';
import Typography from '@mui/material/Typography';
import Label from './Label';

const LegendTable = ({ loading, legendCalculations, series }) => {
  const merged = useMemo(() => {
    return series
      .map((s, i) => {
        if (i === 0) {
          return null;
        }
        const cols = {};
        legendCalculations[i].forEach(({ value, name }) => {
          cols[name] = value;
        });

        return {
          ...s,
          cols,
        };
      })
      .splice(1);
  }, [series, legendCalculations]);

  const columns = useMemo(() => {
    if (merged[0]) {
      const cols = [];
      cols.push({
        dataField: 'name',
        text: '',
        formatter: (val, row) => {
          return <Label text={val} color={row.stroke} />;
        },
      });

      Object.keys(merged[0].cols).forEach((key) => {
        cols.push({
          text: key,
          dataField: `cols.${key}`,
          numeric: true,
          formatter: (val, row) => {
            return <Typography variant="text">{val}</Typography>;
          },
        });
      });

      return cols;
    }
  }, [merged]);

  return merged.length > 0 ? (
    <DataTable
      hover={false}
      size="small"
      keyField="id"
      loading={loading}
      data={merged}
      columns={columns}
      emptyMessage={`No data found`}
    />
  ) : null;
};

export default LegendTable;
