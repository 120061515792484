import { apiClient } from './index';

function decodeJwt(token) {
  const parts = token.split('.');
  return JSON.parse(atob(parts[1]));
}

export const register = (email, password, name) => {
  return apiClient.post('auth/register', { email, password, name });
};

export const getMe = () => {
  return apiClient.get('users/me');
};

export const verifyEmail = (email, code) => {
  return apiClient.post('auth/verify', { email, code });
};

export const resendCode = (email) => {
  return apiClient.post('auth/resend-code', { email });
};

export const requestPasswordReset = (email) => {
  return apiClient.post('auth/forgot-password', { email });
};

export const resetPassword = (email, code, password) => {
  return apiClient.post('auth/reset-password', { email, code, password });
};

export const updatePassword = (oldPassword, newPassword) => {
  return apiClient.post('auth/update-password', { oldPassword, newPassword });
};

export const login = async (email, password) => {
  const resp = await apiClient.post('auth/login', { email, password });
  if (resp.jwt) {
    localStorage.setItem('token', resp.jwt);
    hub.emit('auth', { event: 'signIn', data: decodeJwt(resp.jwt) });
  }
  return resp;
};

export const signOut = () => {
  localStorage.removeItem('token');
  hub.emit('auth', { event: 'signOut' });
};

export const generateMfa = () => {
  return apiClient.post('auth/mfa/generate');
};

export const enableMfa = (code) => {
  return apiClient.post('auth/mfa/verify', { code });
};

export const disableMfa = () => {
  return apiClient.delete('auth/mfa/disable');
};

export const verifyMfa = async (email, token, code) => {
  const resp = await apiClient.post('auth/mfa/validate', {
    email,
    token,
    code,
  });
  if (resp.jwt) {
    localStorage.setItem('token', resp.jwt);
    hub.emit('auth', { event: 'signIn', data: decodeJwt(resp.jwt) });
  }
  return resp;
};

class Hub {
  listeners = [];
  listen(event, callback) {
    this.listeners.push({ event, callback });
  }
  emit(event, payload) {
    this.listeners.forEach((listener) => {
      if (listener.event === event) {
        listener.callback({ payload });
      }
    });
  }
}

export const hub = new Hub();
