import { alpha } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import { grey } from '../colors';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

// ----------------------------------------------------------------------

export default function Button(theme) {
  const { palette } = theme;
  const isLight = theme.palette.mode === 'light';

  const rootStyles = (ownerState) => {
    const inheritColor = ownerState.color === 'inherit';

    const containedVariant = ownerState.variant === 'contained';

    const outlinedVariant = ownerState.variant === 'outlined';

    const textVariant = ownerState.variant === 'text';

    const softVariant = ownerState.variant === 'soft';

    const smallSize = ownerState.size === 'small';

    const mediumSize = ownerState.size === 'medium';

    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      textTransform: 'none',
      fontWeight: 600,
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          'color': isLight
            ? theme.palette.common.white
            : theme.palette.grey[800],
          'backgroundColor': isLight
            ? theme.palette.grey[800]
            : theme.palette.common.white,
          '&:hover': {
            backgroundColor: isLight
              ? theme.palette.grey[700]
              : theme.palette.grey[400],
          },
        }),
        // OUTLINED
        ...(outlinedVariant && {
          'borderColor': alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        // TEXT
        ...(textVariant && {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        // SOFT
        ...(softVariant && {
          'color': theme.palette.text.primary,
          'backgroundColor': alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.24),
          },
        }),
      }),
      ...(outlinedVariant && {
        '&:hover': {
          borderColor: 'currentColor',
          boxShadow: '0 0 0 0.5px currentColor',
        },
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          '&:hover': {},
        }),
        // SOFT
        ...(softVariant && {
          'color': theme.palette[color][isLight ? 'dark' : 'light'],
          'backgroundColor': alpha(theme.palette[color].main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.32),
          },
        }),
      }),
    }));

    const disabledState = {
      [`&.${buttonClasses.disabled}`]: {
        // SOFT
        ...(softVariant && {
          backgroundColor: theme.palette.action.disabledBackground,
        }),
      },
    };

    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 12,
        paddingLeft: 8,
        paddingRight: 8,
        ...(textVariant && {
          paddingLeft: 4,
          paddingRight: 4,
        }),
      }),
      ...(mediumSize && {
        height: 36,
        padding: theme.spacing(1, 1.5),
        fontSize: 14,
        ...(textVariant && {
          padding: theme.spacing(1),
        }),
      }),
      ...(largeSize && {
        height: 40,
        fontSize: 15,
        paddingLeft: 16,
        paddingRight: 16,
        ...(textVariant && {
          paddingLeft: 10,
          paddingRight: 10,
        }),
      }),
    };

    return [defaultStyle, ...colorStyle, disabledState, size];
  };

  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        defaultProps: {
          disableElevation: true,
          disableTouchRipple: true,
        },
      },

      styleOverrides: {
        root: ({ ownerState }) => rootStyles(ownerState),
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'neutral' },
          style: {
            background: 'transparent',
            borderColor: grey[500],
            color: grey[500],
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            background: 'transparent',
            borderColor: palette.secondary.light,
            color: palette.secondary.light,
          },
        },
        {
          props: { variant: 'rounded', color: 'neutral' },
          style: {
            'background': palette.neutral.main,
            'borderRadius': '100px',
            'border': `1px solid ${palette.neutral.main}`,
            '&:hover': {
              backgroundColor: palette.neutral.dark,
            },
            '&:disabled': {
              backgroundColor: palette.action.disabledBackground,
            },
          },
        },
        {
          props: { variant: 'rounded', color: 'primary' },
          style: {
            'background': palette.primary.main,
            'borderRadius': '100px',
            'border': `1px solid ${palette.primary.main}`,
            '&:hover': {
              backgroundColor: palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: palette.action.disabledBackground,
              border: `1px solid ${palette.action.disabled}`,
            },
          },
        },
        {
          props: { variant: 'rounded', color: 'secondary' },
          style: {
            background: 'transparent',
            border: `1px solid ${grey[700]}`,
            borderRadius: '100px',
            color: grey[500],
          },
        },
      ],
    },
  };
}
