import React from 'react';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ButtonField = ({ setOpen, id, InputProps: { ref } = {} }) => (
  <IconButton
    variant="outlined"
    id={id}
    ref={ref}
    onClick={() => setOpen((prev) => !prev)}
  >
    <EventIcon />
  </IconButton>
);

const ButtonDatePicker = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ ...props.slots, field: ButtonField }}
      slotProps={{ ...props.slotProps, field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      disableFuture
    />
  );
};

export default ButtonDatePicker;
