import { getCurrentSpan } from '../utils/otelInstrumentation';

const integrations = {
  'All': false,
  'Intercom': true,
  'Mixpanel': true,
  'journy io': true,
};

export const analyticsPage = (pageName, payload) => {
  try {
    const span = getCurrentSpan('page');
    span.addEvent(pageName, payload);
    window.analytics.page(pageName, payload, integrations);
  } catch (e) {}
};

export const analyticsIdentify = async (id, payload, hashCode) => {
  try {
    if (payload.createdAt) {
      payload.createdAt = new Date(payload.createdAt).valueOf();
    }
    window.analytics.identify(
      id,
      payload,
      hashCode && {
        Intercom: {
          user_hash: hashCode,
        },
      },
    );
  } catch (e) {}
};

export const analyticsTrack = (event, payload) => {
  try {
    const span = getCurrentSpan('track');
    span.addEvent(event, payload);
    window.analytics.track(event, payload, integrations);
  } catch (e) {}
};

export const analyticsReset = () => {
  try {
    window.analytics.reset();
  } catch (e) {}
};

export const analyticsGroup = (groupId, payload) => {
  try {
    window.analytics.group(groupId, payload, integrations);
  } catch (e) {}
};
