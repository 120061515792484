import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import General from './General';
import Variables from './Variables';

const tabs = [
  { name: 'General', Component: General },
  { name: 'Variables', Component: Variables },
];

const DashboardSettings = ({ dashboard, onClose, onSave, onDelete }) => {
  const [tab, setTab] = useState('0');

  const onTabChange = (e, val) => {
    setTab(val);
  };

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box
        sx={{
          width: 700,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
        }}
      >
        <Box p={2}>
          <Typography gutterBottom variant="h4">
            Dashboard Settings
          </Typography>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
              <TabList onChange={onTabChange}>
                {tabs.map(({ name }, idx) => (
                  <Tab key={idx} value={String(idx)} label={name} />
                ))}
              </TabList>
            </Box>
            {tabs.map(({ Component, name }, idx) => (
              <TabPanel key={idx} value={String(idx)} sx={{ px: 0, pt: 0.5 }}>
                <Component
                  dashboard={dashboard}
                  onSave={onSave}
                  onDelete={onDelete}
                  onClose={onClose}
                />
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DashboardSettings;
