import React from 'react';
import Dropdown from '../Dropdown';
import { getIn } from 'formik';

const FormikReactSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  name,
  ...props
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  const onChange = (value, action) => {
    setFieldValue(field.name, value);
    if (props.onChange) {
      props.onChange(value, action);
    }
  };

  const onInputChange = (e, val, reason) => {
    if (props.freeSolo && reason === 'input') {
      onChange(val, reason);
    }
  };

  return (
    <Dropdown
      value={field.value}
      hasError={showError}
      onInputChange={onInputChange}
      helpText={showError ? fieldError : props.helperText}
      {...props}
      onChange={onChange}
    />
  );
};

export default FormikReactSelect;
