import { useMemo, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import TextField from 'components/Form/Formik/FormikInput';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Select from 'components/Form/Formik/FormikReactSelect';
import Button from 'components/Button';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import Properties from './Properties';
import { ListTitle } from '../General';
import Typography from '@mui/material/Typography';

export const OVERRIDE_FIELDS_BY = {
  name: {
    label: 'Fields with name',
    value: 'name',
    config: {
      fieldType: 'select',
    },
    filter: (fields, value) => fields.filter((field) => field.name === value),
  },
  regex: {
    label: 'Fields with name matching regex',
    value: 'regex',
    config: { fieldType: 'text' },
    filter: (fields, value) =>
      fields.filter((field) => new RegExp(value).match(field.name).length),
  },
  query: {
    label: 'Fields returned by query',
    value: 'query',
    config: {
      fieldType: 'select',
    },
    filter: (fields, value) => fields.filter((field) => field.nodeId === value),
  },
};

const Overrides = ({ values, frames, setFieldValue }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(
      isExpanded
        ? expanded.concat([panel])
        : expanded.filter((item) => item !== panel),
    );
  };

  const overrideConfigOptions = useMemo(() => {
    return {
      name: Array.from(new Set((frames || []).map(({ name }) => name))).map(
        (name) => ({
          label: name,
          value: name,
        }),
      ),
      type: [
        { label: 'Numeric', value: 'numeric' },
        { label: 'Time', value: 'time' },
      ],
      query: Array.from(
        new Set((frames || []).map(({ nodeId }) => nodeId)),
      ).map((name) => ({
        label: name,
        value: name,
      })),
    };
  }, [frames]);

  return (
    <FieldArray name="overrides">
      {({ push, remove }) => {
        const { overrides = [] } = values;
        return (
          <Stack spacing={2}>
            <Stack spacing={1}>
              {overrides.map((override, idx) => {
                const { id, properties } = override;
                return (
                  <Accordion
                    id={id}
                    key={id}
                    expanded={expanded.includes(id)}
                    disableGutters
                    onChange={handleChange(id)}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Stack
                        width={'100%'}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Typography
                          sx={{ textTransform: 'uppercase' }}
                          variant="subtitle1"
                          color="text.secondary"
                        >{`Override ${idx + 1}`}</Typography>
                        <IconButton size="small" onClick={() => remove(idx)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: theme.palette.background.light }}
                    >
                      <Stack spacing={1}>
                        <Field
                          fullWidth
                          transformValue
                          margin="none"
                          name={`overrides.${idx}.by`}
                          label="Field Override"
                          component={Select}
                          options={Object.values(OVERRIDE_FIELDS_BY).map(
                            ({ label, value }) => ({ label, value }),
                          )}
                        />
                        {OVERRIDE_FIELDS_BY[values.overrides[idx].by]?.config
                          ?.fieldType === 'select' && (
                          <Field
                            fullWidth
                            margin="none"
                            name={`overrides.${idx}.value`}
                            label="Value"
                            component={Select}
                            transformValue
                            options={
                              overrideConfigOptions[values.overrides[idx].by]
                            }
                          />
                        )}
                        {OVERRIDE_FIELDS_BY[values.overrides[idx].by]?.config
                          ?.fieldType === 'text' && (
                          <Field
                            fullWidth
                            margin="none"
                            name={`overrides.${idx}.value`}
                            label="Value"
                            component={TextField}
                          />
                        )}
                        {override.by && override.value && (
                          <Properties
                            prefix={`overrides.${idx}`}
                            properties={properties}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
            <Button
              variant="contained"
              color="neutral"
              onClick={() => {
                const id = uuidv4();
                push({
                  id,
                });
                setExpanded(expanded.concat(id));
              }}
            >
              + Add Field Override
            </Button>
          </Stack>
        );
      }}
    </FieldArray>
  );
};

export default Overrides;
