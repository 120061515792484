import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Button from 'components/Button';
import Input from 'components/Form/Formik/FormikInput';
import ResetPassword from '../components/ResetPassword';
import TOTPVerify from '../components/TOTPVerify';
import ConfirmSignup from './ConfirmCode';
import { analyticsTrack, analyticsGroup } from 'utils/segment';
import usePageEvent from 'hooks/usePageEvent';
import { useSelector } from 'store';
import { login, register } from 'api/auth';
import { IS_LDAP_ENABLED } from 'constants';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const ldapValidationSchema = yup.object({
  email: yup.string('Enter identifier').required('Identifier is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

function FormSignIn({ hideHeader, onLoginSuccess }) {
  const store = useSelector((s) => s);
  const [error, setError] = useState();
  const [authState, setAuthState] = useState({
    state: 'login',
    username: '',
  });

  usePageEvent('Login', {
    section: 'Login',
    flow: 'Sign In',
  });

  const onSubmit = async (values) => {
    try {
      setError();
      const resp = await login(values.email, values.password);
      if (resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthState({
          state: 'requireNewPassword',
          username: values.email,
          user: resp,
        });
      } else if (resp.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setAuthState({
          state: 'requireTOTP',
          user: resp,
        });
      } else {
        if (onLoginSuccess) {
          await onLoginSuccess();
        }
      }
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        setAuthState({
          state: 'confirmSignup',
          username: values.email,
        });
      } else if (error.code === 'PasswordResetRequiredException') {
        setAuthState({
          state: 'forgotPassword',
          username: values.email,
        });
      } else {
        setError(error.message);
      }
    }
  };

  const onAuthStateChange = (state, values) => {
    setAuthState({
      state,
      ...values,
    });
  };

  const onGoogle = () => {};

  let component;
  let title;
  switch (authState.state) {
    case 'confirmSignup':
      component = (
        <ConfirmSignup
          mode="signup"
          onAuthStateChange={onAuthStateChange}
          username={authState.username}
        />
      );
      title = 'Confirm your account';
      break;
    case 'forgotPassword':
      component = (
        <ResetPassword
          shouldRequestCode
          username={authState.username}
          onAuthStateChange={onAuthStateChange}
          message="You need to reset your password. Password reset code sent to your email address."
        />
      );
      title = 'Reset your password';
      break;
    case 'requireTOTP':
      component = (
        <TOTPVerify
          onAuthStateChange={onAuthStateChange}
          user={authState.user}
        />
      );
      break;
    default:
      component = (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={onSubmit}
          validationSchema={
            IS_LDAP_ENABLED ? ldapValidationSchema : validationSchema
          }
        >
          {({ isSubmitting }) => (
            <Form>
              {error && <Alert severity="error">{error}</Alert>}
              {IS_LDAP_ENABLED ? (
                <Field
                  fullWidth
                  name="email"
                  type="text"
                  label="Identifier"
                  component={Input}
                  placeholder="Enter Identifier"
                />
              ) : (
                <Field
                  fullWidth
                  name="email"
                  type="email"
                  label="Email"
                  component={Input}
                  placeholder="Enter email address"
                />
              )}
              <Field
                fullWidth
                name="password"
                type="password"
                label="Password"
                component={Input}
                placeholder="Enter password"
              />
              {!IS_LDAP_ENABLED && (
                <Box textAlign="right">
                  <MuiLink
                    underline="always"
                    component={Link}
                    to="/forgot-password"
                    fontWeight={500}
                  >
                    Forgot password?
                  </MuiLink>
                </Box>
              )}

              <Box mt={3}>
                <Button
                  loading={isSubmitting}
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
              {!IS_LDAP_ENABLED && (
                <Box mt={3} display="flex" alignItems="center">
                  <Typography color="textSecondary">
                    Don’t have an account?
                  </Typography>
                  <MuiLink
                    underline="always"
                    component={Link}
                    to="/signup"
                    ml={1}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    Sign Up
                  </MuiLink>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      );
      title = 'Welcome back! Please enter your details.';
  }

  return (
    <Box>
      {!hideHeader && (
        <>
          <Helmet title="Login" />
          <Typography gutterBottom variant="h4" fontWeight={700}>
            Login
          </Typography>
          <Typography color="textSecondary">{title}</Typography>
        </>
      )}
      <Box mt={4}>{component}</Box>
    </Box>
  );
}

export default FormSignIn;
