import TextField from 'components/Form/Formik/FormikInput';
import { Field } from 'formik';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';

const AdditionalOptions = ({ name }) => {
  const { values } = useFormikContext();
  const isTablePanel = values.panelType === 'table';

  return !isTablePanel ? (
    <Box mt={2}>
      <Typography gutterBottom variant="body2">
        Additional options
      </Typography>
      <Field
        size="small"
        name={`${name}.legendFormat`}
        label="Legend Format"
        placeholder={'{{serviceName}}'}
        margin="none"
        component={TextField}
        sx={{ width: 150 }}
      />
    </Box>
  ) : null;
};

export default AdditionalOptions;
