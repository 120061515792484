import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useStyles } from './styles';
import useMeasure from 'react-use-measure';
import { useWindowSize } from '@react-hook/window-size';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Label from '../Legend/Label';

function isCursorOutsideCanvas({ left, top }, canvas) {
  if (!canvas) {
    return true;
  }
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
}

const Tooltip = ({
  addHooks,
  xTooltipFormatter,
  yValFormatter,
  yRightValFormatter,
  multiSeriesTooltip,
  highlightFocusedSeries,
  tooltipMaxWidth = 420,
  xIsTime,
  bbox,
}) => {
  const [placement, setPlacement] = useState({
    x: 0,
    y: 0,
    bbox,
  });
  const classes = useStyles({ ...placement, maxWidth: tooltipMaxWidth });
  const [ref, bounds] = useMeasure({ scroll: true });
  const [width, height] = useWindowSize();

  const [data, setData] = useState([]);
  const [toolTipData, setTooltipData] = useState([]);
  const [series, setSeries] = useState([]);
  const [focusedIdx, setFocusedIdx] = useState();
  const [focusedSeriesIdx, setFocusedSeriesIdx] = useState();
  const [yVal, setYval] = useState('');
  const [cursor, setCursor] = useState({
    left: 0,
    top: 0,
  });

  const OFFSET_X = 10;
  const OFFSET_Y = 10;

  useEffect(() => {
    const wouldOverflowRight =
      cursor.left + (bbox?.left || 0) + bounds.width + OFFSET_X + 10 > width;
    const wouldOverflowBottom =
      cursor.top + (bbox?.top || 0) + bounds.height + OFFSET_Y + 10 > height;

    setPlacement({
      x: wouldOverflowRight
        ? cursor.left - (bounds.width + OFFSET_X)
        : cursor.left + OFFSET_X,
      y: wouldOverflowBottom
        ? cursor.top - (bounds.height + OFFSET_Y)
        : cursor.top + OFFSET_Y,
      bbox,
    });
  }, [cursor.left, cursor.top, bbox?.left, bbox?.top]);

  useEffect(() => {
    if (
      data &&
      focusedSeriesIdx &&
      typeof data[focusedSeriesIdx][focusedIdx] === 'number'
    ) {
      setYval(data[focusedSeriesIdx][focusedIdx]);
    }
  }, [focusedIdx, focusedSeriesIdx]);

  useEffect(() => {
    addHooks([
      {
        type: 'init',
        func: (_, opts, data) => {
          setData(data);
          setTooltipData(opts.tooltipData);
          setSeries(opts.series);
        },
      },
      {
        type: 'setCursor',
        func: (u) => {
          if (!isCursorOutsideCanvas(u.cursor, u.bbox)) {
            setCursor({
              left: u.cursor.left,
              top: u.cursor.top,
            });
          }
        },
      },
      {
        type: 'setLegend',
        func: (u) => {
          if (u.legend.idx !== null) {
            setFocusedIdx(u.legend.idx);
          }
        },
      },
      {
        type: 'setSeries',
        func: (_, seriesIdx) => {
          setFocusedSeriesIdx(seriesIdx);
        },
      },
    ]);
  }, []);

  return (
    <Box className={classes.tooltipContainer}>
      {focusedSeriesIdx && series && (
        <Box ref={ref} className={classes.tooltip}>
          <Typography gutterBottom variant="body2" fontWeight={700}>
            {xIsTime
              ? xTooltipFormatter(new Date(data[0][focusedIdx]))
              : xTooltipFormatter(data[0][focusedIdx])}
          </Typography>
          <Stack direction="column">
            {multiSeriesTooltip ? (
              series.map((_, index) => {
                const yVal = (toolTipData || data)[index][focusedIdx];
                if (yVal === null || yVal === undefined) {
                  return null;
                }
                const formatter =
                  series[index].scale === 'right'
                    ? yRightValFormatter
                    : yValFormatter;
                return (
                  index !== 0 && (
                    <Label
                      key={index}
                      text={`${series[index].name}: ${formatter(yVal)}`}
                      color={series[index].stroke || series[index].color}
                      highlight={
                        highlightFocusedSeries && index === focusedSeriesIdx
                      }
                    />
                  )
                );
              })
            ) : (
              <Label
                key={0}
                text={`${series[focusedSeriesIdx].name}: ${
                  series[focusedSeriesIdx].scale === 'right'
                    ? yRightValFormatter(yVal)
                    : yValFormatter(yVal)
                }`}
                color={
                  series[focusedSeriesIdx].stroke ||
                  series[focusedSeriesIdx].color
                }
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Tooltip;
