import React, { useMemo } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import { GET_NAMESPACE_METRICS, GET_NAMESPACES } from 'graphql/metric/queries';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Field, FieldArray, useFormikContext } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import REGIONS from 'constants/aws-regions';
import Dimension from './Dimension';
import AdditionalOptions from '../AdditionalOptions';

const REGION_OPTIONS = REGIONS.map((i) => ({
  label: i.region,
  value: i.region,
}));

const STATS = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

export const Dimensions = ({
  name,
  variableOptions,
  accountId,
  namespace,
  region,
  metricName,
  dimensionFilters,
  source,
  resolvedVariablesValues,
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Box>
      {Object.keys(dimensionFilters || {}).map((key) => {
        return (
          <Dimension
            key={key}
            name={name}
            accountId={accountId}
            namespace={namespace}
            region={region}
            metricName={metricName}
            dimensionKey={key}
            dimensionFilters={dimensionFilters}
            dimensionValues={dimensionFilters[key]}
            onRemove={() => {
              const newObj = { ...dimensionFilters };
              delete newObj[key];
              setFieldValue(`${name}.dimensions`, newObj);
            }}
            source={source}
            variableOptions={variableOptions || []}
            resolvedVariablesValues={resolvedVariablesValues}
          />
        );
      })}
      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
        <Button
          size="small"
          variant="contained"
          color="neutral"
          onClick={() => {
            const newObj = { ...dimensionFilters };
            newObj[''] = [];
            setFieldValue(`${name}.dimensions`, newObj);
          }}
        >
          Add dimension
        </Button>
      </Stack>
    </Box>
  );
};

const CloudwatchQueryForm = ({
  name,
  values,
  compact = false,
  variableOptions,
  resolvedVariablesValues,
}) => {
  const namespaceQueryResult = useQuery(GET_NAMESPACES, {
    fetchPolicy: 'cache-first',
  });

  const namespaceMetricQueryResult = useQuery(GET_NAMESPACE_METRICS, {
    fetchPolicy: 'cache-first',
    variables: {
      namespace: values.namespace,
      accountId: values.datasourceId,
      region: values.region,
    },
    skip: !values.namespace,
  });

  const regionOptions = useMemo(() => {
    return variableOptions
      ? [{ label: 'default', value: '$default' }].concat(REGION_OPTIONS)
      : REGION_OPTIONS;
  }, [variableOptions]);

  return (
    <>
      <Grid container columnSpacing={2} sx={{ mb: 1 }}>
        <Grid item md={3}>
          <Field
            transformValue
            size="small"
            margin="none"
            name={`${name}.region`}
            label="Region"
            component={Select}
            options={
              (variableOptions || []).length
                ? variableOptions.concat(
                    regionOptions.map((region) => ({
                      ...region,
                      groupBy: 'Regions',
                    })),
                  )
                : regionOptions
            }
          />
        </Grid>
        <Grid item md={compact ? 6 : 3} mb={compact && 1}>
          <Field
            transformValue
            freeSolo
            size="small"
            isLoading={namespaceQueryResult.loading}
            margin="none"
            name={`${name}.namespace`}
            label="Namespace"
            component={Select}
            options={
              (variableOptions || []).length
                ? variableOptions.concat(
                    (namespaceQueryResult.data?.rows || []).map(
                      (namespace) => ({
                        ...namespace,
                        groupBy: 'Namespaces',
                      }),
                    ),
                  )
                : namespaceQueryResult.data?.rows || []
            }
          />
        </Grid>
        <Grid item md={compact ? 6 : 3}>
          <Field
            transformValue
            size="small"
            margin="none"
            name={`${name}.metric`}
            label="Select metric"
            component={Select}
            isLoading={namespaceMetricQueryResult.loading}
            options={
              (variableOptions || []).length
                ? variableOptions.concat(
                    (namespaceMetricQueryResult.data?.rows || []).map(
                      (metric) => ({
                        ...metric,
                        groupBy: 'Metrics',
                      }),
                    ),
                  )
                : namespaceMetricQueryResult.data?.rows || []
            }
          />
        </Grid>
        <Grid item md={compact ? 3 : 2}>
          <Field
            transformValue
            size="small"
            name={`${name}.stat`}
            label="Statistic"
            component={Select}
            margin="none"
            options={STATS}
          />
        </Grid>
      </Grid>
      <Typography gutterBottom variant="subtitle2" color="text.secondary">
        Dimensions
      </Typography>
      <Dimensions
        name={name}
        variableOptions={variableOptions}
        source={values.source}
        accountId={values.datasourceId}
        namespace={values.namespace}
        region={values.region}
        metricName={values.metric}
        dimensionFilters={values.dimensions}
        resolvedVariablesValues={resolvedVariablesValues}
      />
      <Grid item xs={12}>
        <AdditionalOptions name={name} />
      </Grid>
    </>
  );
};

export default CloudwatchQueryForm;
