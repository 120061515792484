import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { useStyles } from '../styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Spin from 'components/Spin/index';
import Chart from 'components/UPlot';
import { useSelector, useDispatch } from 'store';
import { setCostData, setForecastData } from 'store/actions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { seriesBarsPlugin, calcVertAxisWidth } from 'components/UPlot/utils';
import { useStyles as usePanelStyles } from 'modules/dashboards/styles';
import Paper from '@mui/material/Paper';
import IconButton from 'components/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropdown from 'components/Form/Dropdown';
import { apiClient } from 'api';

const KEYS = [
  {
    name: 'Upper',
    color: '#F47715',
  },
  {
    name: 'Cost',
    color: '#535D79',
  },
  {
    name: 'Lower',
    color: '#D63711',
  },
];

function MonthlyBarData({
  accountId,
  setAccountId,
  accountsResp,
  isEditable,
  onDelete,
  height,
  width,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const panelClasses = usePanelStyles();
  const { workspace } = useSelector((state) => state.workspace);
  const dashboardData =
    useSelector((state) => state.dashboard)[accountId] || {};
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permissionError, setPermissionError] = React.useState();
  const dateFrom = moment()
    .subtract(9, 'months')
    .endOf('month')
    .add(1, 'days')
    .format('YYYY-MM-DD');
  const dateTo = moment().add(1, 'days').format('YYYY-MM-DD');
  const dateAheadFrom = moment()
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const dateAheadTo = moment(dateAheadFrom)
    .add(3, 'months')
    .format('YYYY-MM-DD');

  async function getData() {
    try {
      if (!dashboardData.cost || !dashboardData.forecast) {
        const [cost, forecast] = await Promise.all([
          apiClient.get(`cost-management/report`, {
            params: {
              accountId,
              workspace_id: workspace.id,
              start: dateFrom,
              end: dateTo,
              granularity: 'MONTHLY',
            },
          }),
          apiClient.get(`cost-management/forecast`, {
            params: {
              accountId,
              workspace_id: workspace.id,
              start: dateAheadFrom,
              end: dateAheadTo,
              granularity: 'MONTHLY',
            },
          }),
        ]);
        dispatch(setCostData(accountId, cost));
        if (Array.isArray(forecast)) {
          dispatch(setForecastData(accountId, forecast));
        }
        formatData(cost, forecast);
      } else {
        formatData(dashboardData.cost, dashboardData.forecast);
      }
    } catch (e) {
      setPermissionError(e.message);
    }
  }

  function formatData(cost, forecast) {
    const list = [];
    //looping over prev 9 months data including current month and formatting for the barchart
    for (const i of cost) {
      let obj = {};
      obj.month = moment(i.timePeriod.start).format('MMM');
      obj.cost = i.totals.unblendedCost.amount;
      list.push(obj);
    }

    if (Array.isArray(forecast)) {
      //looping over next 3 months data and formatting for the barchart
      for (const i of forecast) {
        let obj = {
          forecast: true,
        };
        obj.month = moment(i.timePeriod.start).format('MMM');
        obj.cost = parseFloat(i.mean).toFixed(0);
        obj.upper = parseFloat(i.upper).toFixed(0);
        obj.lower = parseFloat(i.lower).toFixed(0);
        list.push(obj);
      }
    }
    setData(list);
  }

  useEffect(async () => {
    if (!accountId) return;
    setData([]);
    setLoading(true);
    await getData();
    setLoading(false);
  }, [workspace, accountId]);

  const chartSeries = useMemo(
    () =>
      [{}].concat(
        KEYS.map(({ name, color }) => ({
          label: name,
          fill: color,
          width: 0,
          points: { show: false },
          name,
          color,
        })),
      ),
    [KEYS],
  );

  const chartData = useMemo(() => {
    const values = chartSeries.map((series) => []);
    data?.forEach(({ month, cost, upper, lower }) => {
      values[0].push(month);
      values[1].push(upper);
      values[2].push(cost);
      values[3].push(lower);
    });

    return values;
  }, [data]);

  const formatYTicks = (value) => `${value} USD`;

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        px={2}
        py={1}
        mb={2}
        className={isEditable && panelClasses.gridItemTitle}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="subtitle1">Monthly Cost & Forecast</Typography>
        <Stack
          direction="row"
          justifyContent={'flex-end'}
          alignItems="center"
          spacing={1}
        >
          <Dropdown
            placeholder="Select account"
            value={accountId}
            onChange={setAccountId}
            options={
              accountsResp?.data?.accounts.map((i) => ({
                label: i.name,
                value: i.id,
              })) ?? []
            }
          />
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      </Box>
      <Box p={2} pt={0}>
        {data?.length > 0 && (
          <Box display="flex" alignItems="center" columnGap={3}>
            <Box display="flex" alignItems="center">
              <Box
                width={12}
                height={12}
                sx={{ backgroundColor: 'grey.A700' }}
              />
              <Typography color="text.secondary" ml={1} variant="body2">
                Past / Mean forecast
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box width={12} height={12} sx={{ backgroundColor: '#F47715' }} />
              <Typography color="text.secondary" ml={1} variant="body2">
                Upper limit forecast
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box width={12} height={12} sx={{ backgroundColor: '#D63711' }} />
              <Typography color="text.secondary" ml={1} variant="body2">
                Lower limit forecast
              </Typography>
            </Box>
          </Box>
        )}
        <Box pt={2}>
          <Spin
            size={50}
            color={'primary'}
            spinning={loading}
            className={classes.spinCenter}
          >
            <div>
              {!accountId && permissionError && (
                <Alert severity="error">
                  <AlertTitle>{permissionError}</AlertTitle>
                  Make sure that you've enabled Cost Explorer API access. For
                  more information click{' '}
                  <Link
                    underline="always"
                    color="inherit"
                    target="_blank"
                    href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html"
                  >
                    here
                  </Link>
                  .
                </Alert>
              )}
              {chartData && accountId && (
                <Chart
                  data={chartData}
                  height={height - 120}
                  chartSeries={chartSeries}
                  cursor={{
                    show: true,
                    points: {
                      size: 0,
                    },
                    focus: {
                      prox: 300,
                    },
                  }}
                  showLegend={false}
                  scales={{
                    x: {
                      time: false,
                      distr: 2,
                    },
                  }}
                  axes={[
                    {
                      stroke: theme.palette.text.secondary,
                      grid: {
                        show: false,
                      },
                    },
                    {
                      side: 3,
                      stroke: theme.palette.text.secondary,
                      grid: {
                        show: false,
                      },
                      values: (_, values) => {
                        return values.map((v) => formatYTicks(v));
                      },
                      size: calcVertAxisWidth({ multiplier: 6 }),
                    },
                  ]}
                  padding={[16, 24, 0, 16]}
                  appendPlugins={[
                    seriesBarsPlugin({
                      ori: 0,
                      dir: 1,
                    }),
                  ]}
                  xTooltipFormatter={(value) => value}
                  yValFormatter={formatYTicks}
                />
              )}
            </div>
          </Spin>
        </Box>
      </Box>
    </Paper>
  );
}

export default MonthlyBarData;
