import { gql } from '@apollo/client';
import { issueUserFragment } from '../issue_users/fragments';

export const issueFragments = gql`
  fragment Issue on issues {
    id
    checksum
    created_at
    updated_at
    last_occurrence_at
    count
    error_type
    message
    severity
    muted_at
    status
    workspace_id
    service_name
    dataset
    trace_id
    users(where: { is_assigned: { _eq: true } }) {
      ...IssueUser
    }
  }
  ${issueUserFragment}
`;
