import React, { useContext, useMemo } from 'react';
import { AlertContext } from 'context/alert';

const useAlert = () => {
  const context = useContext(AlertContext);
  const alert = useMemo(() => context, [context]);
  return alert;
};

export default useAlert;

export const withAlert = (Component) => {
  const WithAlert = (props, forwardedRef) => (
      <AlertContext.Consumer>
        {(alert) => <Component ref={forwardedRef} alert={alert} {...props} />}
      </AlertContext.Consumer>
    );
  WithAlert.displayName = `WithAlert(${
    Component.displayName || Component.name || 'Component'
  })`;
  return React.forwardRef(WithAlert);
};
