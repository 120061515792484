export const formatTimeLabel = ({ from, to, dateStyle }) => {
  if (dateStyle && dateStyle !== 'auto') {
    return dateStyle;
  }
  const ms = to - from;
  const HR = 60 * 60 * 1000;

  if (ms <= 0.25 * HR) {
    return '{HH}:{mm}:{ss}';
  } else if (ms <= 24 * HR) {
    return '{HH}:{mm}';
  } else if (ms <= 48 * HR) {
    return '{MM}/{DD} {HH}:{mm}';
  } else {
    return '{MM}/{DD}';
  }
};
