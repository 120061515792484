import React, { forwardRef, Children, cloneElement } from 'react';

const GridLayoutChild = forwardRef(
  ({ style, className, children, dataGrid, ...props }, ref) => {
    return (
      <div style={style} className={className} {...props} ref={ref}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            height: parseInt(style.height, 10),
            width: parseInt(style.width, 10),
          }),
        )}
      </div>
    );
  },
);

export default GridLayoutChild;
