import React from 'react';
import Box from '@mui/material/Box';
import TimeseriesGraph from '../TimeseriesGraph';
import KloudMateQueryForm from './KloudMateQueryForm';
import { TOOLTIP_MODES } from 'modules/dashboards/components/PanelSettings/Tooltip';

const KloudMateForm = ({
  name,
  values,
  account,
  onChange,
  result,
  displayResult,
  compact,
  variableOptions,
  timeRange,
  workspace,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  return (
    <Box>
      <KloudMateQueryForm
        workspace={workspace}
        values={values}
        name={name}
        account={account}
        onChange={onChange}
        compact={compact}
        variableOptions={variableOptions}
        timeRange={timeRange}
        onChangeUnit={onChangeUnit}
        resolvedVariablesValues={resolvedVariablesValues}
      />
      {displayResult && result && (
        <Box sx={{ overflowX: 'clip' }}>
          <TimeseriesGraph
            timeRange={values.time_range}
            data={result.frames}
            height={200}
          />
        </Box>
      )}
    </Box>
  );
};

export default KloudMateForm;
