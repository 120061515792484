import { gql } from '@apollo/client';

export const planFields = gql`
  fragment Plan on plans {
    id
    plan_tier
    sku
    trial_period_days
    billing_frequency
    allow_change_to
    allow_change_from
    currency
    name
    description
  }
`;

export const planPriceFields = gql`
  fragment PlanPrice on v_plan_prices {
    id
    items_count
    price_cents
    stripe_price_id
    product {
      id
      type
      name
      description
    }
  }
`;
