import { gql } from '@apollo/client';

export const orgFields = gql`
  fragment Organization on organizations {
    id
    name
    status
    status_reason
    trial_end_date
  }
`;

export const orgUsersField = gql`
  fragment OrgUser on organization_users {
    user_id
    organization_id
    role
  }
`;

export const orgBillingFields = gql`
  fragment OrganizationBillingDetails on organizations {
    id
    name
    trial_end_date
    product_key
    billing_address1
    billing_address2
    billing_city
    billing_country
    billing_email
    billing_first_name
    billing_last_name
    billing_period_day
    billing_period_month
    billing_state
    billing_zip
    cancelled_at
    company_name
    plan {
      id
      plan_tier
      sku
      currency
      allow_change_from
      name
      description
      prices {
        product {
          name
          description
          type
        }
      }
    }
  }
`;
