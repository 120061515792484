import {
  EC2Icon,
  VolumeIcon,
  SnapshotIcon,
  LambdaIcon,
  SQSIcon,
  S3Icon,
  ElasticBeanstalkIcon,
  DynamoDBIcon,
  RDSIcon,
  IAMIcon,
  VPCIcon,
  AutoScalingIcon,
  EIPIcon,
  EFSIcon,
  ELBIcon,
  KinesisIcon,
  ESIcon,
  GatewayIcon,
  EKSIcon,
  ECSIcon,
  ECRIcon,
  AthenaIcon,
  FirehoseIcon,
} from './AwsIcons';

export const serviceTypes = {
  'AWS::EC2::Instance': {
    id: 'ec2',
    title: 'EC2 Instances',
    number: 'Instances',
    icon: <EC2Icon title="EC2" width={40} height={40} />,
  },
  'AWS::EC2::EBS': {
    id: 'ebs',
    title: 'EBS Volumes',
    number: 'Volumes',
    icon: <VolumeIcon title="EBS Volumes" width={40} height={40} />,
  },
  'AWS::EBS::Snapshots': {
    id: 'ebs_snapshots',
    title: 'EBS Snapshots',
    number: 'Snapshots',
    icon: <SnapshotIcon title="EBS Snapshots" width={40} height={40} />,
  },
  'AWS::SQS::Queue': {
    id: 'sqs',
    title: 'SQS Queues',
    number: 'Queues',
    icon: <SQSIcon title="SQS queues" width={40} height={40} />,
  },
  'AWS::Lambda::Function': {
    id: 'lambda',
    title: 'Lambda Functions',
    number: 'Functions',
    icon: <LambdaIcon title="Lambda Functions" width={40} height={40} />,
  },
  'AWS::S3::Bucket': {
    id: 's3',
    title: 'S3 Buckets',
    number: 'Buckets',
    icon: <S3Icon title="S3 Buckets" width={40} height={40} />,
  },
  'AWS::ElasticBeanstalk': {
    id: 'elastic_beanstalk',
    title: 'Elastic Beanstalk',
    number: 'Environments',
    icon: (
      <ElasticBeanstalkIcon title="Elastic Beanstalk" width={40} height={40} />
    ),
  },
  'AWS::RDS::Instance': {
    id: 'rds',
    title: 'RDS Instances',
    number: 'Instances',
    icon: <RDSIcon title="RDS Instances" width={40} height={40} />,
  },
  'AWS::DynamoDb::Table': {
    id: 'dynamo_db',
    title: 'DynamoDB Tables',
    number: 'Tables',
    icon: <DynamoDBIcon title="DynamoDB Tables" width={40} height={40} />,
  },
  'AWS::IAM::User': {
    id: 'iam',
    title: 'IAM',
    number: 'Users',
    icon: <IAMIcon title="IAM" width={40} height={40} />,
    metrics: false,
  },
  'AWS::EC2::Vpc': {
    id: 'vpc',
    title: 'VPC',
    number: 'Networks',
    icon: <VPCIcon title="VPC" width={40} height={40} />,
    metrics: false,
  },
  'AWS::EC2::AutoscalingGroup': {
    id: 'autoscaling_group',
    title: 'Autoscaling Groups',
    number: 'ASG',
    icon: <AutoScalingIcon title="Autoscaling Groups" width={40} height={40} />,
    metrics: false,
  },
  'AWS::EC2::ElasticIp': {
    id: 'elasticIp',
    title: 'Elastic IP',
    number: 'EIP',
    icon: <EIPIcon title="EIP" width={40} height={40} />,
    metrics: false,
  },
  'AWS::EFS::FileSystem': {
    id: 'efs',
    title: 'EFS File Systems',
    number: 'File Systems',
    icon: <EFSIcon title="EFS" width={40} height={40} />,
    metrics: false,
  },
  'AWS::ELB::LoadBalancer': {
    id: 'elb',
    title: 'ELB',
    number: 'Load Balancers',
    icon: <ELBIcon title="ELB" width={40} height={40} />,
  },
  'AWS::Kinesis::Stream': {
    id: 'ks',
    title: 'Kinesis Stream',
    number: 'Streams',
    icon: <KinesisIcon title="Kinesis" width={40} height={40} />,
  },
  'AWS::ES::Domain': {
    id: 'es',
    title: 'Elasticsearch Domains',
    number: 'Domains',
    icon: <ESIcon title="Elasticsearch" width={40} height={40} />,
  },
  'AWS::KinesisFirehose::DeliveryStream': {
    id: 'firehose',
    title: 'Firehose Stream',
    number: 'Streams',
    icon: <FirehoseIcon title="Firehose" width={40} height={40} />,
  },
  'AWS::ApiGateway': {
    id: 'agw',
    title: 'API Gateway',
    number: 'Gateways',
    icon: <GatewayIcon title="API Gateway" width={40} height={40} />,
  },
  'AWS::EKS::Cluster': {
    id: 'eks',
    title: 'EKS Cluster',
    number: 'Clusters',
    icon: <EKSIcon title="EKS" width={40} height={40} />,
  },
  'AWS::ECS::Container': {
    id: 'ecs',
    title: 'ECS Container',
    number: 'Containers',
    icon: <ECSIcon title="ECS" width={40} height={40} />,
  },
  'AWS::ECR::Repository': {
    id: 'ecr',
    title: 'ECR Repository',
    number: 'Repositories',
    icon: <ECRIcon title="ECR" width={40} height={40} />,
    metrics: false,
  },
  'AWS::Athena::Query': {
    id: 'ath',
    title: 'Athena Queries',
    number: 'Queries',
    icon: <AthenaIcon title="Athena" width={40} height={40} />,
    metrics: false,
  },
  'AWS::StepFunction::StateMachine': {
    id: 'step_function',
    title: 'Step Function',
    number: 'State Machines',
    icon: <AthenaIcon width={40} height={40}/>,
  },
};
