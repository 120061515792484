import merge from 'lodash/merge';
//
import TextField from './textfield';
import Button from './button';
import ButtonGroup from './button-group';
import ToggleButton from './toggle-button';
import Link from './link';
import Paper from './paper';
import Chip from './chip';
import Switch from './switch';
import Tabs from './tabs';
import Radio from './radio-button';
import Tooltip from './tooltip';
import Checkbox from './checkbox';
import Table from './table';
import List from './list';
import Avatar from './avatar';
import Autocomplete from './autocomplete';
import Typography from './typography';
import Dialog from './dialog';
import Menu from './menu';
import Popover from './popover';
import Select from './select';
import CSSBaseline from './css-baseline';

// ----------------------------------------------------------------------

export default function componentsOverrides(theme) {
  return merge(
    TextField(theme),
    Button(theme),
    ButtonGroup(theme),
    ToggleButton(theme),
    Link(theme),
    Paper(theme),
    Chip(theme),
    Switch(theme),
    Tabs(theme),
    Radio(theme),
    Tooltip(theme),
    Checkbox(theme),
    Table(theme),
    List(theme),
    Avatar(theme),
    Autocomplete(theme),
    Typography(theme),
    Dialog(theme),
    Menu(theme),
    Popover(theme),
    Select(theme),
    CSSBaseline(theme),
  );
}
