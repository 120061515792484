import { Field } from 'formik';
import Stack from '@mui/material/Stack';
import Switch from 'components/Form/Formik/FormikSwitch';
import TextField from 'components/Form/Formik/FormikInput';
import { parse } from 'rte-moment';
import isNil from 'lodash/isNil';

const INVALID_TIME_SHIFT_ERR = 'Invalid time shift';
const validateTimeShift = (value) => {
  if (isNil(value) || value === '') {
    return;
  }
  try {
    const { body } = parse(value);
    if (body.length !== 1 || body[0].op !== '-' || body[0].type !== 'Offset') {
      return INVALID_TIME_SHIFT_ERR;
    }
  } catch (err) {
    return INVALID_TIME_SHIFT_ERR;
  }
};

export const TimeRangeOptions = () => {
  return (
    <Stack>
      <Field
        name="time_options.time_shift"
        label="Time Shift"
        size="small"
        margin="none"
        component={TextField}
        validate={validateTimeShift}
      />
      <Field
        label="Override dashboard time range"
        labelPlacement="end"
        name="time_options.override"
        size="small"
        formControlLabelProps={{
          componentsProps: {
            typography: {
              color: 'text.secondary',
            },
          },
        }}
        component={Switch}
      />
    </Stack>
  );
};

export default TimeRangeOptions;
