import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Button from 'components/Button';
import Input from 'components/Form/Formik/FormikInput';
import usePageEvent from 'hooks/usePageEvent';
import { resetPassword, requestPasswordReset } from 'api/auth';
import { toast } from 'react-hot-toast';

const validationSchema = yup.object({
  code: yup.string('Enter your code').required('Code is required'),
  password: yup.string('Enter your password').required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = ({
  username,
  message,
  onAuthStateChange,
  shouldRequestCode,
}) => {
  const [error, setError] = useState('');
  usePageEvent('Reset Password', {
    section: 'Forgot Password',
    flow: 'Sign In',
  });

  useEffect(() => {
    if (shouldRequestCode) {
      requestPasswordReset(username);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      await resetPassword(username, values.code, values.password);
      toast.success('Password reset successful! Please login.');
      onAuthStateChange && onAuthStateChange('login');
    } catch (e) {
      setError(e.message);
    }
  };

  const onResendCode = async (e) => {
    e.preventDefault();
    try {
      setError();
      await requestPasswordReset(username);
      toast.success('Code sent!');
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Box>
      {message && <Alert severity="info">{message}</Alert>}
      <Formik
        initialValues={{
          password: '',
          code: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            {error && <Alert severity="error">{error}</Alert>}
            <Field fullWidth name="code" label="Code" component={Input} />
            <Field
              fullWidth
              name="password"
              type="password"
              label="New password"
              component={Input}
            />
            <Field
              fullWidth
              name="confirmPassword"
              type="password"
              component={Input}
              label="Confirm password"
            />
            <Box textAlign="right">
              Lost your code?{' '}
              <MuiLink href="#" onClick={onResendCode}>
                Resend Code
              </MuiLink>
            </Box>
            <Box mt={3}>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                size="large"
                type="submit"
                fullWidth
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ResetPassword;
