import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/NoteAddOutlined';
import ImportIcon from '@mui/icons-material/ImportContactsOutlined';
import Button from 'components/Button';
import ImportPanelModal from './ImportPanelModal';

const PanelCard = ({ title, Icon, onClick }) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        'cursor': 'pointer',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <CardContent sx={{ textAlign: 'center', p: 4 }}>
        <Icon color="secondary" sx={{ fontSize: 40 }} />
        <Typography>{title}</Typography>
      </CardContent>
    </Card>
  );
};

const NewPanelWizard = ({
  onClose,
  onImport,
  variables,
  defaultRegion,
  noVariablesInPanel,
  autoSelectTemplate,
}) => {
  const [showImportModal, setShowImportModal] = useState(
    autoSelectTemplate !== undefined ? autoSelectTemplate : false,
  );

  const toggleImportModal = () => {
    setShowImportModal(!showImportModal);
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>Add panel</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2} justifyContent="center">
            <PanelCard
              title="Create from scratch"
              Icon={AddIcon}
              onClick={() => onImport()}
            />
            <PanelCard
              title="Import from Library"
              Icon={ImportIcon}
              onClick={toggleImportModal}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {showImportModal && (
        <ImportPanelModal
          onSubmit={onImport}
          onClose={toggleImportModal}
          variables={variables}
          defaultRegion={defaultRegion}
          noVariablesInPanel={noVariablesInPanel}
          autoSelectTemplate={autoSelectTemplate}
        />
      )}
    </>
  );
};

export default NewPanelWizard;
