export const mergeHooks = (existingHooks, newHooks, replace) => {
  let mergedHooks = existingHooks;
  newHooks.forEach(({ type, func }) => {
    if (mergedHooks[type]) {
      mergedHooks = {
        ...mergedHooks,
        [type]: replace ? [func] : [...mergedHooks[type], func],
      };
    } else {
      mergedHooks = { ...mergedHooks, [type]: [func] };
    }
  });
  return mergedHooks;
};
