import { useEffect, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'store';
import { runAlarmQuery } from '../utils';
import usePrevious from 'hooks/usePrevious';

const usePanelData = ({
  panelConfig,
  refreshHash,
  panelInViewPort,
  workspaceId,
}) => {
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const prevPanelConfig = usePrevious(panelConfig);
  const prevRefreshHash = usePrevious(refreshHash);
  const prevPanelInViewPort = usePrevious(panelInViewPort);

  const {
    workspace: { workspace },
  } = useSelector((s) => s);

  const runQuery = useCallback(
    async (panelConfig) => {
      try {
        setResult(null);
        setLoading(true);
        const resp = await runAlarmQuery(
          panelConfig,
          workspaceId || workspace.id,
        );
        setResult(resp);
        setError(null);
      } catch (e) {
        setError(e?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [panelConfig],
  );

  useEffect(async () => {
    // wait for accountId variable to resolve
    if (
      (panelConfig?.node_configs || []).some((nc) =>
        nc?.datasourceId?.startsWith('$'),
      )
    ) {
      return;
    }
    const isSameConfig = isEqual(prevPanelConfig, panelConfig);
    const isSameRefreshHash = prevRefreshHash === refreshHash;
    const isSamePanelInViewport = prevPanelInViewPort === panelInViewPort;

    const shouldRun = () => {
      if (!isSameConfig || (isSameConfig && !isSamePanelInViewport)) {
        return panelInViewPort;
      }
      if (prevRefreshHash && !isSameRefreshHash) {
        return true;
      }
      return false;
    };

    if (panelConfig && shouldRun()) {
      await runQuery(panelConfig);
    }
  }, [
    panelConfig,
    prevPanelConfig,
    refreshHash,
    prevRefreshHash,
    panelInViewPort,
  ]);

  return {
    result,
    loading,
    error,
    runQuery,
  };
};

export default usePanelData;
