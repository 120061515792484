import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from 'components/Form/TextInput';
import Button from 'components/Button';
import { Helmet } from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import usePageEvent from 'hooks/usePageEvent';

const CreateOrganization = ({ onCreateOrganization, mobileStepperProps }) => {
  const [value, setValue] = useState('');

  usePageEvent('Organization Info', {
    section: 'Info',
    flow: 'Sign Up',
  });

  const NextBtn = React.memo(
    (props) => (
      <Button
        size="large"
        disabled={!value}
        onClick={() => onCreateOrganization(value)}
        {...props}
      >
        Next
      </Button>
    ),
    [value, onCreateOrganization],
  );
  return (
    <>
      <Box sx={{ p: { xs: 4, md: 0 } }}>
        <Helmet title="Setup your account" />
        <Typography variant="h5" mb="8px" fontWeight={600}>
          Organization Info
        </Typography>
        <Typography mb={4} color="text.secondary" lineHeight="150%">
          Let’s set up your account. Enter your organization name below.
        </Typography>
        <Input
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Organization name"
        />
        <NextBtn sx={{ mt: 1, display: { xs: 'none', md: 'block' } }} />
      </Box>
      {mobileStepperProps && (
        <MobileStepper {...mobileStepperProps} nextButton={<NextBtn />} />
      )}
    </>
  );
};

export default CreateOrganization;
