import {
  STORE_SESSION,
  SET_ACTIVE_WORKSPACE,
  UPDATE_USER,
  SET_COST_DATA,
  SET_COST_BY_SERVICE,
  SET_COST_BY_REGION,
  SET_FORECAST_DATA,
  SET_LAMBDA_DASHBOARD_METRICS,
  TOGGLE_THEME,
  SET_DASHBOARD_TEMPLATES,
  SET_LAMBDA_LIST_METRICS,
} from './types';

export const ORG_KEY = 'active_org';
export const WORKSPACE_KEY = 'active_workspace';

export const storeSession = (user) => ({
  type: STORE_SESSION,
  payload: user,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const setActiveWorkspace = (org, workspace) => {
  if (!org) {
    localStorage.removeItem(ORG_KEY);
  } else {
    localStorage.setItem(ORG_KEY, JSON.stringify(org));
  }
  if (!workspace) {
    localStorage.removeItem(WORKSPACE_KEY);
  } else {
    localStorage.setItem(WORKSPACE_KEY, JSON.stringify(workspace));
  }
  return {
    type: SET_ACTIVE_WORKSPACE,
    payload: { org, workspace },
  };
};

export const setCostData = (accountId, data) => ({
  type: SET_COST_DATA,
  payload: { data, accountId },
});

export const setForecastData = (accountId, data) => ({
  type: SET_FORECAST_DATA,
  payload: { data, accountId },
});

export const setCostByService = (accountId, data) => ({
  type: SET_COST_BY_SERVICE,
  payload: { accountId, data },
});

export const setCostByRegion = (accountId, data) => ({
  type: SET_COST_BY_REGION,
  payload: { accountId, data },
});

export const toggleTheme = (value) => {
  let newTheme = value;
  if (!newTheme) {
    const currentTheme = localStorage.getItem('theme');
    newTheme = currentTheme === 'dark' ? 'light' : 'dark';
  }
  localStorage.setItem('theme', newTheme);
  return {
    type: TOGGLE_THEME,
    payload: newTheme,
  };
};

export const setDashboardTemplates = (data) => ({
  type: SET_DASHBOARD_TEMPLATES,
  payload: data,
});

export const setLambdaListMetrics = (data) => ({
  type: SET_LAMBDA_LIST_METRICS,
  payload: data,
});