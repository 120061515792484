/* eslint-disable import/no-anonymous-default-export */
import { useCallback, useEffect, useState } from 'react';
import oryClient, { getAuthError, getCsrfToken } from 'utils/auth';
import useKMSession from './useKMSession';
import { analyticsIdentify } from 'utils/segment';

export default ({ useMFA, skip, flowId }) => {
  const { getKMSessionFromAuthSession } = useKMSession();

  const [flow, setFlow] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (skip) {
      return;
    }
    async function createLoginFlow() {
      try {
        const { data } = await oryClient.createBrowserLoginFlow({
          aal: useMFA ? 'aal2' : 'aal1',
          refresh: true,
        });
        setFlow(data);
      } catch (error) {
        const errMsg = getAuthError(error.response.data);
        errMsg && setError(errMsg);
      }
    }

    async function getLoginFlow() {
      try {
        const { data } = await oryClient.getLoginFlow({
          id: flowId,
        });
        setFlow(data);
      } catch (error) {
        const errMsg = getAuthError(error.response.data);
        errMsg && setError(errMsg);
      }
    }

    if (!flowId) {
      createLoginFlow();
      return;
    }

    getLoginFlow();
  }, [skip, flowId]);

  const onSubmit = useCallback(
    async ({
      type,
      email,
      password,
      totp_code,
      lookup_secret,
      code,
      shouldResend,
    }) => {
      try {
        setError('');
        const csrf_token = getCsrfToken(flow);

        let body;
        switch (type) {
          case 'totp': {
            body = {
              totp_code,
              csrf_token,
              method: 'totp',
            };
            break;
          }
          case 'lookup_secret': {
            body = {
              csrf_token,
              method: 'lookup_secret',
              lookup_secret,
            };
            break;
          }
          case 'code': {
            body = {
              csrf_token,
              method: 'code',
              identifier: email,
            };
            if (shouldResend) {
              body.resend = 'code';
            }
            if (code) {
              body.code = code;
            }

            break;
          }
          case 'password':
          default: {
            body = {
              csrf_token,
              identifier: email,
              password: password,
              method: 'password',
            };
            break;
          }
        }

        const { data: response } = await oryClient.updateLoginFlow({
          flow: String(flow.id),
          updateLoginFlowBody: body,
        });

        const errMsg = getAuthError(response);
        if (errMsg) {
          setError(errMsg);
          return { error: errMsg };
        }

        await getKMSessionFromAuthSession();

        return { response };
      } catch (error) {
        const errMsg = getAuthError(error.response.data);
        errMsg && setError(errMsg);
        return { error: errMsg };
      }
    },
    [flow],
  );

  return {
    flow,
    onSubmit,
    error,
  };
};
