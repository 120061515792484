import useRequest from 'hooks/useRequest';
import React, { useMemo, useState, useEffect } from 'react';
import Select from 'components/Form/Select';
import Chip from '@mui/material/Chip';
import { fetchValues } from 'api/otel';
import { useSelector } from 'store';
import debounce from 'lodash/debounce';
import { resolveTemplateVariables } from 'modules/dashboards/utils';
import { OTEL_VALUES } from 'modules/dashboards/components/DashboardSettings/Variables/QueryVariable';
import { toast } from 'react-hot-toast';

const ValueField = ({
  name,
  value,
  onChange,
  field,
  type,
  dataset,
  variableOptions,
  from,
  to,
  resolvedVariablesValues,
  isMulti = false,
  ...rest
}) => {
  const workspaceId = useSelector((state) => state.workspace.workspace.id);
  const [query, setQuery] = useState('');
  const debouncedSearch = useMemo(() => debounce(setQuery, 400), []);

  const [data, { loading, error }] = useRequest(
    fetchValues,
    {
      dataset,
      query: resolveTemplateVariables(
        {
          query,
          key: field,
          type,
          workspaceId,
          from,
          to,
          filters: [],
        },
        resolvedVariablesValues,
      ),
    },
    {
      skip: !field,
    },
  );

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const filterValues = useMemo(() => {
    if (data?.values) {
      return data.values.map((item) => ({
        ...item,
        label: item.value,
        icon: item.metricType && <Chip label={item.metricType} />,
        endIcon: true,
        groupBy: variableOptions?.length ? 'Values' : undefined,
      }));
    }
    return [];
  }, [data]);

  return (
    <Select
      fullWidth
      freeSolo
      isLoading={loading}
      component={Select}
      options={(variableOptions || [])
        .filter((opt) => opt.isMulti === isMulti)
        .filter((opt) => opt.queryType === OTEL_VALUES)
        .concat(filterValues)}
      margin="none"
      size="small"
      placeholder="value"
      value={value}
      onChange={(val) => {
        onChange(name, val);
      }}
      onInputChange={(e, v, reason) => {
        if (reason === 'input') {
          debouncedSearch(v);
        }
      }}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default ValueField;
