import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  'st0': {
    fill: theme.palette.primary.main,
  },
  'logoWrapper': {
    position: 'relative',
  },
  'kmLogo': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },

  'kmRate': {
    width: '150px',
    height: '73px',
    position: 'relative',
    zIndex: 1,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'kmGlass': {
    width: '150px',
    height: '73px',
    margin: '0 auto',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'smallIcon': {
    width: '75px',
    height: '40px',
  },
  'fadeIn': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    top: 0,
    right: 0,
    animation: '$heartRateIn 1.5s linear infinite',
  },
  'fadeOut': {
    position: 'absolute',
    width: '120%',
    height: '100%',
    top: 0,
    left: '-120%',
    animation: '$heartRateOut 1.5s linear infinite',
    backgroundColor:
      'linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)',
  },
  'tip': {
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes heartRateIn': {
    '0%': {
      width: '100%',
    },
    '50%': {
      width: 0,
    },
    '100%': {
      width: 0,
    },
  },
  '@keyframes heartRateOut': {
    '0%': {
      left: '-120%',
    },
    '30%': {
      left: '-120%',
    },
    '100%': {
      left: 0,
    },
  },
}));
